import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import ChipLink from '../components/ChipLink';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import moment from 'moment';
import { withIAM, isInRoles, ROLE_ADMIN } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { PURCHASE_TYPE, CURRENCIES } from '../common/enums';

const styles = (theme) => ({});

const defaults = {
  id: true,
  type: false,
  amount: false,
  date: false,
  estimatedTransfer: false,
  patient: false,
  point: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.purchases.defaults';

class Purchases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  makeAmountInput = (amount) => {
    const splittedValue = amount.float.toString().split('.');
    return {
      value: parseInt(splittedValue[0]),
      fraction: parseInt(splittedValue[1]),
      float: parseFloat(amount.float),
      currency: 'EUR',
    };
  };

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.type) ||
        validator.isNumeric(toString(data.amount.float)) ||
        validator.isEmpty(data.date.toString()) ||
        validator.isEmpty(data.estimatedTransfer.toString())
      ) {
        throw new Error(i18n.get('purchases.error.validation'));
      }

      const input = {};

      const date = moment(data.date).unix();
      const estimatedTransfer = moment(data.estimatedTransfer).unix();
      data['date'] = date;
      data['estimatedTransfer'] = estimatedTransfer;

      helper.set(input, data, 'type');
      helper.set(input, data, 'amount', this.makeAmountInput);
      helper.set(input, data, 'date');
      helper.set(input, data, 'estimatedTransfer');

      return input;
    } catch (error) {
      throw new Error(i18n.get('purchases.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'amount.float':
        return 'amount_float';
      case 'point':
        return 'point_ref';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPatientsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                patients(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Patient {
                            id
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.patients.elements.map((element) => {
        return {
          name: `${element.firstname} ${element.lastname}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPatientsSuggestions = () => {
    return [];
  };

  getPointsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                points(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Point {
                            id
                            used
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.points.elements.map((element) => {
        return {
          name: `${element.used ? '✔' : '❌'}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPointsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPatientID =
        newData.patient && newData.patient.length > 0
          ? newData.patient[0].value
          : newData.patient
          ? newData.patient.id
          : null;
      const oldPatientID = oldData && oldData.patient ? oldData.patient.id : null;

      if (newPatientID !== oldPatientID) {
        if (oldPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPatientID,
              purchaseId: newData.id,
            },
            mutation: gql`
              mutation unassignPurchaseFromPatient($id: ID!, $purchaseId: ID!) {
                unassignPurchaseFromPatient(id: $id, purchaseId: $purchaseId) {
                  id
                }
              }
            `,
          });
        }

        if (newPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPatientID,
              purchaseId: newData.id,
            },
            mutation: gql`
              mutation assignPurchaseToPatient($id: ID!, $purchaseId: ID!) {
                assignPurchaseToPatient(id: $id, purchaseId: $purchaseId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPointId =
        newData.point && newData.point.length > 0 ? newData.point[0].value : newData.point ? newData.point.id : null;
      const oldPointId = oldData && oldData.point ? oldData.point.id : null;

      if (newPointId !== oldPointId) {
        if (oldPointId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              pointId: oldPointId,
              id: newData.id,
            },
            mutation: gql`
              mutation unassignPointFromPurchase($id: ID!, $pointId: ID!) {
                unassignPointFromPurchase(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }

        if (newPointId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              pointId: newPointId,
              id: newData.id,
            },
            mutation: gql`
              mutation assignPointToPurchase($id: ID!, $pointId: ID!) {
                assignPointToPurchase(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('purchases.id'),
              field: 'id',
              hidden: this.default('id'),
              customSort: (a, b) => parseInt(a.id) - parseInt(b.id),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('purchases.type'),
              field: 'type',
              hidden: this.default('type'),
              sorting: true,
              filtering: true,
              lookup: PURCHASE_TYPE,
              initialEditValue: 'STANDARD',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('purchases.amount'),
              field: 'amount.float',
              hidden: this.default('amount'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.amount.float)}${
                    CURRENCIES[rowData.amount.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('purchases.date'),
              field: 'date',
              hidden: this.default('date'),
              sorting: true,
              filtering: true,
              type: 'datetime',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('purchases.estimatedTransfer'),
              field: 'estimatedTransfer',
              hidden: this.default('estimatedTransfer'),
              sorting: true,
              filtering: true,
              type: 'date',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('purchases.patient'),
              field: 'patient',
              hidden: this.default('patient'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.firstname} ${v.lastname} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getPatientsSuggestions,
                this.getDefaultPatientsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.patient ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.customers'), data: { id: rowData.patient.id } }}
                      label={`${rowData.patient.firstname} ${rowData.patient.lastname} (${rowData.patient.id}) `}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.firstname} ${props.value.lastname} (${props.value.id})`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('purchases.patient.label'),
                  i18n.get('required'),
                  this.getPatientsSuggestions,
                  this.getDefaultPatientsSuggestions
                );
              },
            },
            {
              title: i18n.get('purchases.point'),
              field: 'point',
              hidden: this.default('point'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.used ? '✔' : '❌'} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getPointsSuggestions,
                this.getDefaultPointsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.point ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.points'), data: { id: rowData.point.id } }}
                      label={`${rowData.point.used ? '✔' : '❌'} (${rowData.point.id})`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.used ? '✔' : '❌'} (${props.value.id})`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('purchases.point.label'),
                  i18n.get('required'),
                  this.getPointsSuggestions,
                  this.getDefaultPointsSuggestions
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'date', ['date']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          purchases(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Purchase {
                                  id
                                  type
                                  amount {
                                    float
                                    currency
                                  }
                                  date {
                                    isoString
                                  }
                                  estimatedTransfer {
                                    isoString
                                  }
                                  patient {
                                    id
                                    firstname
                                    lastname
                                  }
                                  point {
                                    id
                                    used
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.purchases.elements.map((element) => ({
                    ...element,
                    date: element.date && element.date.isoString ? moment(element.date.isoString).toDate() : null,
                    estimatedTransfer:
                      element.estimatedTransfer && element.estimatedTransfer.isoString
                        ? moment(element.estimatedTransfer.isoString).toDate()
                        : null,
                  })),
                  totalCount: result.data.purchases.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'purchases' }),
          ]}
          title={i18n.get('purchases.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            // isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_FINANCE]),
            // isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createPurchase($id: ID!, $input: PurchaseInput!) {
                            createPurchase(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('purchases.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('purchases.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            // onRowUpdate: (newData, oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     try {
            //       const input = this.getInput(newData);

            //       const result = await graphql.clientWithToken(this.props.accessToken).mutate({
            //         variables: {
            //           id: newData.id,
            //           input: input,
            //         },
            //         mutation: gql`
            //           mutation updatePurchase($id: ID!, $input: PurchaseInput!) {
            //             updatePurchase(id: $id, input: $input) {
            //               id
            //             }
            //           }
            //         `,
            //       });

            //       await this.updateRelationships(newData, oldData);

            //       this.showMessage(i18n.get('purchases.message.onRowUpdate.success'), 'success');

            //       resolve(result);
            //     } catch (error) {
            //       this.showMessage(i18n.get('purchases.message.onRowUpdate.error'), 'error', error.message);
            //       reject(error);
            //     }
            //   }),
            // onRowDelete: (oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     try {
            //       const result = await graphql.clientWithToken(this.props.accessToken).mutate({
            //         variables: {
            //           id: oldData.id,
            //         },
            //         mutation: gql`
            //           mutation deletePurchase($id: ID!) {
            //             deletePurchase(id: $id)
            //           }
            //         `,
            //       });

            //       this.showMessage(i18n.get('purchases.message.onRowDelete.success'), 'success');

            //       resolve(result);
            //     } catch (error) {
            //       this.showMessage(i18n.get('purchases.message.onRowDelete.error'), 'error', error.message);
            //       reject(error);
            //     }
            //   }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Purchases.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Purchases)));
