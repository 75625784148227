import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import ChipLink from '../components/ChipLink';
import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_FINANCE } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { CURRENCIES, POINT_TYPE } from '../common/enums';

const styles = (theme) => ({});

const defaults = {
  id: true,
  used: false,
  type: false,
  patient: false,
  purchase: false,
  booking: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.points.defaults';

class Points extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (validator.isEmpty(data.id) || validator.isEmpty(data.type)) {
        throw new Error(i18n.get('points.error.validation'));
      }

      const input = {
        used: data.used || false,
        type: data.type,
      };

      return input;
    } catch (error) {
      throw new Error(i18n.get('points.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPatientsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                patients(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Patient {
                            id
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.patients.elements.map((element) => {
        return {
          name: `${element.firstname} ${element.lastname}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPatientsSuggestions = () => {
    return [];
  };

  getPurchasesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: IS, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                purchases(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Purchase {
                            id
                            amount {
                              float
                              currency
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.purchases.elements.map((element) => {
        return {
          name: `${helper.formatFloatNumber(element.amount.float)}${CURRENCIES[element.amount.currency]}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPurchasesSuggestions = () => {
    return [];
  };

  getBookingsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bookings(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Booking {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bookings.elements.map((element) => {
        return {
          name: `${moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBookingsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPatientID =
        newData.patient && newData.patient.length > 0
          ? newData.patient[0].value
          : newData.patient
          ? newData.patient.id
          : null;
      const oldPatientID = oldData && oldData.patient ? oldData.patient.id : null;

      if (newPatientID !== oldPatientID) {
        if (oldPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPatientID,
              pointId: newData.id,
            },
            mutation: gql`
              mutation unassignPointFromPatient($id: ID!, $pointId: ID!) {
                unassignPointFromPatient(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }

        if (newPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPatientID,
              pointId: newData.id,
            },
            mutation: gql`
              mutation assignPointToPatient($id: ID!, $pointId: ID!) {
                assignPointToPatient(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPurchaseId =
        newData.purchase && newData.purchase.length > 0
          ? newData.purchase[0].value
          : newData.purchase
          ? newData.purchase.id
          : null;
      const oldPurchaseId = oldData && oldData.purchase ? oldData.purchase.id : null;

      if (newPurchaseId !== oldPurchaseId) {
        if (oldPurchaseId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPurchaseId,
              pointId: newData.id,
            },
            mutation: gql`
              mutation unassignPointFromPurchase($id: ID!, $pointId: ID!) {
                unassignPointFromPurchase(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }

        if (newPurchaseId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPurchaseId,
              pointId: newData.id,
            },
            mutation: gql`
              mutation assignPointToPurchase($id: ID!, $pointId: ID!) {
                assignPointToPurchase(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newBookingID =
        newData.booking && newData.booking.length > 0
          ? newData.booking[0].value
          : newData.booking
          ? newData.booking.id
          : null;
      const oldBookingID = oldData && oldData.booking ? oldData.booking.id : null;

      if (newBookingID !== oldBookingID) {
        if (oldBookingID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldBookingID,
              pointId: newData.id,
            },
            mutation: gql`
              mutation unassignPointFromBooking($id: ID!, $pointId: ID!) {
                unassignPointFromBooking(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }

        if (newBookingID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newBookingID,
              pointId: newData.id,
            },
            mutation: gql`
              mutation assignPointToBooking($id: ID!, $pointId: ID!) {
                assignPointToBooking(id: $id, pointId: $pointId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('points.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('points.used'),
              field: 'used',
              hidden: this.default('used'),
              sorting: true,
              filtering: true,
              type: 'boolean',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('points.type'),
              field: 'type',
              hidden: this.default('type'),
              sorting: true,
              filtering: true,
              lookup: POINT_TYPE,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('points.patient'),
              field: 'patient',
              hidden: this.default('patient'),
              sorting: false,
              filtering: false,
              render: (rowData) => {
                if (rowData) {
                  return rowData.patient ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.customers'), data: { id: rowData.patient.id } }}
                      label={`${rowData.patient.firstname} ${rowData.patient.lastname}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.firstname} ${props.value.lastname}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('points.patient.label'),
                  i18n.get('required'),
                  this.getPatientsSuggestions,
                  this.getDefaultPatientsSuggestions
                );
              },
            },
            {
              title: i18n.get('points.purchase'),
              field: 'purchase',
              hidden: this.default('purchase'),
              sorting: false,
              filtering: false,
              render: (rowData) => {
                if (rowData) {
                  return rowData.purchase ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.purchases'), data: { id: rowData.purchase.id } }}
                      label={`${helper.formatFloatNumber(rowData.purchase.amount.float)}${
                        CURRENCIES[rowData.purchase.amount.currency] || ''
                      }`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${helper.formatFloatNumber(props.value.amount.float)}${
                            CURRENCIES[props.value.amount.currency] || ''
                          }`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('points.purchase.label'),
                  i18n.get('required'),
                  this.getPurchasesSuggestions,
                  this.getDefaultPurchasesSuggestions
                );
              },
            },
            {
              title: i18n.get('points.booking'),
              field: 'booking',
              hidden: this.default('booking'),
              sorting: false,
              filtering: false,
              render: (rowData) => {
                if (rowData) {
                  return rowData.booking ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.bookings'), data: { id: rowData.booking.id } }}
                      label={`${moment(rowData.booking.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${moment(props.value.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('points.booking.label'),
                  i18n.get('required'),
                  this.getBookingsSuggestions,
                  this.getDefaultBookingsSuggestions
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          points(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Point {
                                  id
                                  used
                                  type
                                  patient {
                                    id
                                    firstname
                                    lastname
                                  }
                                  purchase {
                                    id
                                    amount {
                                      float
                                      currency
                                    }
                                  }
                                  booking {
                                    id
                                    starts {
                                      isoString
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.points.elements,
                  totalCount: result.data.points.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'points' }),
          ]}
          title={i18n.get('points.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_FINANCE]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createPoint($id: ID!, $input: PointInput!) {
                            createPoint(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('points.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('points.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updatePoint($id: ID!, $input: PointInput!) {
                        updatePoint(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('points.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('points.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deletePoint($id: ID!) {
                        deletePoint(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('points.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('points.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Points.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Points)));
