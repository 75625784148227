import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import ChipLink from '../components/ChipLink';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as validator from 'validator';
import moment from 'moment';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_FINANCE } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { CURRENCIES } from '../common/enums';

const styles = (theme) => ({});

const defaults = {
  id: true,
  number: false,
  amount: false,
  netAmount: false,
  vat: false,
  date: false,
  practice: false,
  hcp: false,
  fees: false,
  transfer: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.invoices.defaults';

class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  makeAmountInput = (amount) => {
    const splittedValue = amount.float.toString().split('.');
    return {
      value: parseInt(splittedValue[0]),
      fraction: parseInt(splittedValue[1]),
      float: parseFloat(amount.float),
      currency: 'EUR',
    };
  };

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.number) ||
        validator.isNumeric(toString(data.amount.float)) ||
        validator.isNumeric(toString(data.netAmount.float)) ||
        validator.isNumeric(toString(data.vat.float)) ||
        validator.isEmpty(data.date.toString())
      ) {
        throw new Error(i18n.get('invoices.error.validation'));
      }

      const input = {};

      const date = moment(data.date).unix();
      data['date'] = date;

      helper.set(input, data, 'number');
      helper.set(input, data, 'amount', this.makeAmountInput);
      helper.set(input, data, 'netAmount', this.makeAmountInput);
      helper.set(input, data, 'vat', this.makeAmountInput);
      helper.set(input, data, 'date');

      return input;
    } catch (error) {
      throw new Error(i18n.get('invoices.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'amount.float':
        return 'amount_float';
      case 'netAmount.float':
        return 'netAmount_float';
      case 'vat.float':
        return 'vat_float';
      case 'fees':
        return 'fees_search_string';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  getTransfersSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                transfers(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Transfer {
                            id
                            date {
                              isoString
                            }
                            invoice {
                              number
                            }
                            bankAccount {
                              iban
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.transfers.elements.map((element) => {
        return {
          name: `${element.invoice?.number}, ${element.bankAccount.iban}, ${moment(element.date.isoString).format(
            'DD.MM.YYYY'
          )}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultTransfersSuggestions = () => {
    return [];
  };

  getFeesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                fees(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Fee {
                          id
                          amount {
                            float
                            currency
                          }
                          date {
                            isoString
                          }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.fees.elements.map((element) => {
        return {
          name: `${helper.formatFloatNumber(element.amount?.float)}${CURRENCIES[element.amount?.currency]}, ${moment(
            element.date.isoString
          ).format('DD.MM.YYYY')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultFeesSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation unassignInvoiceFromPractice($id: ID!, $invoiceId: ID!) {
                unassignInvoiceFromPractice(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation assignInvoiceToPractice($id: ID!, $invoiceId: ID!) {
                assignInvoiceToPractice(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newHCPID =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHCPID = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHCPID !== oldHCPID) {
        if (oldHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHCPID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation unassignInvoiceFromHCP($id: ID!, $invoiceId: ID!) {
                unassignInvoiceFromHCP(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }

        if (newHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHCPID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation assignInvoiceToHCP($id: ID!, $invoiceId: ID!) {
                assignInvoiceToHCP(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newTransferID =
        newData.transfer && newData.transfer.length > 0
          ? newData.transfer[0].value
          : newData.transfer
          ? newData.transfer.id
          : null;
      const oldTransferID = oldData && oldData.transfer ? oldData.transfer.id : null;

      if (newTransferID !== oldTransferID) {
        if (oldTransferID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldTransferID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation unassignInvoiceFromTransfer($id: ID!, $invoiceId: ID!) {
                unassignInvoiceFromTransfer(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }

        if (newTransferID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newTransferID,
              invoiceId: newData.id,
            },
            mutation: gql`
              mutation assignInvoiceToTransfer($id: ID!, $invoiceId: ID!) {
                assignInvoiceToTransfer(id: $id, invoiceId: $invoiceId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('invoices.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('invoices.number'),
              field: 'number',
              hidden: this.default('number'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('invoices.amount'),
              field: 'amount.float',
              hidden: this.default('amount'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.amount?.float)}${
                    CURRENCIES[rowData.amount?.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('invoices.netAmount'),
              field: 'netAmount.float',
              hidden: this.default('netAmount'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.netAmount?.float)}${
                    CURRENCIES[rowData.netAmount?.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('invoices.vat'),
              field: 'vat.float',
              hidden: this.default('vat'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.vat?.float)}${
                    CURRENCIES[rowData.vat?.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('invoices.date'),
              field: 'date',
              hidden: this.default('date'),
              sorting: true,
              filtering: true,
              type: 'datetime',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('invoices.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('invoices.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
            },
            {
              title: i18n.get('invoices.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                return helper.hcpChip(rowData.hcp);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('invoices.hcp.label'),
                  i18n.get('required'),
                  this.getHCPsSuggestions,
                  this.getDefaultHCPsSuggestions
                );
              },
            },
            {
              title: i18n.get('invoices.transfer'),
              field: 'transfer',
              hidden: this.default('transfer'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.invoice?.number || ''}, ${v.bankAccount.iban}, ${moment(v.date.isoString).format(
                        'DD.MM.YYYY'
                      )}`,
                      value: v.id,
                    };
                  }),
                this.getTransfersSuggestions,
                this.getDefaultTransfersSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.transfer ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.transfers'), data: { id: rowData.transfer.id } }}
                      label={`${rowData.transfer.invoice?.number || ''}, ${rowData.transfer.bankAccount.iban}, ${moment(
                        rowData.transfer.date.isoString
                      ).format('DD.MM.YYYY')} `}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.invoice?.number || ''}, ${props.value.bankAccount.iban}, ${moment(
                            props.value.date.isoString
                          ).format('DD.MM.YYYY')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('invoices.transfer.label'),
                  i18n.get('required'),
                  this.getTransfersSuggestions,
                  this.getDefaultTransfersSuggestions
                );
              },
            },
            {
              title: i18n.get('invoices.fees'),
              field: 'fees',
              hidden: this.default('fees'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${helper.formatFloatNumber(v.amount?.float)}${CURRENCIES[v.amount?.currency]}, ${moment(
                        v.date.isoString
                      ).format('DD.MM.YYYY')}`,
                      value: v.id,
                    };
                  }),
                this.getFeesSuggestions,
                this.getDefaultFeesSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData.fees
                    ? rowData.fees.map((fee) => {
                        return (
                          <ChipLink
                            link={{ page: i18n.get('menus.fees'), data: { id: fee.id } }}
                            key={fee.id}
                            label={`${helper.formatFloatNumber(fee.amount?.float)}${
                              CURRENCIES[fee.amount?.currency]
                            }, ${moment(fee.date.isoString).format('DD.MM.YYYY')}`}
                            style={{ marginRight: 8, marginBottom: 8 }}
                          />
                        );
                      })
                    : '-';
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'number', ['number']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          invoices(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Invoice {
                                  id
                                  number
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                  date {
                                    isoString
                                  }
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                  transfer {
                                    id
                                    invoice {
                                      number
                                    }
                                    bankAccount {
                                      iban
                                    }
                                    date {
                                      isoString
                                    }
                                  }
                                  fees {
                                    id
                                    amount {
                                      float
                                      currency
                                    }
                                    date {
                                      isoString
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.invoices.elements.map((element) => ({
                    ...element,
                    date: element.date?.isoString ? moment(element.date.isoString).toDate() : null,
                  })),
                  totalCount: result.data.invoices.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: SaveAltIcon,
              tooltip: i18n.get('invoices.downloadAction'),
              onClick: async (event, rowData) => {
                alert('TODO: export invoice');
              },
            },
            this.props.getJournalAction({ collection: 'invoices' }),
          ]}
          title={i18n.get('invoices.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_FINANCE]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createInvoice($id: ID!, $input: InvoiceInput!) {
                            createInvoice(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('invoices.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('invoices.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateInvoice($id: ID!, $input: InvoiceInput!) {
                        updateInvoice(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('invoices.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('invoices.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteInvoice($id: ID!) {
                        deleteInvoice(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('invoices.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('invoices.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Invoices)));
