import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as SEPA from 'sepa';

import ChipLink from '../components/ChipLink';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ExportIcon from '@material-ui/icons/SaveAlt';
import * as validator from 'validator';
import moment from 'moment';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_FINANCE } from '../common/iamV2';
import { TRANSFER_STATUSES } from '../common/enums';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  date: false,
  status: false,
  practice: false,
  hcp: false,
  invoice: false,
  bankAccount: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.transfers.defaults';

class Transfers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (validator.isEmpty(data.id) || validator.isEmpty(data.date.toString()) || validator.isEmpty(data.status)) {
        throw new Error(i18n.get('transfers.error.validation'));
      }

      const input = {};

      const date = moment(data.date).unix();
      data['date'] = date;

      helper.set(input, data, 'status');
      helper.set(input, data, 'date');

      return input;
    } catch (error) {
      throw new Error(i18n.get('transfers.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'invoice':
        return 'invoice_ref';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  getBankAccountsSuggestions = async (value) => {
    try {
      const where = `[{column: "iban", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bankAccounts(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on BankAccount {
                            id
                            iban
                            bic
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bankAccounts.elements.map((element) => {
        return {
          name: `${element.iban} ${element.bic}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBankAccountsSuggestions = () => {
    return [];
  };

  getInvoicesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                invoices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Invoice {
                            id
                            number
                            date {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.invoices.elements.map((element) => {
        return {
          name: `${element.number}, ${moment(element.date.isoString).format('DD.MM.YYYY')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultInvoicesSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation unassignTransferFromPractice($id: ID!, $transferId: ID!) {
                unassignTransferFromPractice(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation assignTransferToPractice($id: ID!, $transferId: ID!) {
                assignTransferToPractice(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newHCPID =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHCPID = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHCPID !== oldHCPID) {
        if (oldHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHCPID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation unassignTransferFromHCP($id: ID!, $transferId: ID!) {
                unassignTransferFromHCP(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }

        if (newHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHCPID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation assignTransferToHCP($id: ID!, $transferId: ID!) {
                assignTransferToHCP(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newBankAccountID =
        newData.bankAccount && newData.bankAccount.length > 0
          ? newData.bankAccount[0].value
          : newData.bankAccount
          ? newData.bankAccount.id
          : null;
      const oldBankAccountID = oldData && oldData.bankAccount ? oldData.bankAccount.id : null;

      if (newBankAccountID !== oldBankAccountID) {
        if (oldBankAccountID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldBankAccountID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation unassignTransferFromBankAccount($id: ID!, $transferId: ID!) {
                unassignTransferFromBankAccount(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }

        if (newBankAccountID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newBankAccountID,
              transferId: newData.id,
            },
            mutation: gql`
              mutation assignTransferToBankAccount($id: ID!, $transferId: ID!) {
                assignTransferToBankAccount(id: $id, transferId: $transferId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  getConfig = async () => {
    const where = `[{column: "key", comparator: IN, values: ["initiatorName","debtorIBAN","debtorBIC","debtorId"]}]`;

    const result = await graphql.clientWithToken(this.props.accessToken).query({
      query: gql`
            query {
                masters(where:${where}) {
                    totalCount
                    elements {
                        ... on Master {
                            id
                            key
                            value
                       }
                    }
                }
            }
        `,
      fetchPolicy: 'no-cache',
    });

    return result.data.masters.elements.reduce((acc, element) => {
      return {
        ...acc,
        [element.key]: element.value,
      };
    }, {});
  };

  generateExportFile = async (event, rowData) => {
    const transactions = this.tableRef.current.state.data;
    if (transactions.length > 0) {
      try {
        const CONFIG = await this.getConfig();
        const doc = new SEPA.Document('pain.001.001.03');
        doc.grpHdr.id = `XMPL.${moment().unix()}`;
        doc.grpHdr.created = new Date();
        doc.grpHdr.initiatorName = CONFIG.initiatorName;

        transactions.forEach((transaction) => {
          const info = doc.createPaymentInfo();
          info.requestedExecutionDate = transaction.date;
          info.debtorIBAN = CONFIG.debtorIBAN;
          info.debtorBIC = CONFIG.debtorBIC;
          info.debtorName = transaction.practice.name;
          info.debtorId = CONFIG.debtorId;
          doc.addPaymentInfo(info);

          const tx = info.createTransaction();
          tx.creditorName = `${transaction.hcp.firstname} ${transaction.hcp.lastname}`;
          tx.creditorIBAN = transaction.bankAccount.iban;
          tx.creditorBIC = transaction.bankAccount.bic;
          // tx.mandateId = 'XMPL.CUST487.2014';
          tx.mandateSignatureDate = transaction.date;
          tx.amount = transaction.invoice?.amount.float || 0.1;
          tx.remittanceInfo = `INVOICE ${transaction.invoice?.number || ''}`;
          tx.end2endId = `XMPL.HCP${transaction.hcp.id}.INVOICE.${transaction.invoice?.number || ''}'`;
          info.addTransaction(tx);
        });

        helper.exportFile('xml', 'transactions', doc.toString());
      } catch (error) {
        this.showMessage(i18n.get('transfers.message.export.error'), 'error', error.message);
      }
    } else {
      this.showMessage(i18n.get('transfers.message.export.emptyArray'), 'error');
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('transfers.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('transfers.date'),
              field: 'date',
              hidden: this.default('date'),
              sorting: true,
              filtering: true,
              type: 'datetime',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('transfers.status'),
              field: 'status',
              hidden: this.default('status'),
              sorting: true,
              filtering: true,
              lookup: TRANSFER_STATUSES,
              initialEditValue: 'NEW',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('transfers.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('transfers.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
            },
            {
              title: i18n.get('transfers.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                return helper.hcpChip(rowData.hcp);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('transfers.hcp.label'),
                  i18n.get('required'),
                  this.getHCPsSuggestions,
                  this.getDefaultHCPsSuggestions
                );
              },
            },
            {
              title: i18n.get('transfers.bankAccount'),
              field: 'bankAccount',
              hidden: this.default('bankAccount'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.iban}`,
                      value: v.id,
                    };
                  }),
                this.getBankAccountsSuggestions,
                this.getDefaultBankAccountsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.bankAccount ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.bankAccounts'), data: { id: rowData.bankAccount.id } }}
                      label={`${rowData.bankAccount.iban}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id ? [{ name: `${props.value.iban}`, value: props.value.id }] : [],
                  i18n.get('transfers.bankAccount.label'),
                  i18n.get('required'),
                  this.getBankAccountsSuggestions,
                  this.getDefaultBankAccountsSuggestions
                );
              },
            },
            {
              title: i18n.get('transfers.invoice'),
              field: 'invoice',
              hidden: this.default('invoice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.number}, ${moment(v.date.isoString).format('DD.MM.YYYY')}`,
                      value: v.id,
                    };
                  }),
                this.getInvoicesSuggestions,
                this.getDefaultInvoicesSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData.invoice ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.invoices'), data: { id: rowData.invoice.id } }}
                      label={`${rowData.invoice.number}, ${moment(rowData.invoice.date.isoString).format(
                        'DD.MM.YYYY'
                      )}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'date', ['date']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          transfers(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Transfer {
                                  id
                                  date {
                                    isoString
                                  }
                                  status
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                  invoice {
                                    id
                                    number
                                    date {
                                      isoString
                                    }
                                    amount {
                                      float
                                    }
                                  }
                                  bankAccount {
                                    id
                                    iban
                                    bic
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.transfers.elements.map((element) => ({
                    ...element,
                    date: element.date.isoString ? moment(element.date.isoString).toDate() : null,
                  })),
                  totalCount: result.data.transfers.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              isFreeAction: true,
              icon: ExportIcon,
              tooltip: i18n.get('general.exportSepaXml'),
              onClick: this.generateExportFile,
            },
            this.props.getJournalAction({ collection: 'transfers' }),
          ]}
          title={i18n.get('transfers.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_FINANCE]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createTransfer($id: ID!, $input: TransferInput!) {
                            createTransfer(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('transfers.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('transfers.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateTransfer($id: ID!, $input: TransferInput!) {
                        updateTransfer(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('transfers.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('transfers.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteTransfer($id: ID!) {
                        deleteTransfer(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('transfers.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('transfers.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Transfers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Transfers)));
