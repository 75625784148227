import React, { Component, forwardRef } from 'react';
import Table from 'material-table';

import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import * as i18n from '../common/i18n';

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default class MaterialTable extends Component {
  tableRef = this.props.tableRef || React.createRef();

  resetAllFilters = () => {
    let self = this.tableRef.current; // get the tableRef
    console.log(self);
    let query = self.state.query; // get the query obj
    if (query.filters.length > 0) {
      self.setState({
        ...self.dataManager.getRenderState(), // set the render state
        query: {
          ...query,
          filters: [], // set filters to nothing
        },
      });
      // you also need to update the filter value on the column by doing this:
      for (var item in query.filters) {
        let tableColumn = query.filters[item].column;
        self.dataManager.changeFilterValue(tableColumn.tableData.id, null);
      }
      // trigger onFilterChangeDebounce
      self.setState({}, self.onFilterChangeDebounce);
    }
  };

  render() {
    const { tableRef, ...others } = this.props;
    const actions = others?.actions || [];
    if (others?.options?.resetFiltersButton) {
      actions.unshift({
        isFreeAction: true,
        icon: () => (
          <Badge max={99} badgeContent={this.tableRef?.current?.state?.query?.filters.length} color="secondary">
            <FilterListIcon
              color={this.tableRef?.current?.state?.query?.filters.length === 0 ? 'disabled' : 'primary'}
            />
          </Badge>
        ),
        tooltip: i18n.get('general.table.actions.resetAllFilters'),
        onClick: () => {
          this.resetAllFilters();
        },
      });
    }
    return (
      <Table
        icons={tableIcons}
        localization={i18n.getMaterialTableLocalization()}
        resetAllFilters={this.resetAllFilters}
        tableRef={this.tableRef}
        {...others}
        actions={actions}
      />
    );
  }
}
