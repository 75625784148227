import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';

const debounce = (func, wait) => {
  let timeout;

  return (args) => {
    clearTimeout(timeout);
    args.persist();
    timeout = setTimeout(() => {
      timeout = null;
      func(args);
    }, wait);
  };
};

function renderInput(inputProps, debounceOff) {
  const { value, onChange, chips, ref, ...other } = inputProps;

  return (
    <ChipInput
      clearInputValueOnChange
      onUpdateInput={!debounceOff ? debounce(onChange, 450) : onChange}
      value={chips}
      inputRef={ref}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={(e) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    // left: 0,
    // right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
});

class Chips extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxLength: this.props.maxLength ? this.props.maxLength : 1,
      suggestions: this.props.getDefaultSuggestions(),
      value: this.props.value,
      textFieldInput: '',
    };
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    const results = await this.props.getSuggestions(value);

    this.setState({
      suggestions: results,
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.getDefaultSuggestions(),
    });
  };

  handletextFieldInputChange = (event, { newValue }) => {
    this.setState({
      textFieldInput: newValue.value ? newValue.name : newValue,
    });
  };

  handleAddChip(chip) {
    if (
      (this.props.allowDuplicates || this.state.value.map((v) => v.value).indexOf(chip.value) < 0) &&
      this.state.value.length < this.state.maxLength
    ) {
      this.setState(
        ({ value }) => ({
          value: [...value, chip],
          textFieldInput: '',
        }),
        () => {
          this.props.onChanged(this.state.value);
        }
      );
    } else {
      this.setState({ textFieldInput: '' });
    }
  }

  handleBeforeAddChip(value) {
    return value.value !== undefined && this.state.value.length < this.state.maxLength;
  }

  handleDeleteChip(chip, index) {
    this.setState(
      ({ value }) => {
        const temp = value.slice();
        temp.splice(index, 1);
        return {
          value: temp,
        };
      },
      () => {
        this.props.onChanged(this.state.value);
      }
    );
  }

  render() {
    const { classes, placeholder, label } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={(props) => {
          return renderInput(props, this.props.debounceOff);
        }}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        alwaysRenderSuggestions={false}
        highlightFirstSuggestion={true}
        multiSection={this.props.multiSection}
        renderSectionTitle={this.props.renderSectionTitle}
        getSectionSuggestions={this.props.getSectionSuggestions}
        onSuggestionSelected={(e, { suggestionValue }) => {
          this.handleAddChip(suggestionValue);
          e.preventDefault();
        }}
        focusInputOnSuggestionClick={true}
        inputProps={{
          chipRenderer: this.props.chipRenderer
            ? this.props.chipRenderer
            : ({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => {
                if (React.isValidElement(text)) {
                  return React.cloneElement(text, {
                    color: `${isFocused ? 'primary' : 'default'}`,
                    onClick: handleClick,
                    onDelete: handleDelete,
                  });
                }
                return (
                  <Chip
                    key={text}
                    color={`${isFocused ? 'primary' : 'default'}`}
                    label={text}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
                  />
                );
              },
          chips: this.state.value,
          dataSourceConfig: { text: 'name', value: 'value' },
          value: this.state.textFieldInput,
          onChange: this.handletextFieldInputChange,
          onBeforeAdd: (value) => this.handleBeforeAddChip(value),
          onAdd: (chip) => this.handleAddChip(chip),
          onDelete: (chip, index) => this.handleDeleteChip(chip, index),
          placeholder: placeholder,
          label: label,
        }}
      />
    );
  }
}

Chips.propTypes = {
  allowDuplicates: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Chips);
