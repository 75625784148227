import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/es/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import Admin from './main/Admin';
import Login from './main/Login';
import Page404 from './main/Page404';
import CompleteRegistration from './main/CompleteRegistration';
import { adminTheme } from './common/theme';
import ErrorBoundary from './common/error';

import { ROUTES } from './common/constants';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.css';
import IAMContextProvider from './common/iamV2';

function AdminWrapper() {
  return (
    <MuiThemeProvider theme={adminTheme}>
      <CssBaseline /> {/* initialize css */}
      <ErrorBoundary>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
          <IAMContextProvider>
            <Admin />
          </IAMContextProvider>
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.COMPLETE_REGISTRATION} component={CompleteRegistration} />
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.BASE} component={AdminWrapper} />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
