import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountIcon from '@material-ui/icons/AccountBalance';
import CalenderIcon from '@material-ui/icons/CalendarToday';
import LinkIcon from '@material-ui/icons/Link';
import LocationIcon from '@material-ui/icons/LocationOn';
import CityIcon from '@material-ui/icons/LocationCity';
import ConfirmationIcon from '@material-ui/icons/ConfirmationNumber';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EuroIcon from '@material-ui/icons/Euro';
// import CardMembershipIcon from '@material-ui/icons/CardMembership';
import SubjectIcon from '@material-ui/icons/Subject';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import HospitalIcon from '@material-ui/icons/LocalHospitalOutlined';
import GroupIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import ListIcon from '@material-ui/icons/List';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import StarIcon from '@material-ui/icons/Star';
import ShopIcon from '@material-ui/icons/Shop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReportIcon from '@material-ui/icons/Report';
import SettingsIcon from '@material-ui/icons/Settings';
import BookIcon from '@material-ui/icons/Book';
import SecurityIcon from '@material-ui/icons/Security';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PhoneIcon from '@material-ui/icons/Phone';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import Users from '../pages/Users';
import Practices from '../pages/Practices';
import EnrolledPractices from '../pages/EnrolledPractices';
import OnboardingPractices from '../pages/OnboardingPractices';
import Locations from '../pages/Locations';
import Cities from '../pages/Cities';
import Dashboard from '../pages/Dashboard';
import Hcps from '../pages/Hcps';
import EnrolledHcps from '../pages/EnrolledHcps';
import OnboardingHcps from '../pages/OnboardingHcps';
import BankAccounts from '../pages/BankAccounts';
import Texts from '../pages/Texts';
import TextsOnboarding from '../pages/TextsOnboarding';
import Specializations from '../pages/Specializations';
import ContractLevels from '../pages/ContractLevels';
import Constraints from '../pages/Constraints';
import Lots from '../pages/Lots';
import Patients from '../pages/Patients';
import Bookings from '../pages/Bookings';
import Reviews from '../pages/Reviews';
import Equipments from '../pages/Equipments';
import Qualifications from '../pages/Qualifications';
import Companies from '../pages/Companies';
import Codes from '../pages/Codes';
import Journals from '../pages/Journals';
import Photos from '../pages/Photos';
import Purchases from '../pages/Purchases';
import Points from '../pages/Points';
import Fees from '../pages/Fees';
import Invoices from '../pages/Invoices';
import Transfers from '../pages/Transfers';
import FinancialReports from '../pages/FinancialReports';
import ReviewReports from '../pages/ReviewReports';
import WorkloadReports from '../pages/WorkloadReports';
import Reports from '../pages/Reports';
import UnwantedKeywords from '../pages/UnwantedKeywords';
import UnwantedImages from '../pages/UnwantedImages';
import Masters from '../pages/Masters';
import Imprint from '../pages/Imprint';
import Privacy from '../pages/Privacy';
import Tos from '../pages/Tos';
import FAQ from '../pages/FAQ';
import CooperativeAgreements from '../pages/CooperativeAgreements';
import SystemMessages from '../pages/SystemMessages';
import Phones from '../pages/Phones';
import IconWithBadge from '../components/IconWithBadge';
import * as i18n from '../common/i18n';

import { ROLE_ADMIN, ROLE_FINANCE, ROLE_SUPPORT, ROLE_TECHNICAL } from '../common/iamV2';

export const menus = [
  {
    entries: [
      {
        primary: i18n.get('menus.dashboard'),
        icon: <DashboardIcon />,
        component: <Dashboard />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_FINANCE, ROLE_SUPPORT, ROLE_TECHNICAL],
  },
  {
    title: i18n.get('menus.healthProvider'),
    entries: [
      {
        primary: i18n.get('menus.practices'),
        icon: (
          <IconWithBadge
            icon={<HospitalIcon />}
            selector={(data) => {
              return data.enroledPractices;
            }}
          />
        ),
        component: <Practices />,
      },
      {
        primary: i18n.get('menus.enrolledpractices'),
        icon: <HospitalIcon />,
        component: <EnrolledPractices />,
      },
      {
        primary: i18n.get('menus.onboardingpractices'),
        icon: <HospitalIcon />,
        component: <OnboardingPractices />,
      },
      {
        primary: i18n.get('menus.locations'),
        icon: <LocationIcon />,
        component: <Locations />,
      },
      {
        primary: i18n.get('menus.cities'),
        icon: <CityIcon />,
        component: <Cities />,
      },
      {
        primary: i18n.get('menus.bankAccounts'),
        icon: <AccountIcon />,
        component: <BankAccounts />,
      },

      {
        primary: i18n.get('menus.hcps'),
        icon: (
          <IconWithBadge
            icon={<PersonAddIcon />}
            selector={(data) => {
              return data.enroledHCPs;
            }}
          />
        ),
        component: <Hcps />,
      },
      {
        primary: i18n.get('menus.enrolledhcps'),
        icon: <PersonAddIcon />,
        component: <EnrolledHcps />,
      },
      {
        primary: i18n.get('menus.onboardinghcps'),
        icon: <PersonAddIcon />,
        component: <OnboardingHcps />,
      },
      {
        primary: i18n.get('menus.lots'),
        icon: <EventNoteIcon />,
        component: <Lots />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_SUPPORT, ROLE_FINANCE, ROLE_TECHNICAL],
  },
  {
    title: i18n.get('menus.patients'),
    entries: [
      {
        primary: i18n.get('menus.customers'),
        icon: <GroupIcon />,
        component: <Patients />,
      },
      {
        primary: i18n.get('menus.bookings'),
        icon: <ConfirmationIcon />,
        component: <Bookings />,
      },
      {
        primary: i18n.get('menus.reviews'),
        icon: <StarHalfIcon />,
        component: <Reviews />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_SUPPORT, ROLE_FINANCE, ROLE_TECHNICAL],
  },
  {
    title: i18n.get('menus.finance'),
    entries: [
      {
        primary: i18n.get('menus.purchases'),
        icon: <ShopIcon />,
        component: <Purchases />,
      },
      {
        primary: i18n.get('menus.points'),
        icon: <LocalOfferIcon />,
        component: <Points />,
      },
      {
        primary: i18n.get('menus.fees'),
        icon: <EuroIcon />,
        component: <Fees />,
      },
      {
        primary: i18n.get('menus.invoices'),
        icon: <ReceiptIcon />,
        component: <Invoices />,
      },
      {
        primary: i18n.get('menus.transfers'),
        icon: <SyncAltIcon />,
        component: <Transfers />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_FINANCE],
  },
  {
    title: i18n.get('menus.reports'),
    entries: [
      {
        primary: i18n.get('menus.financialReport'),
        icon: <TrendingUpIcon />,
        component: <FinancialReports />,
      },
      {
        primary: i18n.get('menus.reviewReport'),
        icon: <BarChartIcon />,
        component: <ReviewReports />,
      },
      {
        primary: i18n.get('menus.workloadReport'),
        icon: <PieChartIcon />,
        component: <WorkloadReports />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_FINANCE],
  },
  {
    title: i18n.get('menus.fraudDetection'),
    entries: [
      {
        primary: i18n.get('menus.reports'),
        icon: <ReportIcon />,
        component: <Reports />,
      },
      {
        primary: i18n.get('menus.unwantedKeywords'),
        icon: <SpeakerNotesOffIcon />,
        component: <UnwantedKeywords />,
      },
      {
        primary: i18n.get('menus.unwantedImages'),
        icon: <BrokenImageIcon />,
        component: <UnwantedImages />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_SUPPORT],
  },
  {
    title: i18n.get('menus.content'),
    entries: [
      {
        primary: i18n.get('menus.text'),
        icon: <SubjectIcon />,
        component: <Texts />,
      },
      {
        primary: i18n.get('menus.textOnboarding'),
        icon: <SubjectIcon />,
        component: <TextsOnboarding />,
      },
      {
        primary: i18n.get('menus.phones'),
        icon: <PhoneIcon />,
        component: <Phones />,
      },

      {
        primary: i18n.get('menus.specializations'),
        icon: <SchoolIcon />,
        component: <Specializations />,
      },
      {
        primary: i18n.get('menus.qualifications'),
        icon: <StarIcon />,
        component: <Qualifications />,
      },
      {
        primary: i18n.get('menus.equipments'),
        icon: <LocalActivityIcon />,
        component: <Equipments />,
      },
      {
        primary: i18n.get('menus.contractlevels'),
        icon: <CalenderIcon />,
        component: <ContractLevels />,
      },
      {
        primary: i18n.get('menus.constraints'),
        icon: <LinkIcon />,
        component: <Constraints />,
      },
      {
        primary: i18n.get('menus.photos'),
        icon: <PhotoIcon />,
        component: <Photos />,
      },
      {
        primary: i18n.get('menus.masters'),
        icon: <SettingsIcon />,
        component: <Masters />,
      },
      {
        primary: `${i18n.get('menus.imprint')}`,
        icon: <BookIcon />,
        component: <Imprint />,
      },
      {
        primary: `${i18n.get('menus.privacy')}`,
        icon: <SecurityIcon />,
        component: <Privacy />,
      },
      {
        primary: `${i18n.get('menus.tos')}`,
        icon: <MenuBookIcon />,
        component: <Tos />,
      },
      {
        primary: `${i18n.get('menus.faq')}`,
        icon: <LiveHelpIcon />,
        component: <FAQ />,
      },
      {
        primary: `${i18n.get('menus.cooperativeAgreements')}`,
        icon: <ImportContactsIcon />,
        component: <CooperativeAgreements />,
      },
      {
        primary: `${i18n.get('menus.systemmessages')}`,
        icon: <AnnouncementIcon />,
        component: <SystemMessages />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_TECHNICAL],
  },
  {
    title: i18n.get('menus.vauchers'),
    entries: [
      {
        primary: i18n.get('menus.companies'),
        icon: <CardGiftcardIcon />,
        component: <Companies />,
      },
      {
        primary: i18n.get('menus.codes'),
        icon: <HorizontalSplitIcon />,
        component: <Codes />,
      },
    ],
    roles: [ROLE_ADMIN, ROLE_SUPPORT, ROLE_FINANCE],
  },
  {
    title: i18n.get('menus.other'),
    entries: [
      {
        primary: i18n.get('menus.journals'),
        icon: <ListIcon />,
        component: <Journals />,
      },
    ],
    roles: [ROLE_ADMIN],
  },
  {
    title: i18n.get('menus.organization'),
    entries: [
      {
        primary: i18n.get('menus.users'),
        icon: <PeopleIcon />,
        component: <Users />,
      },
    ],
    roles: [ROLE_ADMIN],
  },
];
