import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';

function IconWithBadge(props) {
  const value = props.selector ? props.selector(props.data) : props.value;

  return value !== '' ? (
    <Badge badgeContent={value} color={props.color || 'secondary'}>
      {props.icon}
    </Badge>
  ) : (
    props.icon
  );
}

export default IconWithBadge;

IconWithBadge.propTypes = {
  data: PropTypes.object,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  selector: PropTypes.func,
  icon: PropTypes.node.isRequired,
};
