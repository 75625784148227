import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import ChipLink from '../components/ChipLink';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import moment from 'moment';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_FINANCE } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { CURRENCIES } from '../common/enums';

const styles = (theme) => ({});

const defaults = {
  id: true,
  amount: false,
  netAmount: false,
  vat: false,
  date: false,
  estimatedTransfer: false,
  contractlevel: false,
  practice: false,
  hcp: false,
  lot: false,
  invoice: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.fees.defaults';

class Fees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  makeAmountInput = (amount) => {
    const splittedValue = amount.float.toString().split('.');
    return {
      value: parseInt(splittedValue[0]),
      fraction: parseInt(splittedValue[1]),
      float: parseFloat(amount.float),
      currency: 'EUR',
    };
  };

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isNumeric(toString(data.amount.float)) ||
        validator.isNumeric(toString(data.netAmount.float)) ||
        validator.isNumeric(toString(data.vat.float)) ||
        validator.isEmpty(data.date.toString()) ||
        validator.isEmpty(data.estimatedTransfer.toString())
      ) {
        throw new Error(i18n.get('fees.error.validation'));
      }

      const input = {};

      const date = moment(data.date).unix();
      const estimatedTransfer = moment(data.estimatedTransfer).unix();
      data['date'] = date;
      data['estimatedTransfer'] = estimatedTransfer;

      helper.set(input, data, 'amount', this.makeAmountInput);
      helper.set(input, data, 'netAmount', this.makeAmountInput);
      helper.set(input, data, 'vat', this.makeAmountInput);
      helper.set(input, data, 'date');
      helper.set(input, data, 'estimatedTransfer');

      return input;
    } catch (error) {
      throw new Error(i18n.get('fees.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'amount.float':
        return 'amount_float';
      case 'netAmount.float':
        return 'netAmount_float';
      case 'vat.float':
        return 'vat_float';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  getLotsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                lots(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Lot {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.lots.elements.map((element) => {
        return {
          name: `${moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultLotsSuggestions = () => {
    return [];
  };

  getInvoicesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                invoices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Invoice {
                            id
                            number
                            date {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.invoices.elements.map((element) => {
        return {
          name: `${element.number}, ${moment(element.date.isoString).format('DD.MM.YYYY')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultInvoicesSuggestions = () => {
    return [];
  };

  getContractlevelsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LT, string: "${value}~"},{column: "id", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                contractlevels(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Contractlevel {
                            id
                            label
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.contractlevels.elements.map((element) => {
        return {
          name: `${element.label}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultContractlevelsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation unassignFeeFromPractice($id: ID!, $feeId: ID!) {
                unassignFeeFromPractice(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation assignFeeToPractice($id: ID!, $feeId: ID!) {
                assignFeeToPractice(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newHCPID =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHCPID = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHCPID !== oldHCPID) {
        if (oldHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHCPID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation unassignFeeFromHCP($id: ID!, $feeId: ID!) {
                unassignFeeFromHCP(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }

        if (newHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHCPID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation assignFeeToHCP($id: ID!, $feeId: ID!) {
                assignFeeToHCP(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newLotID =
        newData.lot && newData.lot.length > 0 ? newData.lot[0].value : newData.lot ? newData.lot.id : null;
      const oldLotID = oldData && oldData.lot ? oldData.lot.id : null;

      if (newLotID !== oldLotID) {
        if (oldLotID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldLotID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation unassignFeeFromLot($id: ID!, $feeId: ID!) {
                unassignFeeFromLot(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }

        if (newLotID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newLotID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation assignFeeToLot($id: ID!, $feeId: ID!) {
                assignFeeToLot(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newInvoiceID =
        newData.invoice && newData.invoice.length > 0
          ? newData.invoice[0].value
          : newData.invoice
          ? newData.invoice.id
          : null;
      const oldInvoiceID = oldData && oldData.invoice ? oldData.invoice.id : null;

      if (newInvoiceID !== oldInvoiceID) {
        if (oldInvoiceID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldInvoiceID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation unassignFeeFromInvoice($id: ID!, $feeId: ID!) {
                unassignFeeFromInvoice(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }

        if (newInvoiceID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newInvoiceID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation assignFeeToInvoice($id: ID!, $feeId: ID!) {
                assignFeeToInvoice(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newContractlevelID =
        newData.contractlevel && newData.contractlevel.length > 0
          ? newData.contractlevel[0].value
          : newData.contractlevel
          ? newData.contractlevel.id
          : null;
      const oldContractlevelID = oldData && oldData.contractlevel ? oldData.contractlevel.id : null;

      if (newContractlevelID !== oldContractlevelID) {
        if (oldContractlevelID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldContractlevelID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation unassignFeeFromContractlevel($id: ID!, $feeId: ID!) {
                unassignFeeFromContractlevel(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }

        if (newContractlevelID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newContractlevelID,
              feeId: newData.id,
            },
            mutation: gql`
              mutation assignFeeToContractlevel($id: ID!, $feeId: ID!) {
                assignFeeToContractlevel(id: $id, feeId: $feeId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('fees.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('fees.amount'),
              field: 'amount.float',
              hidden: this.default('amount'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.amount.float)}${
                    CURRENCIES[rowData.amount.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('fees.netAmount'),
              field: 'netAmount.float',
              hidden: this.default('netAmount'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.netAmount.float)}${
                    CURRENCIES[rowData.netAmount.currency]
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('fees.vat'),
              field: 'vat.float',
              hidden: this.default('vat'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.vat.float)}${CURRENCIES[rowData.vat.currency]}`}</span>
                );
              },
            },
            {
              title: i18n.get('fees.date'),
              field: 'date',
              hidden: this.default('date'),
              sorting: true,
              filtering: true,
              type: 'datetime',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('fees.estimatedTransfer'),
              field: 'estimatedTransfer',
              hidden: this.default('estimatedTransfer'),
              sorting: true,
              filtering: true,
              type: 'date',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('fees.contractlevel'),
              field: 'contractlevel',
              hidden: this.default('contractlevel'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label}`,
                      value: v.id,
                    };
                  }),
                this.getContractlevelsSuggestions,
                this.getDefaultContractlevelsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.contractlevel ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.contractlevels'), data: { id: rowData.contractlevel.id } }}
                      label={`${rowData.contractlevel.label}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id ? [{ name: `${props.value.label}`, value: props.value.id }] : [],
                  i18n.get('fees.contractlevel.label'),
                  i18n.get('required'),
                  this.getContractlevelsSuggestions,
                  this.getDefaultContractlevelsSuggestions
                );
              },
            },
            {
              title: i18n.get('fees.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('fees.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
            },
            {
              title: i18n.get('fees.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                return helper.hcpChip(rowData.hcp);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('fees.hcp.label'),
                  i18n.get('required'),
                  this.getHCPsSuggestions,
                  this.getDefaultHCPsSuggestions
                );
              },
            },
            {
              title: i18n.get('fees.lot'),
              field: 'lot',
              hidden: this.default('lot'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                      value: v.id,
                    };
                  }),
                this.getLotsSuggestions,
                this.getDefaultLotsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.lot ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.lots'), data: { id: rowData.lot.id } }}
                      key={rowData.lot.id}
                      label={`${moment(rowData.lot.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${moment(props.value.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('fees.lot.label'),
                  i18n.get('required'),
                  this.getLotsSuggestions,
                  this.getDefaultLotsSuggestions
                );
              },
            },
            {
              title: i18n.get('fees.invoice'),
              field: 'invoice',
              hidden: this.default('invoice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.number}, ${moment(v.date.isoString).format('DD.MM.YYYY')}`,
                      value: v.id,
                    };
                  }),
                this.getInvoicesSuggestions,
                this.getDefaultInvoicesSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.invoice ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.invoices'), data: { id: rowData.invoice.id } }}
                      label={`${rowData.invoice.number}, ${moment(rowData.invoice.date.isoString).format(
                        'DD.MM.YYYY'
                      )}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.number}, ${moment(props.value.date.isoString).format('DD.MM.YYYY')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('fees.invoice.label'),
                  i18n.get('required'),
                  this.getInvoicesSuggestions,
                  this.getDefaultInvoicesSuggestions
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'date', ['date']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          fees(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Fee {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                  date {
                                    isoString
                                  }
                                  estimatedTransfer {
                                    isoString
                                  }
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                  lot {
                                    id
                                    starts {
                                      isoString
                                    }
                                  }
                                  invoice {
                                    id
                                    number
                                    date {
                                      isoString
                                    }
                                  }
                                  contractlevel {
                                    id
                                    label
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.fees.elements.map((element) => ({
                    ...element,
                    date: element.date.isoString ? moment(element.date.isoString).toDate() : null,
                    estimatedTransfer: element.estimatedTransfer.isoString
                      ? moment(element.estimatedTransfer.isoString).toDate()
                      : null,
                  })),
                  totalCount: result.data.fees.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'fees' }),
          ]}
          title={i18n.get('fees.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_FINANCE]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createFee($id: ID!, $input: FeeInput!) {
                            createFee(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('fees.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('fees.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateFee($id: ID!, $input: FeeInput!) {
                        updateFee(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('fees.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('fees.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteFee($id: ID!) {
                        deleteFee(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('fees.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('fees.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Fees.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Fees)));
