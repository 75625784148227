import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ChipLink from '../components/ChipLink';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  label: false,
  maxMin: false,
  weekdays: false,
  holidays: false,
  saturdays: false,
  sundays: false,
  starts: false,
  ends: false,
  contractlevels: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.constraints.defaults';

class Constraints extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.label) ||
        validator.isEmpty(data.starts.toString()) ||
        validator.isEmpty(data.ends.toString())
      ) {
        throw new Error(i18n.get('constraints.error.validation'));
      }

      const input = {
        weekdays: data.weekdays || false,
        holidays: data.holidays || false,
        saturdays: data.saturdays || false,
        sundays: data.sundays || false,
      };

      const starts = moment(data.starts).unix();
      const ends = moment(data.ends).unix();
      data['starts'] = starts;
      data['ends'] = ends;

      helper.set(input, data, 'label');
      helper.set(input, data, 'starts');
      helper.set(input, data, 'ends');

      return input;
    } catch (error) {
      throw new Error(i18n.get('constraints.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getContractlevelsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LT, string: "${value}~"},{column: "id", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                contractlevels(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Contractlevel {
                            id
                            label
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.contractlevels.elements.map((element) => {
        return {
          name: `${element.label}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultContractlevelsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newContractlevelIds = newData.contractlevels
        ? newData.contractlevels.map((p) => (p.value ? p.value : p.id))
        : [];
      const oldContractlevelIds = oldData && oldData.contractlevels ? oldData.contractlevels.map((p) => p.id) : [];

      const addedContractlevelIds = helper.diffArray(newContractlevelIds, oldContractlevelIds);

      for (const addedContractlevelId of addedContractlevelIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: addedContractlevelId,
            constraintId: newData.id,
          },
          mutation: gql`
            mutation assignConstraintToContractlevel($id: ID!, $constraintId: ID!) {
              assignConstraintToContractlevel(id: $id, constraintId: $constraintId) {
                id
              }
            }
          `,
        });
      }

      // these I need to remove
      const removedContractlevelIds = helper.diffArray(oldContractlevelIds, newContractlevelIds);

      for (const removedContractlevelId of removedContractlevelIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: removedContractlevelId,
            constraintId: newData.id,
          },
          mutation: gql`
            mutation unassignConstraintFromContractlevel($id: ID!, $constraintId: ID!) {
              unassignConstraintFromContractlevel(id: $id, constraintId: $constraintId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('constraints.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('constraints.label'),
              field: 'label',
              hidden: this.default('label'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('constraints.weekdays'),
              field: 'weekdays',
              hidden: this.default('weekdays'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.holidays'),
              field: 'holidays',
              hidden: this.default('holidays'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.saturdays'),
              field: 'saturdays',
              hidden: this.default('saturdays'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.sundays'),
              field: 'sundays',
              hidden: this.default('sundays'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.starts'),
              field: 'starts',
              hidden: this.default('starts'),
              type: 'time',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.ends'),
              field: 'ends',
              hidden: this.default('ends'),
              type: 'time',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('constraints.contractlevels'),
              field: 'contractlevels',
              hidden: this.default('contractlevels'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label}`,
                      value: v.id,
                    };
                  }),
                this.getContractlevelsSuggestions,
                this.getDefaultContractlevelsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.contractlevels
                    ? rowData.contractlevels.map((contractlevel) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.contractlevels'), data: { id: contractlevel.id } }}
                          key={contractlevel.id}
                          label={`${contractlevel.label}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label}`, value: v.id };
                      })
                    : [],
                  i18n.get('constraints.contractlevels.label'),
                  i18n.get('required'),
                  this.getContractlevelsSuggestions,
                  this.getDefaultContractlevelsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          constraints(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Constraint {
                                  id,
                                  label
                                  weekdays
                                  holidays
                                  saturdays
                                  sundays
                                  starts {
                                    isoString
                                  }
                                  ends {
                                    isoString
                                  }
                                  contractlevels {
                                    id
                                    label
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.constraints.elements.map((element) => ({
                    ...element,
                    starts: element.starts.isoString ? moment(element.starts.isoString).toDate() : null,
                    ends: element.ends.isoString ? moment(element.ends.isoString).toDate() : null,
                  })),
                  totalCount: result.data.constraints.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },

            this.props.getJournalAction({ collection: 'constraints' }),
          ]}
          title={i18n.get('constraints.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createConstraint($id: ID!, $input: ConstraintInput!) {
                            createConstraint(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('constraints.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('constraints.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateConstraint($id: ID!, $input: ConstraintInput!) {
                        updateConstraint(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('constraints.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('constraints.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteConstraint($id: ID!) {
                        deleteConstraint(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('constraints.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('constraints.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Constraints.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Constraints)));
