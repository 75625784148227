import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { Countries } from '../common/enums';
import LocationIcon from '@material-ui/icons/LocationOn';
import PaginationDialog from '../components/PaginationDialog';
import GoogleMap from '../components/Map';

const styles = (theme) => ({});

const defaults = {
  id: true,
  nameEN: false,
  nameDE: false,
  // geoLat: false,
  // geoLong: false,
  // geoHash: false,
  country: false,
  available: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.cities.defaults';

class Cities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.name.en) ||
        validator.isEmpty(data.name.de) ||
        // validator.isNumeric(toString(data.geoPosition.lat)) ||
        // validator.isNumeric(toString(data.geoPosition.long)) ||
        // validator.isEmpty(data.geoPosition.hash) ||
        validator.isEmpty(data.country)
      ) {
        throw new Error(i18n.get('cities.error.validation'));
      }

      const input = {
        name: {
          en: data.name.en,
          de: data.name.de,
        },
        // geoPosition: {
        //   lat: parseFloat(data.geoPosition.lat),
        //   long: parseFloat(data.geoPosition.long),
        //   hash: data.geoPosition.hash,
        // },
      };

      helper.set(input, data, 'country');
      helper.set(input, data, 'available');

      return input;
    } catch (error) {
      throw new Error(i18n.get('cities.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'name.en':
        return 'name_en';
      case 'name.de':
        return 'name_de';
      case 'geoPosition.lat':
        return 'geoPosition_lat';
      case 'geoPosition.long':
        return 'geoPosition_long';
      case 'geoPosition.hash':
        return 'geoPosition_hash';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationCitiesData = async (query) => {
    try {
      const where = `{column:"city", comparator: IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            locations(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Location {
                                  id
                                  bsnr
                                  street
                                  zipCode  
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.locations.elements,
        totalCount: result.data.locations.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('cities.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('cities.name.en'),
              field: 'name.en',
              hidden: this.default('nameEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('cities.name.de'),
              field: 'name.de',
              hidden: this.default('nameDE'),
              defaultSort: 'asc',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            // {
            //   title: i18n.get('cities.geoPosition.lat'),
            //   field: 'geoPosition.lat',
            //   hidden: this.default('geoLat'),
            //   sorting: true,
            //   filtering: true,
            //   type: 'numeric',
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            // {
            //   title: i18n.get('cities.geoPosition.long'),
            //   field: 'geoPosition.long',
            //   hidden: this.default('geoLong'),
            //   sorting: true,
            //   filtering: true,
            //   type: 'numeric',
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            // {
            //   title: i18n.get('cities.geoPosition.hash'),
            //   field: 'geoPosition.hash',
            //   hidden: this.default('geoHash'),
            //   sorting: true,
            //   filtering: true,
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            {
              title: i18n.get('cities.country'),
              field: 'country',
              hidden: this.default('country'),
              sorting: true,
              filtering: true,
              lookup: Countries,
              initialEditValue: 'DE',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('cities.available'),
              field: 'available',
              hidden: this.default('available'),
              sorting: true,
              filtering: true,
              type: 'boolean',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          cities(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on City {
                                  id,
                                  name {
                                    en
                                    de
                                  }
                                  geoPosition {
                                    lat
                                    long
                                    hash
                                  }
                                  country
                                  available
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.cities.elements,
                  totalCount: result.data.cities.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: () => {
                return <LocationIcon />;
              },
              tooltip: i18n.get('cities.locations'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('locations.id'), field: 'id', hidden: true },
                    { title: i18n.get('locations.bsnr'), field: 'bsnr' },
                    { title: i18n.get('locations.street'), field: 'street' },
                    { title: i18n.get('locations.zipCode'), field: 'zipCode' },
                  ],
                  paginationTitle: i18n.get('cities.pagination.title'),
                  fetchPaginationData: this.fetchPaginationCitiesData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'cities' }),
          ]}
          title={i18n.get('cities.title')}
          detailPanel={(rowData) => {
            return <GoogleMap lat={rowData.geoPosition?.lat} long={rowData.geoPosition?.long} />;
          }}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createCity($id: ID!, $input: CityInput!) {
                            createCity(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      this.showMessage(i18n.get('cities.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('cities.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateCity($id: ID!, $input: CityInput!) {
                        updateCity(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  this.showMessage(i18n.get('cities.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('cities.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteCity($id: ID!) {
                        deleteCity(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('cities.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('cities.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Cities.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Cities)));
