import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ChipLink from '../components/ChipLink';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import * as validator from 'validator';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
// import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import ImagesViewer from '../components/ImagesViewer';

const styles = (theme) => ({});

const defaults = {
  id: true,
  thumbnail: false,
  fraudScore: false,
  practice: false,
  hcp: false,
  patient: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.photos.defaults';

class Photos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  isSelected = (property) => {
    if (property) {
      if (property.length !== undefined && property.length > 0) {
        return true;
      } else if (property.length !== undefined && property.length === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  getInput = (data) => {
    try {
      let imageValidation = true;
      if (data.thumbnail.uri) {
        imageValidation = !validator.isURL(data.thumbnail.uri);
      } else if (data.thumbnail instanceof File) {
        imageValidation = data.thumbnail.size / 1024 / 1024 > 10;
      }

      if (
        validator.isEmpty(data.id) ||
        validator.isNumeric(toString(data.fraudScore)) ||
        imageValidation ||
        (this.isSelected(data.practice) && this.isSelected(data.hcp) && this.isSelected(data.patient))
      ) {
        throw new Error(i18n.get('photos.error.validation'));
      }

      const input = {};

      helper.set(input, data, 'fraudScore', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('photos.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  getPatientsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                patients(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Patient {
                            id
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.patients.elements.map((element) => {
        return {
          name: `${element.firstname} ${element.lastname}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPatientsSuggestions = () => {
    return [];
  };

  getChangesFor = async (property, newData, oldData) => {
    const newImageData = newData?.[property];
    const oldImageData = oldData?.[property];

    if (newImageData instanceof File && !oldImageData?.uri) {
      // upload new photo
      const result = await fetch(`${process.env.REACT_APP_API_ITERN_ENDPOINT}/photos`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
        body: newImageData,
      }).then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          return await response.json();
        } else {
          throw Error(response.statusText);
        }
      });
      return result.data;
    } else if (newImageData === undefined && oldImageData.uri) {
      // delete photo
      console.log('object');
      await fetch(`${process.env.REACT_APP_API_ITERN_ENDPOINT}/photos/${oldData.storagePath.split('/')[1]}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return {};
        } else {
          throw Error(response.statusText);
        }
      });
      return {};
    } else if (newImageData instanceof File && oldImageData.uri) {
      // replace photo
      const result = await fetch(
        `${process.env.REACT_APP_API_ITERN_ENDPOINT}/photos/${oldData.storagePath.split('/')[1]}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
          body: newImageData,
        }
      ).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      });
      return result.data;
    } else {
      return {
        storagePath: oldData.storagePath,
      };
    }
  };

  handleImageChanges = async (newData, oldData) => {
    return await this.getChangesFor('thumbnail', newData, oldData);
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation unassignPhotoFromPractice($id: ID!, $photoId: ID!) {
                unassignPhotoFromPractice(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation assignPhotoToPractice($id: ID!, $photoId: ID!) {
                assignPhotoToPractice(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newHCPID =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHCPID = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHCPID !== oldHCPID) {
        if (oldHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHCPID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation unassignPhotoFromHCP($id: ID!, $photoId: ID!) {
                unassignPhotoFromHCP(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }

        if (newHCPID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHCPID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation assignPhotoToHCP($id: ID!, $photoId: ID!) {
                assignPhotoToHCP(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPatientID =
        newData.patient && newData.patient.length > 0
          ? newData.patient[0].value
          : newData.patient
          ? newData.patient.id
          : null;
      const oldPatientID = oldData && oldData.patient ? oldData.patient.id : null;

      if (newPatientID !== oldPatientID) {
        if (oldPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPatientID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation unassignPhotoFromPatient($id: ID!, $photoId: ID!) {
                unassignPhotoFromPatient(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }

        if (newPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPatientID,
              photoId: newData.id,
            },
            mutation: gql`
              mutation assignPhotoToPatient($id: ID!, $photoId: ID!) {
                assignPhotoToPatient(id: $id, photoId: $photoId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('photos.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('photos.thumbnail'),
              field: 'thumbnail',
              hidden: this.default('thumbnail'),
              sorting: false,
              filtering: false,
              render: (rowData) => {
                if (rowData) {
                  return rowData && rowData.thumbnail?.uri ? (
                    <ImagesViewer
                      photos={[
                        {
                          thumbnailUri: rowData.thumbnail.uri,
                          originalUri: rowData.original?.uri,
                        },
                      ]}
                    />
                  ) : (
                    ' - '
                  );
                }
              },
              editComponent: helper.defaultImageUpload,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('photos.fraudScore'),
              field: 'fraudScore',
              hidden: this.default('fraudScore'),
              sorting: true,
              filtering: true,
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('photos.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('photos.practice.label'),
                  i18n.get('optioned'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
            },
            {
              title: i18n.get('photos.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.hcp ? helper.hcpChip(rowData.hcp) : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('photos.hcp.label'),
                  i18n.get('optioned'),
                  this.getHCPsSuggestions,
                  this.getDefaultHCPsSuggestions
                );
              },
            },
            {
              title: i18n.get('photos.patient'),
              field: 'patient',
              hidden: this.default('patient'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.firstname} ${v.lastname}`,
                      value: v.id,
                    };
                  }),
                this.getPatientsSuggestions,
                this.getDefaultPatientsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.patient ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.customers'), data: { id: rowData.patient.id } }}
                      label={`${rowData.patient.firstname} ${rowData.patient.lastname}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.firstname} ${props.value.lastname}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('photos.patient.label'),
                  i18n.get('optioned'),
                  this.getPatientsSuggestions,
                  this.getDefaultPatientsSuggestions
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          photos(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Photo {
                                  id,
                                  storagePath
                                  thumbnail {
                                    uri
                                  }
                                  fraudScore
                                  web {
                                    uri
                                  }
                                  original {
                                    uri
                                  }
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                  patient {
                                    id
                                    firstname
                                    lastname
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.photos.elements,
                  totalCount: result.data.photos.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'photos' }),
          ]}
          title={i18n.get('photos.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      const imagesData = await this.handleImageChanges(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: {
                            ...input,
                            ...imagesData,
                          },
                          id: id,
                        },
                        mutation: gql`
                          mutation createPhoto($id: ID!, $input: PhotoInput!) {
                            createPhoto(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('photos.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('photos.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const imagesData = await this.handleImageChanges(newData, oldData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: {
                        ...input,
                        ...imagesData,
                      },
                    },
                    mutation: gql`
                      mutation updatePhoto($id: ID!, $input: PhotoInput!) {
                        updatePhoto(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('photos.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('photos.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  await this.handleImageChanges(null, oldData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deletePhoto($id: ID!) {
                        deletePhoto(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('photos.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('photos.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Photos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Photos)));
