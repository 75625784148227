import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import * as validator from 'validator';
import { BOOKING_STATUS, DELAY_REASONS } from '../common/enums';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  lot: false,
  starts: false,
  ends: false,
  created: false,
  status: false,
  isForAnother: false,
  anotherFirstname: false,
  anotherLastname: false,
  anotherBirthday: false,
  didNotCome: false,
  cancelable: false,
  delayReason: false,
  delayTimeMin: false,
  specialization: false,
  patient: false,
  review: false,
  point: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.bookings.defaults';

class Bookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.starts.toString()) ||
        validator.isEmpty(data.ends.toString()) ||
        validator.isEmpty(data.created.toString())
        // validator.isEmpty(data.anotherBirthday.toString()) ||
        // validator.isEmpty(data.status) ||
        // validator.isEmpty(data.anotherFirstname) ||
        // validator.isEmpty(data.anotherLastname)
      ) {
        throw new Error(i18n.get('bookings.error.validation'));
      }

      const input = {
        isForAnother: data.isForAnother || false,
        cancelable: data.cancelable || false,
        didNotCome: data.didNotCome || false,
        delay: data.delay || {},
      };

      const starts = moment(data.starts).unix();
      const ends = moment(data.ends).unix();
      const created = moment(data.created).unix();
      const anotherBirthday = moment(data.anotherBirthday).unix();
      data['starts'] = starts;
      data['ends'] = ends;
      data['created'] = created;
      data['anotherBirthday'] = anotherBirthday;

      helper.set(input, data, 'starts');
      helper.set(input, data, 'ends');
      helper.set(input, data, 'created');
      helper.set(input, data, 'anotherBirthday');
      helper.set(input, data, 'status');
      helper.set(input, data, 'anotherFirstname');
      helper.set(input, data, 'anotherLastname');

      return input;
    } catch (error) {
      throw new Error(i18n.get('bookings.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'review':
        return 'review_ref';
      case 'point':
        return 'point_ref';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getLotsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                lots(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Lot {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.lots.elements.map((element) => {
        return {
          name: `${moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultLotsSuggestions = () => {
    return [];
  };

  getPatientsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                patients(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Patient {
                            id
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.patients.elements.map((element) => {
        return {
          name: `${element.firstname} ${element.lastname}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPatientsSuggestions = () => {
    return [];
  };

  getReviewsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE_OR, string: "${value}"}, {column: "rating", comparator: IS_OR, int: ${parseInt(
        value
      )}}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                reviews(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Review {
                            id
                            rating
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.reviews.elements.map((element) => {
        return {
          name: (
            <Chip
              label={
                <Box display="flex">
                  <Rating value={element.rating} readOnly size="small" />
                </Box>
              }
              style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
            />
          ),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultReviewsSuggestions = () => {
    return [];
  };

  getPointsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: IS, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                points(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Point {
                            id
                            used
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.points.elements.map((element) => {
        return {
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPointsSuggestions = () => {
    return [];
  };

  getSpecializationsSuggestions = async (value) => {
    try {
      const where = `[{column: "label.de", comparator: LT, string: "${value}~"},{column: "label.de", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                specializations(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Specialization {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.specializations.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultSpecializationsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newLotID =
        newData.lot && newData.lot.length > 0 ? newData.lot[0].value : newData.lot ? newData.lot.id : null;
      const oldLotID = oldData && oldData.lot ? oldData.lot.id : null;

      if (newLotID !== oldLotID) {
        if (oldLotID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldLotID,
              bookingId: newData.id,
            },
            mutation: gql`
              mutation unassignBookingFromLot($id: ID!, $bookingId: ID!) {
                unassignBookingFromLot(id: $id, bookingId: $bookingId) {
                  id
                }
              }
            `,
          });
        }

        if (newLotID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newLotID,
              bookingId: newData.id,
            },
            mutation: gql`
              mutation assignBookingToLot($id: ID!, $bookingId: ID!) {
                assignBookingToLot(id: $id, bookingId: $bookingId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPatientD =
        newData.patient && newData.patient.length > 0
          ? newData.patient[0].value
          : newData.patient
          ? newData.patient.id
          : null;
      const oldPatientD = oldData && oldData.patient ? oldData.patient.id : null;

      if (newPatientD !== oldPatientD) {
        if (oldPatientD) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPatientD,
              bookingId: newData.id,
            },
            mutation: gql`
              mutation unassignBookingFromPatient($id: ID!, $bookingId: ID!) {
                unassignBookingFromPatient(id: $id, bookingId: $bookingId) {
                  id
                }
              }
            `,
          });
        }

        if (newPatientD) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPatientD,
              bookingId: newData.id,
            },
            mutation: gql`
              mutation assignBookingToPatient($id: ID!, $bookingId: ID!) {
                assignBookingToPatient(id: $id, bookingId: $bookingId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newReviewD =
        newData.review && newData.review.length > 0
          ? newData.review[0].value
          : newData.review
          ? newData.review.id
          : null;
      const oldReviewD = oldData && oldData.review ? oldData.review.id : null;

      if (newReviewD !== oldReviewD) {
        if (oldReviewD) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              reviewId: oldReviewD,
            },
            mutation: gql`
              mutation unassignReviewFromBooking($id: ID!, $reviewId: ID!) {
                unassignReviewFromBooking(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }

        if (newReviewD) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              reviewId: newReviewD,
            },
            mutation: gql`
              mutation assignReviewToBooking($id: ID!, $reviewId: ID!) {
                assignReviewToBooking(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newSpecializationID =
        newData.specialization && newData.specialization.length > 0
          ? newData.specialization[0].value
          : newData.specialization
          ? newData.specialization.id
          : null;
      const oldSpecializationID = oldData && oldData.specialization ? oldData.specialization.id : null;

      if (newSpecializationID !== oldSpecializationID) {
        if (oldSpecializationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              specializationId: oldSpecializationID,
            },
            mutation: gql`
              mutation unassignSpecializationFromBooking($id: ID!, $specializationId: ID!) {
                unassignSpecializationFromBooking(id: $id, specializationId: $specializationId) {
                  id
                }
              }
            `,
          });
        }

        if (newSpecializationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              specializationId: newSpecializationID,
            },
            mutation: gql`
              mutation assignSpecializationToBooking($id: ID!, $specializationId: ID!) {
                assignSpecializationToBooking(id: $id, specializationId: $specializationId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('bookings.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bookings.lot'),
              field: 'lot',
              hidden: this.default('lot'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                      value: v.id,
                    };
                  }),
                this.getLotsSuggestions,
                this.getDefaultLotsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.lot ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.lots'), data: { id: rowData.lot.id } }}
                      key={rowData.lot.id}
                      label={`${moment(rowData.lot.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${moment(props.value.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('bookings.lot.label'),
                  i18n.get('required'),
                  this.getLotsSuggestions,
                  this.getDefaultLotsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.starts'),
              field: 'starts',
              hidden: this.default('starts'),
              type: 'datetime',
              comparator: 'GTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.ends'),
              field: 'ends',
              hidden: this.default('ends'),
              type: 'datetime',
              comparator: 'LTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.created'),
              field: 'created',
              hidden: this.default('created'),
              type: 'date',
              comparator: 'LTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.status'),
              field: 'status',
              hidden: this.default('status'),
              lookup: BOOKING_STATUS,
              initialEditValue: 'OK',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.didNotCome'),
              field: 'didNotCome',
              hidden: this.default('didNotCome'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.cancelable'),
              field: 'cancelable',
              hidden: this.default('cancelable'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.isForAnother'),
              field: 'isForAnother',
              hidden: this.default('isForAnother'),
              type: 'boolean',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.anotherFirstname'),
              field: 'anotherFirstname',
              hidden: this.default('anotherFirstname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.anotherLastname'),
              field: 'anotherLastname',
              hidden: this.default('anotherLastname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.anotherBirthday'),
              field: 'anotherBirthday',
              hidden: this.default('anotherBirthday'),
              type: 'date',
              comparator: 'LTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.delayReason'),
              field: 'delay.reason',
              hidden: this.default('delayReason'),
              lookup: DELAY_REASONS,
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.delayTimeMin'),
              field: 'delay.timeMin',
              hidden: this.default('delayTimeMin'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 30,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.specialization'),
              field: 'specialization',
              hidden: this.default('specialization'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label.de}`,
                      value: v.id,
                    };
                  }),
                this.getSpecializationsSuggestions,
                this.getDefaultSpecializationsSuggestions
              ),
              render: (rowData) => {
                if (rowData.specialization) {
                  return (
                    <ChipLink
                      link={{ page: i18n.get('menus.specializations'), data: { id: rowData.specialization.id } }}
                      key={rowData.specialization.id}
                      label={`${rowData.specialization.label.de}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  );
                } else {
                  return '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.label?.de}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('bookings.specialization.label'),
                  null,
                  this.getSpecializationsSuggestions,
                  this.getDefaultSpecializationsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.patient'),
              field: 'patient',
              hidden: this.default('patient'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.firstname} ${v.lastname}`,
                      value: v.id,
                    };
                  }),
                this.getPatientsSuggestions,
                this.getDefaultPatientsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.patient ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.customers'), data: { id: rowData.patient.id } }}
                      key={rowData.patient.id}
                      label={`${rowData.patient.firstname} ${rowData.patient.lastname}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.firstname} ${props.value.lastname}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('bookings.patient.label'),
                  i18n.get('required'),
                  this.getPatientsSuggestions,
                  this.getDefaultPatientsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.review'),
              field: 'review',
              hidden: this.default('review'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: (
                        <Box display="flex">
                          <Rating value={v.rating} readOnly size="small" />
                        </Box>
                      ),
                      value: v.id,
                    };
                  }),
                this.getReviewsSuggestions,
                this.getDefaultReviewsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.review ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.reviews'), data: { id: rowData.review.id } }}
                      key={rowData.review.id}
                      label={
                        <Box display="flex">
                          <Rating value={rowData.review.rating} readOnly size="small" />
                        </Box>
                      }
                      style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: (
                            <Chip
                              key={props.value.id}
                              label={
                                <Box display="flex">
                                  <Rating value={props.value.rating} readOnly size="small" />
                                </Box>
                              }
                              style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
                            />
                          ),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('bookings.review.label'),
                  i18n.get('required'),
                  this.getReviewsSuggestions,
                  this.getDefaultReviewsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bookings.point'),
              field: 'point',
              hidden: this.default('point'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.used ? '✔' : '❌'}`,
                      value: v.id,
                    };
                  }),
                this.getPointsSuggestions,
                this.getDefaultPointsSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData.point ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.points'), data: { id: rowData.point.id } }}
                      label={`${rowData.point.used ? '✔' : '❌'}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'starts', ['starts']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          bookings(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Booking {
                                  id,
                                  status
                                  cancelable
                                  didNotCome
                                  isForAnother
                                  anotherFirstname
                                  anotherLastname
                                  delay {
                                    reason
                                    timeMin
                                  }
                                  anotherBirthday {
                                    X
                                  }
                                  specialization {
                                    id
                                    label {
                                      de
                                    }
                                  }
                                  lot {
                                    id
                                    starts {
                                      X
                                    }
                                  }
                                  starts {
                                    X
                                  }
                                  ends {
                                    X
                                  }
                                  created {
                                    X
                                  }
                                  patient {
                                    id
                                    firstname
                                    lastname
                                  }
                                  review {
                                    id
                                    rating
                                  }
                                  point {
                                    id
                                    used
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.bookings.elements.map((element) => ({
                    ...element,
                    starts: element.starts?.X ? moment(element.starts.X, 'X').toDate() : null,
                    ends: element.ends?.X ? moment(element.ends.X, 'X').toDate() : null,
                    created: element.created?.X ? moment(element.created.X, 'X').toDate() : null,
                    anotherBirthday:
                      element.anotherBirthday && element.anotherBirthday.X
                        ? moment(element.anotherBirthday.X, 'X').toDate()
                        : null,
                  })),
                  totalCount: result.data.bookings.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'bookings' }),
          ]}
          title={i18n.get('bookings.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createBooking($id: ID!, $input: BookingInput!) {
                            createBooking(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('bookings.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('bookings.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateBooking($id: ID!, $input: BookingInput!) {
                        updateBooking(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  // assign it
                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('bookings.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('bookings.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteBooking($id: ID!) {
                        deleteBooking(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('bookings.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('bookings.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Bookings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Bookings)));
