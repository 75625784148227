import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChangeNameDialog from '../components/ChangeNameDialog';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import DrawerMenu from './DrawerMenu';
import { menus } from './Menus';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withIAM } from '../common/iamV2';
import * as i18n from '../common/i18n';
import WelcomeDialog from '../components/WelcomeDialog';
import ChangeProfilePictureDialog from '../components/ChangeProfilePictureDialog';

const drawerWidth = 250;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    color: 'white',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    color: 'black',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100vh',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      outline: '1px solid slategrey',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  grid: {
    width: '100vw',
    height: '100vh',
  },
});

const AdminContext = React.createContext();

const ROUTES = menus.reduce((acc, menu) => {
  return [...acc, ...menu.entries];
}, []);

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangePasswordOpened: false,
      isChangeProfilePictureOpened: false,
      open: true,
      // page: menus[0].entries[0],
      user: null,
      anchorEl: null,
    };
  }

  changeRoute = (page, data) => {
    this.props.history.push(`/${page.primary}`, data);
  };

  handleLink = (menuIndex, entriesIndex) => {
    this.changeRoute(menus[menuIndex].entries[entriesIndex]);
    // this.setState({ page: menus[menuIndex].entries[entriesIndex] });
  };

  handleLinkWithData = (page, data) => {
    const selectedPage = menus
      .reduce((a, m) => {
        return [...a, ...m.entries];
      }, [])
      .find((m) => m.primary === page);
    this.changeRoute(selectedPage, data);
    // this.setState({
    //   page: {
    //     ...selectedPage,
    //     component: React.cloneElement(selectedPage.component, { pageData: data }),
    //   },
    // });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleChangePasswordDialogOpen = () => {
    this.setState({ isChangePasswordOpened: true, anchorEl: null });
  };

  handleChangePasswordDialogClose = () => {
    this.setState({ isChangePasswordOpened: false });
  };

  handleChangeNameDialogOpen = () => {
    this.setState({ isChangeNameOpened: true, anchorEl: null });
  };

  handleChangeNameDialogClose = () => {
    this.setState({ isChangeNameOpened: false });
  };

  handleChangeProfilePictureDialogOpen = () => {
    this.setState({ isChangeProfilePictureOpened: true, anchorEl: null });
  };

  handleChangeProfilePictureDialogClose = () => {
    this.setState({ isChangeProfilePictureOpened: false });
  };

  handleMenuChange = (page) => {
    this.changeRoute(page);
    // this.setState({ page: page });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = async () => {
    this.props.onLogout();

    this.handleClose();
  };

  getTitle = () => {
    const page = ROUTES.find((e) => `/${e.primary}` === this.props.history.location.pathname);
    return page ? page.primary : '404';
  };

  render() {
    const { classes } = this.props;

    const open = ['xs'].includes(this.props.width) ? false : this.state.open;
    return (
      <div className={classes.root}>
        <AdminContext.Provider
          value={{
            drawerOpen: this.state.open,
            onMenuChange: this.handleMenuChange,
            // selected: this.state.page,
            onLink: this.handleLink,
            onLinkWithData: this.handleLinkWithData,
          }}
        >
          <CssBaseline />
          <AppBar position="fixed" className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              {this.props.width !== 'xs' && (
                <IconButton
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                {this.getTitle()}
                {/* {this.state.page.primary} */}
              </Typography>
              <Button id="profilebutton" color="inherit" onClick={this.handleMenu}>
                {isWidthUp('md', this.props.width) && this.props.user.displayName?.length > 0
                  ? this.props.user.displayName
                  : this.props.user.email}
                <Avatar className={classes.rightIcon} alt={this.props.user.email} src={this.props.user.photoURL}>
                  <AccountCircle />
                </Avatar>
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleChangeProfilePictureDialogOpen}>
                  {i18n.get('admin.changeProfilePicture')}
                </MenuItem>
                <MenuItem onClick={this.handleChangeNameDialogOpen}>{i18n.get('admin.changeName')}</MenuItem>
                <MenuItem onClick={this.handleChangePasswordDialogOpen}>{i18n.get('admin.changePassword')}</MenuItem>
                <MenuItem onClick={this.handleLogout}>{i18n.get('admin.logout')}</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <List>
              <DrawerMenu />
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Switch>
              {ROUTES.map((route) => {
                return (
                  <Route
                    key={route.primary}
                    exact
                    path={`/${route.primary}`}
                    render={(props) => {
                      if (props.location.state) {
                        return React.cloneElement(route.component, { pageData: props.location.state });
                      }
                      return route.component;
                    }}
                  />
                );
              })}
              <Redirect exact from="/" to={`/${ROUTES[0].primary}`} />
            </Switch>
            {/* {this.state.page.component} */}
          </main>
          <ChangeNameDialog
            placeholder={this.props.user.displayName}
            isChangeNameOpened={this.state.isChangeNameOpened}
            closeChangeNameDialog={this.handleChangeNameDialogClose}
          />
          <ChangePasswordDialog
            isChangePasswordOpened={this.state.isChangePasswordOpened}
            closeChangePasswordDialog={this.handleChangePasswordDialogClose}
          />
          <ChangeProfilePictureDialog
            isOpen={this.state.isChangeProfilePictureOpened}
            onClose={this.handleChangeProfilePictureDialogClose}
          />
          <WelcomeDialog name={this.props.user.displayName || this.props.user.email} />
        </AdminContext.Provider>
      </div>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const AdminContextConsumer = AdminContext.Consumer;

export default withIAM(withWidth()(withStyles(styles, { withTheme: true })(withRouter(Admin))));
