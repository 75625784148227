import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PaginationDialog from '../components/PaginationDialog';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import * as validator from 'validator';
import { STATUSES, LEGAL_ENTITY } from '../common/enums';

import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import ImagesViewer from '../components/ImagesViewer';
import { STATUSES_COLORS } from '../common/colors';

const styles = (theme) => ({});

const defaults = {
  id: true,
  number: false,
  status: false,
  legalEntity: false,
  name: false,
  web: false,
  email: false,
  phone: false,
  fraudScore: false,
  locations: false,
  bankAccounts: false,
  hcps: false,
  equipments: false,
  photos: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.onboardingPractices.defaults';

class Practices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationLotsData = async (query) => {
    try {
      const where = `{column:"practice", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            lots(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Lot {
                                  id,
                                  starts {
                                    isoString
                                  }
                                  ends {
                                    isoString
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.lots.elements.map((element) => ({
          ...element,
          starts: element.starts.isoString ? moment(element.starts.isoString).toDate() : null,
          ends: element.ends.isoString ? moment(element.ends.isoString).toDate() : null,
        })),
        totalCount: result.data.lots.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationFeesData = async (query) => {
    try {
      const where = `{column:"practice", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            fees(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Fee {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.fees.elements,
        totalCount: result.data.fees.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationInvoicesData = async (query) => {
    try {
      const where = `{column:"practice", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            invoices(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Invoice {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.invoices.elements,
        totalCount: result.data.invoices.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationTransfersData = async (query) => {
    try {
      const where = `{column:"practice", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            transfers(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Transfer {
                                  id
                                  date {
                                    isoString
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.transfers.elements.map((element) => ({
          ...element,
          date: element.date.isoString ? moment(element.date.isoString).toDate() : null,
        })),
        totalCount: result.data.transfers.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.status) ||
        validator.isEmpty(data.legalEntity) ||
        validator.isEmpty(data.name) ||
        validator.isEmpty(data.web) ||
        validator.isNumeric(toString(data.fraudScore)) ||
        !validator.isEmail(data.email) ||
        validator.isEmpty(data.phone)
      ) {
        throw new Error(i18n.get('practices.error.validation'));
      }

      const input = {};

      helper.set(input, data, 'name');
      helper.set(input, data, 'status');
      helper.set(input, data, 'legalEntity');
      helper.set(input, data, 'web');
      helper.set(input, data, 'email');
      helper.set(input, data, 'phone');
      helper.set(input, data, 'fraudScore', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('practices.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'equipments':
        return 'equipments_search_string';
      case 'hcps':
        return 'hcps_search_string';
      case 'bankAccounts':
        return 'bankAccounts_search_string';
      case 'locations':
        return 'locations_search_string';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getEquipmentsSuggestions = async (value) => {
    try {
      const where = `[{column: "label.de", comparator: LT, string: "${value}~"},{column: "label.de", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                equipments(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Equipment {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.equipments.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultEquipmentsSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  getBankAccountsSuggestions = async (value) => {
    try {
      const where = `[{column: "iban", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bankAccounts(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on BankAccount {
                            id
                            iban
                            bic
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bankAccounts.elements.map((element) => {
        return {
          name: `${element.iban}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBankAccountsSuggestions = () => {
    return [];
  };

  getLocationsSuggestions = async (value) => {
    try {
      const where = `[{column: "street", comparator: LIKE_OR, string: "${value}"}, {column: "zipCode", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                locations(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Location {
                            id
                            bsnr
                            street
                            zipCode
                            city {
                              name {
                                de
                              }
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.locations.elements.map((element) => {
        return {
          name: `${element.street}, ${element.zipCode} ${element.city?.name.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultLocationsSuggestions = () => {
    return [];
  };

  changePracticeStatus = async (id, status) => {
    try {
      const input = {
        status: status,
      };

      await graphql.clientWithToken(this.props.accessToken).mutate({
        variables: {
          id: id,
          input: input,
        },
        mutation: gql`
          mutation updatePractice($id: ID!, $input: PracticeInput!) {
            updatePractice(id: $id, input: $input) {
              id
            }
          }
        `,
      });

      this.showMessage(i18n.get('practices.message.onRowUpdate.success'), 'success');
    } catch (error) {
      this.showMessage(i18n.get('practices.message.onRowUpdate.error'), 'error', error.message);
    }
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newEquipmentsIds = newData.equipments ? newData.equipments.map((p) => (p.value ? p.value : p.id)) : [];
      const oldEquipmentsIds = oldData && oldData.equipments ? oldData.equipments.map((p) => p.id) : [];

      const addedEquipmentsIds = helper.diffArray(newEquipmentsIds, oldEquipmentsIds);

      for (const addedEquipmentId of addedEquipmentsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            equipmentId: addedEquipmentId,
          },
          mutation: gql`
            mutation assignEquipmentToPractice($id: ID!, $equipmentId: ID!) {
              assignEquipmentToPractice(id: $id, equipmentId: $equipmentId) {
                id
              }
            }
          `,
        });
      }

      // these I need to remove
      const removedEquipmentsIds = helper.diffArray(oldEquipmentsIds, newEquipmentsIds);

      for (const removedEquipmnetId of removedEquipmentsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            equipmentId: removedEquipmnetId,
          },
          mutation: gql`
            mutation unassignEquipmentFromPractice($id: ID!, $equipmentId: ID!) {
              unassignEquipmentFromPractice(id: $id, equipmentId: $equipmentId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('practices.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('practices.number'),
              field: 'number',
              hidden: this.default('number'),
              editable: 'never',
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.status'),
              field: 'status',
              hidden: this.default('status'),
              lookup: STATUSES,
              sorting: true,
              filtering: false,
              defaultFilter: ['NEW', 'SUBMITTED', 'UNDERREVIEW'],
              render: (rowData) => {
                if (rowData) {
                  return rowData.status ? (
                    <Chip
                      label={`${STATUSES[rowData.status]}`}
                      style={{
                        backgroundColor: STATUSES_COLORS[rowData.status],
                        color: 'white',
                      }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.legalEntity'),
              field: 'legalEntity',
              hidden: this.default('legalEntity'),
              lookup: LEGAL_ENTITY,
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.name'),
              field: 'name',
              hidden: this.default('name'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('practices.web'),
              field: 'web',
              hidden: this.default('web'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return rowData.web ? (
                  <Link target="_blank" href={rowData.web}>
                    {rowData.web}
                  </Link>
                ) : (
                  '-'
                );
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('practices.email'),
              field: 'email',
              hidden: this.default('email'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return rowData.email ? <Link href={`mailto:${rowData.email}`}>{rowData.email}</Link> : '-';
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('practices.phone'),
              field: 'phone',
              hidden: this.default('phone'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return rowData.phone ? <Link href={`tel:${rowData.phone}`}>{rowData.phone}</Link> : '-';
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('practices.fraudScore'),
              field: 'fraudScore',
              hidden: this.default('fraudScore'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.locations'),
              field: 'locations',
              hidden: this.default('locations'),
              sorting: false,
              filtering: true,
              editable: 'never',
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.street}, ${v.zipCode} ${v.city?.name.de} (${v.bsnr})`,
                      value: v.id,
                    };
                  }),
                this.getLocationsSuggestions,
                this.getDefaultLocationsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.locations
                    ? rowData.locations.map((location) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.locations'), data: { id: location.id } }}
                          key={location.id}
                          label={`${location.street}, ${location.zipCode} ${location?.city.name.de} (${location.bsnr})`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.bankAccounts'),
              field: 'bankAccounts',
              hidden: this.default('bankAccounts'),
              sorting: false,
              filtering: true,
              editable: 'never',
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.iban} (${v.bic})`,
                      value: v.id,
                    };
                  }),
                this.getBankAccountsSuggestions,
                this.getDefaultBankAccountsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.bankAccounts
                    ? rowData.bankAccounts.map((bankAccount) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.bankAccounts'), data: { id: bankAccount.id } }}
                          key={bankAccount.id}
                          label={`${bankAccount.iban} (${bankAccount.bic})`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.hcps'),
              field: 'hcps',
              hidden: this.default('hcps'),
              sorting: false,
              filtering: true,
              editable: 'never',
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.hcps ? rowData.hcps.map((hcp) => helper.hcpChip(hcp)) : '-';
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.equipments'),
              field: 'equipments',
              hidden: this.default('equipments'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return { name: `${v.label?.de}`, value: v.id };
                  }),
                this.getEquipmentsSuggestions,
                this.getDefaultEquipmentsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.equipments
                    ? rowData.equipments.map((equipment) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.equipments'), data: { id: equipment.id } }}
                          key={equipment.id}
                          label={`${equipment.label.de}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label?.de}`, value: v.id };
                      })
                    : [],
                  i18n.get('practices.equipments.label'),
                  null,
                  this.getEquipmentsSuggestions,
                  this.getDefaultEquipmentsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.photos'),
              field: 'photos',
              hidden: this.default('photos'),
              sorting: false,
              filtering: false,
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData.photos ? (
                    <ImagesViewer
                      photos={rowData.photos.map((photo) => ({
                        thumbnailUri: photo.thumbnail.uri,
                        originalUri: photo.original.uri,
                      }))}
                    />
                  ) : (
                    '-'
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'number', ['number']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                    query {
                      practices(first: ${query.pageSize}, offset: ${
                    query.pageSize * query.page
                  }, orderBy:${orderBy}, where:${where}) {
                        totalCount
                        elements {
                          ... on Practice {
                            id
                            number
                            status
                            legalEntity
                            name
                            web
                            email
                            phone
                            fraudScore
                            locations {
                              id
                              bsnr
                              zipCode
                              street
                              city {
                                name { de }
                              }
                            }
                            bankAccounts {
                              id
                              iban
                              bic
                            }
                            hcps { id number status title lastname firstname }
                            equipments {
                              id 
                              label {
                                de
                              }
                            }
                            photos {
                              id
                              thumbnail {
                                uri
                              }
                              original {
                                uri
                              }
                            }
                          }
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.practices.elements,
                  totalCount: result.data.practices.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: CheckIcon,
              tooltip: i18n.get('practices.actions.toEnroll'),
              onClick: async (event, rowData) => {
                await this.changePracticeStatus(rowData.id, 'ENROLLED');
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: ClearIcon,
              tooltip: i18n.get('practices.actions.toReject'),
              onClick: async (event, rowData) => {
                await this.changePracticeStatus(rowData.id, 'REJECTED');
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: VisibilityIcon,
              tooltip: i18n.get('practices.actions.toReview'),
              onClick: async (event, rowData) => {
                await this.changePracticeStatus(rowData.id, 'UNDERREVIEW');
                this.tableRef.current.onQueryChange();
              },
            },
          ]}
          title={i18n.get('practices.onboardingTitle')}
          options={{
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updatePractice($id: ID!, $input: PracticeInput!) {
                        updatePractice(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('practices.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('practices.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Practices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Practices)));
