import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PaginationDialog from '../components/PaginationDialog';
import { STATUSES, HCP_TITLES, HCP_TYPES } from '../common/enums';
import { STATUSES_COLORS } from '../common/colors';

const styles = (theme) => ({});

const defaults = {
  id: true,
  labelEN: false,
  labelDE: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.qualifications.defaults';

class Qualifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (validator.isEmpty(data.label.de) || validator.isEmpty(data.label.en)) {
        throw new Error(i18n.get('qualifications.error.validation'));
      }

      const input = {
        en: data.label.en,
        de: data.label.de,
      };

      return input;
    } catch (error) {
      throw new Error(i18n.get('qualifications.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'label.de':
        return 'label_de';
      case 'label.en':
        return 'label_en';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationHCPData = async (query) => {
    try {
      const where = `{column:"qualifications_search_string", comparator:LIKE, string: ",${this.state.paginationObject.id},"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            hcps(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on HCP {
                                  id
                                  number
                                  status
                                  title
                                  type
                                  firstname
                                  lastname  
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.hcps.elements,
        totalCount: result.data.hcps.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  getSpecializationsSuggestions = async (value) => {
    try {
      const where = `[{column: "label_de", comparator: LIKE_OR, string: "${value}"}, {column: "label_en", comparator: LIKE_OR, string: "${value}"}]`;
      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                specializations(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Specialization {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.specializations.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultSpecializationsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newSpecializationID =
        newData.specialization && newData.specialization.length > 0
          ? newData.specialization[0].value
          : newData.specialization
          ? newData.specialization.id
          : null;
      const oldSpecializationID = oldData && oldData.specialization ? oldData.specialization.id : null;

      if (newSpecializationID !== oldSpecializationID) {
        if (oldSpecializationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldSpecializationID,
              qualificationId: newData.id,
            },
            mutation: gql`
              mutation unassignQualificationFromSpecialization($id: ID!, $qualificationId: ID!) {
                unassignQualificationFromSpecialization(id: $id, qualificationId: $qualificationId) {
                  id
                }
              }
            `,
          });
        }

        if (newSpecializationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newSpecializationID,
              qualificationId: newData.id,
            },
            mutation: gql`
              mutation assignQualificationToSpecialization($id: ID!, $qualificationId: ID!) {
                assignQualificationToSpecialization(id: $id, qualificationId: $qualificationId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('qualifications.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('qualifications.label.en'),
              field: 'label.en',
              hidden: this.default('labelEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('qualifications.label.de'),
              field: 'label.de',
              hidden: this.default('labelEN'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('qualifications.specialization'),
              field: 'specialization',
              hidden: this.default('specialization'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                (items, props) => {
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  );
                },
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label.de}`,
                      value: v.id,
                    };
                  }),
                this.getSpecializationsSuggestions,
                this.getDefaultSpecializationsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.specialization ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.specializations'), data: { id: rowData.specialization.id } }}
                      label={`${rowData.specialization.label.de}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id ? [{ name: `${props.value.label.de}`, value: props.value.id }] : [],
                  i18n.get('qualifications.specialization.label'),
                  i18n.get('required'),
                  this.getSpecializationsSuggestions,
                  this.getDefaultSpecializationsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          qualifications(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Qualification {
                                  id,
                                  label {
                                    en
                                    de
                                  }
                                  specialization {
                                    id
                                    label {
                                      de
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.qualifications.elements,
                  totalCount: result.data.qualifications.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: PersonAddIcon,
              tooltip: i18n.get('menus.hcps'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('hcps.id'), field: 'id', hidden: true },
                    { title: i18n.get('hcps.number'), field: 'number' },
                    {
                      title: i18n.get('hcps.status'),
                      field: 'status',
                      lookup: STATUSES,
                      render: (rowData) => {
                        return rowData.status ? (
                          <Chip
                            label={`${STATUSES[rowData.status]}`}
                            style={{
                              backgroundColor: STATUSES_COLORS[rowData.status],
                            }}
                          />
                        ) : (
                          '-'
                        );
                      },
                    },
                    { title: i18n.get('hcps.title'), field: 'title', lookup: HCP_TITLES },
                    { title: i18n.get('hcps.type'), field: 'type', lookup: HCP_TYPES },
                    { title: i18n.get('hcps.firstname'), field: 'firstname' },
                    { title: i18n.get('hcps.lastname'), field: 'lastname', sorting: false },
                  ],
                  paginationTitle: i18n.get('qualifications.pagination.title'),
                  fetchPaginationData: this.fetchPaginationHCPData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'qualifications' }),
          ]}
          title={i18n.get('qualifications.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createQualification($id: ID!, $input: LocalizedStringInput!) {
                            createQualification(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('qualifications.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('qualifications.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateQualification($id: ID!, $input: LocalizedStringInput!) {
                        updateQualification(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('qualifications.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('qualifications.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteQualification($id: ID!) {
                        deleteQualification(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('qualifications.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('qualifications.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Qualifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Qualifications)));
