import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

import { auth } from '../common/firebase';

import '../firebaseui.css';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });

    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      tosUrl: '/terms',
      signInFlow: 'popup',
      privacyPolicyUrl: '/privacy',
    };

    const ui = new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);
  }

  render() {
    return (
      <React.Fragment>
        <div id="firebaseui-auth-container"></div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles({ withTheme: true })(Login);
