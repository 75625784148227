import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import gql from 'graphql-tag';
import * as graphql from '../common/graphql';
import * as i18n from '../common/i18n';
import Message from '../components/Message';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import PieChart from 'recharts/lib/chart/PieChart';
import Pie from 'recharts/lib/polar/Pie';
import Cell from 'recharts/lib/component/Cell';
import Legend from 'recharts/lib/component/Legend';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core';
import { withIAM } from '../common/iamV2';
import * as Colors from '../common/colors';
import GaugeChart from 'react-gauge-chart';
import ReportSkeleton from '../components/ReportSkeleton';

import { AdminContextConsumer } from '../main/Admin';

import { DateRangePicker } from 'react-dates';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  head: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  header: {
    margin: theme.spacing(5),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: '#AAAAAA33',
  },
  card: {
    height: '280px',
  },
});

function SimpleLineChart(props) {
  const { data } = props;
  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width="99%" height={320}>
      <LineChart data={data}>
        <XAxis dataKey="date" />
        <YAxis
          yAxisId="left"
          label={{ value: i18n.get('dashboard.chart.yaxis.label'), angle: -90, position: 'insideLeft' }}
        />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          name={i18n.get('dashboard.chart.countOfLots.name')}
          dataKey="countOfLots"
          stroke={Colors.primary}
        />
        <Line
          yAxisId="left"
          type="monotone"
          name={i18n.get('dashboard.chart.countOfBookings.name')}
          dataKey="countOfBookings"
          stroke={Colors.red}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const COLORS = [Colors.primary, Colors.secondary];
const RADIAN = Math.PI / 180;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      loading: true,
      fetching: false,
      period: 7,
      data: [
        {
          dateValue: 0,
          countOfLots: 0,
          countOfBookings: 0,
          bookingsInMinutes: 0,
        },
      ],
      bookingsData: {
        selected: '',
        total: '',
      },
      hcpsData: {
        approvedHcps: '',
        newApprovedHcps: '',
      },
    };
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  fetchKPIs = async (noOfDays) => {
    await this.setState({ fetching: true });
    const kpiWheres = `[
      ${
        this.state.starts
          ? `{column: "dateValue", comparator: GTE, datetime: ${this.state.starts.startOf('day').unix()}},`
          : ''
      }
      ${this.state.ends ? `{column: "dateValue", comparator: LTE, datetime: ${this.state.ends.unix()}},` : ''}
      {column: "label", comparator: IN, values: ["countOfLots","countOfBookings"]},
    ]`;

    try {
      let kpis = [];
      let current = 0;
      let totalCount = 0;
      do {
        const result = await graphql.clientWithToken(this.props.accessToken).query({
          query: gql`
                        query {
                            kpis(first: 100, offset: ${current}, where: ${kpiWheres} ) {
                                totalCount
                                elements {
                                    ... on KPI {
                                        id
                                        label
                                        dateValue {
                                          X
                                        }
                                        intValue
                                        floatValue
                                    }
                                }
                            }
                        }
                    `,
          fetchPolicy: 'no-cache',
        });

        totalCount = result.data.kpis.totalCount;
        current = current + result.data.kpis.elements.length;
        kpis = kpis.concat(result.data.kpis.elements);
      } while (current < totalCount);

      const groupedKpis = Object.entries(
        kpis.reduce((r, a) => {
          if (r && r.hasOwnProperty && r.hasOwnProperty(a.dateValue.X)) {
            r[a.dateValue.X].push(a);
          } else {
            const sameDay = Object.keys(r).find((key) => moment.unix(key).isSame(moment.unix(a.dateValue.X), 'day'));
            if (sameDay) {
              r[a.dateValue.X] = r[sameDay];
              delete r[sameDay];
              r[a.dateValue.X].push(a);
            } else {
              r[a.dateValue.X] = [];
              r[a.dateValue.X].push(a);
            }
          }
          return r;
        }, {})
      ).map((kpi) => {
        return {
          date: moment(kpi[0], 'X').format('DD.MM.YYYY'),
          countOfLots: kpi[1].find((k) => k.label === 'countOfLots')?.intValue,
          countOfBookings: kpi[1].find((k) => k.label === 'countOfBookings')?.intValue,
        };
      });

      const bookingWheres = `[
        ${
          this.state.starts
            ? `{column: "starts", comparator: GTE, datetime: ${this.state.starts.startOf('day').unix()}},`
            : ''
        }
        ${this.state.ends ? `{column: "ends", comparator: LTE, datetime: ${this.state.ends.unix()}},` : ''}
      ]`;

      const approvedHcpsWheres = `[
        ${
          this.state.starts
            ? `{column: "created", comparator: GTE, datetime: ${this.state.starts.startOf('day').unix()}},`
            : ''
        }
        ${this.state.ends ? `{column: "created", comparator: LTE, datetime: ${this.state.ends.unix()}},` : ''}
        {column:"collection", comparator:IS, string:"hcps"},
        {column:"action", comparator:IS, string:"UPDATED"},
        {column:"newData", comparator:LIKE, string:"\\"status\\":\\"ENROLLED\\""},
        {column:"oldData", comparator:LIKE_NOT, string:"\\"status\\":\\"ENROLLED\\""},
        {column:"oldData", comparator:LIKE, string:"\\"type\\":\\"PHYSICIAN\\""},
        {column:"oldData", comparator:LIKE, string:"\\"type\\":\\"EMPLOYEDPHYSICIAN\\""},
      ]`;

      const newApprovedHcps = `[
        ${
          this.state.starts
            ? `{column: "created", comparator: GTE, datetime: ${this.state.starts.startOf('day').unix()}},`
            : ''
        }
        ${this.state.ends ? `{column: "created", comparator: LTE, datetime: ${this.state.ends.unix()}},` : ''}
        {column:"collection", comparator:IS,string:"hcps"},
        {column:"action", comparator:IS, string:"UPDATED"},
        {column:"oldData", comparator:LIKE, string:"\\"status\\":\\"NEW\\""},
        {column:"newData", comparator:LIKE, string:"\\"status\\":\\"ENROLLED\\""},
        {column:"oldData", comparator:LIKE, string:"\\"type\\":\\"PHYSICIAN\\""},
      ]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            allBookings: bookings {
                              totalCount
                            }
                            selected: bookings(first: 0, where: ${bookingWheres}) {
                              totalCount
                            }
                            approvedHcps: journals(first: 0, where: ${approvedHcpsWheres}) {
                              totalCount
                            }
                            newApprovedHcps: journals(first: 0, where: ${newApprovedHcps}) {
                              totalCount
                            }
                        }
                    `,
        fetchPolicy: 'no-cache',
      });

      this.setState({
        data: groupedKpis,
        bookingsData: {
          selected: result.data.selected.totalCount,
          total: result.data.allBookings.totalCount,
        },
        hcpsData: {
          approvedHcps: result.data.approvedHcps.totalCount,
          newApprovedHcps: result.data.newApprovedHcps.totalCount,
        },
        fetching: false,
      });
    } catch (error) {
      this.setState({ fetching: false });
      this.showMessage(error.message, 'error');
    }
  };

  handleChangeSelect = (event) => {
    const data = {
      [event.target.name]: event.target.value,
      starts:
        event.target.value !== 'custom'
          ? moment()
              .subtract(event.target.value - 1, 'day')
              .startOf('day')
          : this.state.starts,
      ends: event.target.value !== 'custom' ? moment() : this.state.ends,
    };
    this.setState(data, () => {
      if (event.target.value !== 'custom') {
        this.fetchKPIs();
      }
    });
  };

  handleChangeRange = async (starts, ends) => {
    await this.setState({ starts, ends });
  };

  componentDidMount() {
    this.setState({
      loading: false,
    });
    this.handleChangeSelect({
      target: {
        name: 'period',
        value: 7,
      },
    });
    // this.fetchKPIs(this.state.period);
  }

  calculateWorkload = () => {
    let noOfLots = 0;
    let noOfBookings = 0;
    if (this.state.data)
      this.state.data.forEach((d) => {
        noOfBookings = noOfBookings + d.countOfBookings;
        noOfLots = noOfLots + d.countOfLots;
      });
    return noOfBookings / noOfLots || 0;
  };

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.loading ? (
          <div />
        ) : (
          <AdminContextConsumer>
            {({ onLink }) => (
              <React.Fragment>
                <div className={classes.head}>
                  <Typography variant={'h5'} className={classes.header} align={'center'}>
                    {i18n.get('dashboard.period.label')}{' '}
                  </Typography>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">{i18n.get('dashboard.period')}</InputLabel>
                    <Select
                      value={this.state.period}
                      onChange={this.handleChangeSelect}
                      inputProps={{
                        name: 'period',
                        id: 'period',
                      }}
                    >
                      <MenuItem value={7}>{i18n.get('dashboard.period.lastSevenDays')}</MenuItem>
                      <MenuItem value={14}>{i18n.get('dashboard.period.lastTwoWeeks')}</MenuItem>
                      <MenuItem value={'custom'}>{i18n.get('dashboard.period.custom')}</MenuItem>
                    </Select>
                  </FormControl>
                  <Box marginLeft={2}>
                    <DateRangePicker
                      small
                      isOutsideRange={(day) => !day.isBefore(moment())}
                      displayFormat={'DD.MM.YYYY'}
                      disabled={this.state.period !== 'custom'}
                      startDate={this.state.starts} // momentPropTypes.momentObj or null,
                      startDateId="starts" // PropTypes.string.isRequired,
                      endDate={this.state.ends} // momentPropTypes.momentObj or null,
                      endDateId="ends" // PropTypes.string.isRequired,
                      onClose={async ({ startDate, endDate }) => {
                        await this.handleChangeRange(startDate, endDate);
                        await this.fetchKPIs();
                      }}
                      onDatesChange={({ startDate, endDate }) => {
                        this.handleChangeRange(startDate, endDate);
                      }} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    />
                  </Box>
                </div>
                {this.state.fetching ? (
                  <ReportSkeleton />
                ) : (
                  <React.Fragment>
                    <SimpleLineChart data={this.state.data} />

                    <Typography variant={'h5'} className={classes.header} align={'center'}>
                      {i18n.get('dashboard.header')}
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item lg={4} sm={12}>
                        <Card className={classes.card}>
                          <CardContent style={{ height: '100%' }}>
                            <Typography variant="h5" component="h2">
                              {i18n.get('dashboard.grid.1.title')}
                            </Typography>
                            <GaugeChart
                              id="bookings-lots"
                              style={{
                                height: '100%',
                              }}
                              arcsLength={[0.8, 0.1, 0.1]}
                              arcWidth={0.3}
                              colors={[Colors.primary, Colors.orange, Colors.red]}
                              arcPadding={0.01}
                              percent={this.calculateWorkload()}
                              colorRadius={2}
                              needleColor="#BBB"
                              needleBaseColor="#AAA"
                              textColor="#00254C"
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={4} sm={12}>
                        <Card className={classes.card}>
                          <CardContent style={{ height: '100%' }}>
                            <Typography variant="h5" component="h2">
                              {i18n.get('dashboard.grid.2.title')}
                            </Typography>
                            <ResponsiveContainer width={'99%'} height={'90%'}>
                              <PieChart>
                                <Legend />
                                <Pie
                                  data={[
                                    {
                                      name: i18n.get('dashboard.grid.2.firstLine', {
                                        number: this.state.hcpsData.approvedHcps,
                                      }),
                                      value: this.state.hcpsData.approvedHcps,
                                    },
                                    {
                                      name: i18n.get('dashboard.grid.2.secondLine', {
                                        number: this.state.hcpsData.newApprovedHcps,
                                      }),
                                      value: this.state.hcpsData.newApprovedHcps,
                                    },
                                  ]}
                                  labelLine={false}
                                  label={this.renderCustomizedLabel}
                                  outerRadius={80}
                                  dataKey="value"
                                  fill="#8884d8"
                                >
                                  {[
                                    {
                                      name: i18n.get('dashboard.grid.2.firstLine', {
                                        number: this.state.hcpsData.approvedHcps,
                                      }),
                                      value: this.state.hcpsData.approvedHcps,
                                    },
                                    {
                                      name: i18n.get('dashboard.grid.2.secondLine', {
                                        number: this.state.hcpsData.newApprovedHcps,
                                      }),
                                      value: this.state.hcpsData.newApprovedHcps,
                                    },
                                  ].map((entry, index) => (
                                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            {/* <Typography component="p">
                              {i18n.get('dashboard.grid.2.firstLine', { number: this.state.hcpsData.approvedHcps })}
                            </Typography>
                            <Typography component="p">
                              {i18n.get('dashboard.grid.2.secondLine', { number: this.state.hcpsData.newApprovedHcps })}
                            </Typography> */}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={4} sm={12}>
                        <Card className={classes.card}>
                          <CardContent style={{ height: '100%' }}>
                            <Typography variant="h5" component="h2">
                              {i18n.get('dashboard.grid.3.title')}
                            </Typography>
                            <ResponsiveContainer width={'99%'} height={'90%'}>
                              <PieChart>
                                <Legend />
                                <Pie
                                  data={[
                                    {
                                      name: i18n.get('dashboard.grid.3.firstLine', {
                                        number: this.state.bookingsData.selected,
                                      }),
                                      value: this.state.bookingsData.selected,
                                    },
                                    {
                                      name: i18n.get('dashboard.grid.3.secondLine', {
                                        number: this.state.bookingsData.total,
                                      }),
                                      value: this.state.bookingsData.total,
                                    },
                                  ]}
                                  labelLine={false}
                                  label={this.renderCustomizedLabel}
                                  outerRadius={80}
                                  dataKey="value"
                                  fill="#8884d8"
                                >
                                  {[
                                    {
                                      name: i18n.get('dashboard.grid.3.firstLine', {
                                        number: this.state.bookingsData.selected,
                                      }),
                                      value: this.state.bookingsData.selected,
                                    },
                                    {
                                      name: i18n.get('dashboard.grid.3.secondLine', {
                                        number: this.state.bookingsData.total,
                                      }),
                                      value: this.state.bookingsData.total,
                                    },
                                  ].map((entry, index) => (
                                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            {/* <Typography component="p">
                              {i18n.get('dashboard.grid.3.firstLine', { number: this.state.bookingsData.selected })}
                            </Typography>
                            <Typography component="p">
                              {i18n.get('dashboard.grid.3.secondLine', { number: this.state.bookingsData.total })}
                            </Typography> */}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </AdminContextConsumer>
        )}
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withIAM(Dashboard));
