import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as i18n from '../common/i18n';
import { reactLocalStorage } from 'reactjs-localstorage';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const STORAGE_PROPERTY_NAME = 'p37.welcomeSettings';

export const setWelcomeSettings = (state) => {
  reactLocalStorage.setObject(STORAGE_PROPERTY_NAME, { show: state });
};

export default function WelcomeDialog(props) {
  const [open, setOpen] = React.useState(reactLocalStorage.getObject(STORAGE_PROPERTY_NAME, { show: true }).show);

  const handleClose = () => {
    setOpen(false);
    setWelcomeSettings(false);
  };

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="welcome-dialog-title"
      aria-describedby="welcome-dialog-description"
    >
      <DialogTitle id="welcome-dialog-title">{i18n.get('welcomeDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="welcome-dialog-description" color="textPrimary">
          {i18n.get('welcomeDialog.message', { name: props.name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary" variant="contained">
          {i18n.get('welcomeDialog.buttonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
