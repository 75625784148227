import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  position: 'relative',
  display: 'flex',
  minWidth: 0,
  // overflow: 'hidden',
};

const closeButton = {
  position: 'absolute',
  top: '-25px',
  right: '-25px',
  backgroundColor: 'white',
};

const img = {
  display: 'block',
  width: 100,
  height: 100,
};

export default function ImageUpload(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const previews = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(previews);
      props.onDrop(previews);
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <IconButton
          style={closeButton}
          onClick={() => {
            const filteredFiles = files.filter((f) => f.name !== file.name);
            setFiles(filteredFiles);
            props.onDrop(filteredFiles);
          }}
        >
          <CloseIcon />
        </IconButton>
        <img alt="" src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (props.value?.uri) {
      setFiles([
        {
          name: 'temp',
          preview: props.value.uri,
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section className="container">
      {thumbs.length === 0 && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Ziehen Sie ein Bild hierher oder klicken Sie, um ein Bild auszuwählen</p>
        </div>
      )}
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}
