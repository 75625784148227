import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';

export default function ReportSkeleton() {
  return (
    <React.Fragment>
      <Skeleton variant="rect" width={'99%'} height={320} />
      <Typography variant={'h5'} align={'center'}>
        <Skeleton variant="text" margin={5} />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <Skeleton variant="rect" height={300}></Skeleton>
        </Grid>
        <Grid item xs>
          <Skeleton variant="rect" height={300}></Skeleton>
        </Grid>
        <Grid item xs>
          <Skeleton variant="rect" height={300}></Skeleton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
