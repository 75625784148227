import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PaginationDialog from '../components/PaginationDialog';
import { HCP_TITLES, HCP_TYPES, STATUSES } from '../common/enums';
import { STATUSES_COLORS } from '../common/colors';

const styles = (theme) => ({});

const defaults = {
  id: true,
  labelEN: false,
  labelDE: false,
  qualifications: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.specializations.defaults';

class Specializations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (validator.isEmpty(data.label.de) || validator.isEmpty(data.label.en)) {
        throw new Error(i18n.get('specializations.error.validation'));
      }

      const input = {
        en: data.label.en,
        de: data.label.de,
      };

      return input;
    } catch (error) {
      throw new Error(i18n.get('specializations.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'label.de':
        return 'label_de';
      case 'label.en':
        return 'label_en';
      case 'qualifications':
        return 'qualifications_search_string';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationHCPData = async (query) => {
    try {
      const where = `{column:"specializations_search_string", comparator:LIKE, string: ",${this.state.paginationObject.id},"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            hcps(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on HCP {
                                  id
                                  number
                                  status
                                  title
                                  type
                                  firstname
                                  lastname  
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.hcps.elements,
        totalCount: result.data.hcps.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  getQualificationsSuggestions = async (value) => {
    try {
      const where = `[{column: "label_de", comparator: LIKE_OR, string: "${value}"}, {column: "label_en", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                qualifications(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Qualification {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.qualifications.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultQualificationsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newQualificationsIds = newData.qualifications
        ? newData.qualifications.map((p) => (p.value ? p.value : p.id))
        : [];
      const oldQualificationsIds = oldData && oldData.qualifications ? oldData.qualifications.map((p) => p.id) : [];

      const addedQualificationsIds = helper.diffArray(newQualificationsIds, oldQualificationsIds);

      for (const addedQualificationId of addedQualificationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            qualificationId: addedQualificationId,
          },
          mutation: gql`
            mutation assignQualificationToSpecialization($id: ID!, $qualificationId: ID!) {
              assignQualificationToSpecialization(id: $id, qualificationId: $qualificationId) {
                id
              }
            }
          `,
        });
      }

      // these I need to remove
      const removedQualificationsIds = helper.diffArray(oldQualificationsIds, newQualificationsIds);

      for (const removedQualificationId of removedQualificationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            qualificationId: removedQualificationId,
          },
          mutation: gql`
            mutation unassignQualificationFromSpecialization($id: ID!, $qualificationId: ID!) {
              unassignQualificationFromSpecialization(id: $id, qualificationId: $qualificationId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('specializations.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('specializations.label.en'),
              field: 'label.en',
              hidden: this.default('labelEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('specializations.label.de'),
              field: 'label.de',
              hidden: this.default('labelDE'),
              defaultSort: 'asc',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('specializations.qualifications'),
              field: 'qualifications',
              hidden: this.default('qualifications'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label.de}`,
                      value: v.id,
                    };
                  }),
                this.getQualificationsSuggestions,
                this.getDefaultQualificationsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.qualifications
                    ? rowData.qualifications.map((qualification) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.qualifications'), data: { id: qualification.id } }}
                          key={qualification.id}
                          label={`${qualification.label.de}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label?.de}`, value: v.id };
                      })
                    : [],
                  i18n.get('specializations.qualifications.label'),
                  null,
                  this.getQualificationsSuggestions,
                  this.getDefaultQualificationsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          specializations(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Specialization {
                                  id,
                                  label {
                                    en
                                    de
                                  }
                                  qualifications {
                                    id
                                    label {
                                      de
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.specializations.elements,
                  totalCount: result.data.specializations.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: () => {
                return <PersonAddIcon />;
              },
              tooltip: i18n.get('specializations.hcps'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('hcps.id'), field: 'id', hidden: true },
                    { title: i18n.get('hcps.number'), field: 'number' },
                    {
                      title: i18n.get('hcps.status'),
                      field: 'status',
                      lookup: STATUSES,
                      render: (rowData) => {
                        return rowData.status ? (
                          <Chip
                            label={`${STATUSES[rowData.status]}`}
                            style={{
                              backgroundColor: STATUSES_COLORS[rowData.status],
                            }}
                          />
                        ) : (
                          '-'
                        );
                      },
                    },
                    { title: i18n.get('hcps.title'), field: 'title', lookup: HCP_TITLES },
                    { title: i18n.get('hcps.type'), field: 'type', lookup: HCP_TYPES },
                    { title: i18n.get('hcps.firstname'), field: 'firstname' },
                    { title: i18n.get('hcps.lastname'), field: 'lastname', sorting: false },
                  ],
                  paginationTitle: i18n.get('specializations.pagination.title'),
                  fetchPaginationData: this.fetchPaginationHCPData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'specializations' }),
          ]}
          title={i18n.get('specializations.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createSpecialization($id: ID!, $input: LocalizedStringInput!) {
                            createSpecialization(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('specializations.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('specializations.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateSpecialization($id: ID!, $input: LocalizedStringInput!) {
                        updateSpecialization(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('specializations.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('specializations.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteSpecialization($id: ID!) {
                        deleteSpecialization(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('specializations.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('specializations.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Specializations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Specializations)));
