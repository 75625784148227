import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import MaterialTable from './MaterialTable';

import Message from '../components/Message';
import * as helper from '../common/helper';

const styles = (theme) => ({});

class PaginationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      confirm: false,
      working: false,
      resetObject: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      data: [],
      loading: false,
    };

    this.tableRef = React.createRef();
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  async componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && this.props.staticData) {
      await this.setState({
        loading: true,
      });
      const result = await this.props.fetchData();
      await this.setState({
        loading: false,
        data: result.data,
      });
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} keepMounted onClose={this.props.onCancel}>
        <DialogTitle>{this.props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: '100%' }}>
            {this.props.open && (
              <MaterialTable
                components={{
                  Cell: helper.defaultCell,
                  //FilterRow: helper.defaultFilter
                }}
                isLoading={this.state.loading}
                tableRef={this.tableRef}
                columns={this.props.columns}
                options={{
                  exportButton: false,
                  filtering: false,
                  showTitle: false,
                  debounceInterval: 500,
                  search: false,
                  columnsButton: true,
                  pageSize: 10,
                }}
                data={
                  this.props.staticData
                    ? this.state.data
                    : (query) =>
                        new Promise(async (resolve, reject) => {
                          try {
                            resolve(await this.props.fetchData(query));
                          } catch (error) {
                            this.showMessage(error.message, 'error');
                            reject(error);
                          }
                        })
                }
              />
            )}
            <Message
              hideAfter={this.state.hideAfter}
              onClose={this.closeMessage}
              variant={this.state.variant}
              message={this.state.message}
              details={this.state.details}
              open={this.state.message !== null}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            {this.props.cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PaginationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PaginationDialog);
