export const primary = '#53A3A8';
export const secondary = '#C46F63';
export const red = '#e80623';
export const coral = '#ffad9c';
export const orange = '#ffca5b';
export const green = '#55efed';
export const blue = '#5ebaff';

export const MESSAGE_COLORS = {
  blue: '#005A7A',
  green: '#396200',
  red: '#AB0032',
  orange: '#F9B31D',
};

export const STATUSES_COLORS = {
  ENROLLED: primary,
  NEW: blue,
  REJECTED: red,
  SUBMITTED: green,
  SUSPENDED: orange,
  UNDERREVIEW: coral,
};
