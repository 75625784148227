import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import Chip from '@material-ui/core/Chip';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { Countries } from '../common/enums';
import validator from 'validator';
import moment from 'moment';
import Jimp from 'jimp';

const styles = (theme) => ({});

const defaults = {
  id: true,
  phash: false,
  score: false,
  comment: false,
  created: false,
  countries: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.unwantedImages.defaults';

class UnwantedImages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = async (data) => {
    try {
      if (data.phash instanceof File) {
        const image = await Jimp.read(data.phash.preview);
        data.phash = image.pHash();
      }

      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.phash) ||
        validator.isNumeric(toString(data.score)) ||
        validator.isEmpty(data.comment) ||
        validator.isEmpty(data.created.toString())
      ) {
        throw new Error(i18n.get('unwantedImages.error.validation'));
      }

      const created = moment(data.created).unix();
      data['created'] = created;

      const input = {};

      helper.set(input, data, 'phash');
      helper.set(input, data, 'score', parseInt);
      helper.set(input, data, 'comment');
      helper.set(input, data, 'created');
      helper.set(input, data, 'countries', (countries) => countries.map((country) => country.value || country));

      return input;
    } catch (error) {
      throw new Error(i18n.get('unwantedImages.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  updateRelationships = async (newData, oldData) => {
    try {
    } catch (error) {
      throw error;
    }
  };

  getCountriesSuggestions = async (value) => {
    return Object.entries(Countries)
      .filter((country) => country[1].toLowerCase().indexOf(value.toLowerCase()) > -1)
      .map((country) => ({
        value: country[0],
        name: country[1],
      }));
  };

  getDefaultCountriesSuggestions = () => {
    return Object.entries(Countries).map((country) => ({
      value: country[0],
      name: country[1],
    }));
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('unwantedImages.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('unwantedImages.phash'),
              field: 'phash',
              hidden: this.default('phash'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
                wordBreak: 'break-all',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.defaultImageUpload,
            },
            {
              title: i18n.get('unwantedImages.score'),
              field: 'score',
              hidden: this.default('score'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('unwantedImages.comment'),
              field: 'comment',
              hidden: this.default('comment'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('unwantedImages.created'),
              field: 'created',
              hidden: this.default('created'),
              sorting: true,
              filtering: true,
              type: 'datetime',
              filterComponent: helper.DateRangeFilter,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('unwantedImages.countries'),
              field: 'countries',
              hidden: this.default('countries'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                if (rowData.countries) {
                  return rowData.countries
                    ? rowData.countries.map((country) => (
                        <Chip
                          key={country}
                          label={`${Countries[country]}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.name || Countries[v]}`, value: v.value || v };
                      })
                    : [],
                  i18n.get('unwantedImages.countries.label'),
                  null,
                  this.getCountriesSuggestions,
                  this.getDefaultCountriesSuggestions,
                  50
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          unwantedImages(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on UnwantedImage {
                                  id
                                  phash
                                  score
                                  comment
                                  created {
                                    isoString
                                  }
                                  countries
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.unwantedImages.elements.map((element) => ({
                    ...element,
                    created: element.created.isoString ? moment(element.created.isoString).toDate() : null,
                  })),
                  totalCount: result.data.unwantedImages.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'unwantedImages' }),
          ]}
          title={i18n.get('unwantedImages.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = await this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createUnwantedImage($id: ID!, $input: UnwantedImageInput!) {
                            createUnwantedImage(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      // await this.updateRelationships(newData);

                      this.showMessage(i18n.get('unwantedImages.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('unwantedImages.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = await this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateUnwantedImage($id: ID!, $input: UnwantedImageInput!) {
                        updateUnwantedImage(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  // await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('unwantedImages.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('unwantedImages.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteUnwantedImage($id: ID!) {
                        deleteUnwantedImage(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('unwantedImages.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('unwantedImages.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

UnwantedImages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(UnwantedImages)));
