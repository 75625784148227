import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { PLATFORMS, DEVICES } from '../common/enums';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  platform: false,
  os: false,
  devices: false,
  version: false,
  titleEN: false,
  titleDE: false,
  messageEN: false,
  messageDE: false,
  validFrom: false,
  validUntil: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.systemmessages.defaults';

class SystemMessages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.platform) ||
        validator.isEmpty(data.os) ||
        validator.isEmpty(data.devices) ||
        validator.isEmpty(data.version) ||
        validator.isEmpty(data.title.en) ||
        validator.isEmpty(data.title.de) ||
        validator.isEmpty(data.message.en) ||
        validator.isEmpty(data.message.de) ||
        validator.isEmpty(data.validFrom.toString()) ||
        validator.isEmpty(data.validUntil.toString())
      ) {
        throw new Error(i18n.get('systemmessages.error.validation'));
      }

      const input = {
        title: {
          en: data.title.en,
          de: data.title.de,
        },
        message: {
          en: data.message.en,
          de: data.message.de,
        },
      };

      const validFrom = moment(data.validFrom).unix();
      const validUntil = moment(data.validUntil).unix();
      data['validFrom'] = validFrom;
      data['validUntil'] = validUntil;

      helper.set(input, data, 'platform');
      helper.set(input, data, 'os');
      helper.set(input, data, 'devices');
      helper.set(input, data, 'version');
      helper.set(input, data, 'platform');
      helper.set(input, data, 'validFrom');
      helper.set(input, data, 'validUntil');

      return input;
    } catch (error) {
      throw new Error(i18n.get('systemmessages.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'title.en':
        return 'title_en';
      case 'title.de':
        return 'title_de';
      case 'message.en':
        return 'message_en';
      case 'message.de':
        return 'message_de';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('systemmessages.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('systemmessages.platform'),
              field: 'platform',
              hidden: this.default('platform'),
              lookup: PLATFORMS,
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('systemmessages.devices'),
              field: 'devices',
              hidden: this.default('devices'),
              lookup: DEVICES,
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('systemmessages.os'),
              field: 'os',
              hidden: this.default('os'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('systemmessages.version'),
              field: 'version',
              hidden: this.default('version'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('systemmessages.title.en'),
              field: 'title.en',
              hidden: this.default('titleEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('systemmessages.title.de'),
              field: 'title.de',
              hidden: this.default('titleEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('systemmessages.message.en'),
              field: 'message.en',
              hidden: this.default('messageEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              message: i18n.get('systemmessages.message.de'),
              field: 'message.de',
              hidden: this.default('messageEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('systemmessages.validFrom'),
              field: 'validFrom',
              hidden: this.default('validFrom'),
              type: 'datetime',
              comparator: 'GTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('systemmessages.validUntil'),
              field: 'validUntil',
              hidden: this.default('validUntil'),
              type: 'datetime',
              comparator: 'LTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          systemmessages(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on SystemMessage {
                                  id
                                  platform
                                  devices
                                  os
                                  version
                                  title {
                                    en
                                    de
                                  }
                                  message {
                                    en
                                    de
                                  }
                                  validFrom {
                                    isoString
                                  }
                                  validUntil {
                                    isoString
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.systemmessages.elements.map((element) => {
                    return {
                      ...element,
                      validFrom: element.validFrom.isoString ? moment(element.validFrom.isoString).toDate() : null,
                      validUntil: element.validUntil.isoString ? moment(element.validUntil.isoString).toDate() : null,
                    };
                  }),
                  totalCount: result.data.systemmessages.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'systemmessages' }),
          ]}
          title={i18n.get('systemmessages.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createSystemMessage($id: ID!, $input: SystemMessageInput!) {
                            createSystemMessage(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      this.showMessage(i18n.get('systemmessages.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('systemmessages.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateSystemMessage($id: ID!, $input: SystemMessageInput!) {
                        updateSystemMessage(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  this.showMessage(i18n.get('systemmessages.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('systemmessages.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteSystemMessage($id: ID!) {
                        deleteSystemMessage(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('systemmessages.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('systemmessages.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

SystemMessages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(SystemMessages)));
