import * as i18n from '../common/i18n';

export const Countries = {
  DE: i18n.get('enums.countries.DE'),
};

export const STATUSES = {
  ENROLLED: i18n.get('enums.statuses.ENROLLED'),
  NEW: i18n.get('enums.statuses.NEW'),
  REJECTED: i18n.get('enums.statuses.REJECTED'),
  SUBMITTED: i18n.get('enums.statuses.SUBMITTED'),
  SUSPENDED: i18n.get('enums.statuses.SUSPENDED'),
  UNDERREVIEW: i18n.get('enums.statuses.UNDERREVIEW'),
};

export const LEGAL_ENTITY = {
  GMBH: i18n.get('enums.legalEntity.GMBH'),
  GBR: i18n.get('enums.legalEntity.GBR'),
  GMBH_COKG: i18n.get('enums.legalEntity.GMBH_COKG'),
  OHG: i18n.get('enums.legalEntity.OHG'),
  UG: i18n.get('enums.legalEntity.UG'),
  KG: i18n.get('enums.legalEntity.KG'),
  AG: i18n.get('enums.legalEntity.AG'),
  PARTG: i18n.get('enums.legalEntity.PARTG'),
  PARTG_MBB: i18n.get('enums.legalEntity.PARTG_MBB'),
  KGAA: i18n.get('enums.legalEntity.KGAA'),
  EK: i18n.get('enums.legalEntity.EK'),
};

export const HCP_TYPES = {
  PHYSICIAN: i18n.get('enums.hcpTypes.PHYSICIAN'),
  MEDICALASSISTANT: i18n.get('enums.hcpTypes.MEDICALASSISTANT'),
  EMPLOYEDPHYSICIAN: i18n.get('enums.hcpTypes.EMPLOYEDPHYSICIAN'),
  ADMINISTRATOR: i18n.get('enums.hcpTypes.ADMINISTRATOR'),
};

export const HCP_TITLES = {
  DR: i18n.get('enums.hcpTitles.DR'),
  PROF: i18n.get('enums.hcpTitles.PROF'),
  OTHER: i18n.get('enums.hcpTitles.OTHER'),
};

export const ACTIONS = {
  CREATED: i18n.get('enums.actions.CREATED'),
  UPDATED: i18n.get('enums.actions.UPDATED'),
  DELETED: i18n.get('enums.actions.DELETED'),
};

export const ROLES = {
  ADMIN: i18n.get('enums.roles.ADMIN'),
  SUPPORT: i18n.get('enums.roles.SUPPORT'),
  FINANCE: i18n.get('enums.roles.FINANCE'),
  TECHNICAL: i18n.get('enums.roles.TECHNICAL'),
};

export const LEAD_TIMES = {
  IMMEDIATE: i18n.get('enums.leadTimes.IMMEDIATE'),
  NEXT_WEEKEND: i18n.get('enums.leadTimes.NEXT_WEEKEND'),
  SEVEN_DAYS_WEEKEND: i18n.get('enums.leadTimes.SEVEN_DAYS_WEEKEND'),
};

export const PURCHASE_TYPE = {
  STANDARD: i18n.get('enums.purchaseTypes.STANDARD'),
  WEEKEND: i18n.get('enums.purchaseTypes.WEEKEND'),
};

export const POINT_TYPE = {
  STANDARD: i18n.get('enums.pointTypes.STANDARD'),
  WEEKEND: i18n.get('enums.pointTypes.WEEKEND'),
};

export const CURRENCIES = {
  EUR: i18n.get('enums.currencies.EUR'),
  RSD: i18n.get('enums.currencies.RSD'),
};

export const REDEEM_STATUS = {
  NEW: i18n.get('enums.redeemStatuses.NEW'),
  ACTIVE: i18n.get('enums.redeemStatuses.ACTIVE'),
  USED: i18n.get('enums.redeemStatuses.USED'),
  REVOKED: i18n.get('enums.redeemStatuses.REVOKED'),
};

export const LANGUAGES = {
  DE: i18n.get('enums.languages.DE'),
  EN: i18n.get('enums.languages.EN'),
};

export const REPORT_REASONS = {
  COPYRIGHT: i18n.get('enums.reportReasons.COPYRIGHT'),
  HARASSMENT: i18n.get('enums.reportReasons.HARASSMENT'),
  SPAM: i18n.get('enums.reportReasons.SPAM'),
  FRAUD: i18n.get('enums.reportReasons.FRAUD'),
  MISC: i18n.get('enums.reportReasons.MISC'),
};

export const REPORT_STATUSES = {
  NEW: i18n.get('enums.reportStatuses.NEW'),
  CLOSED: i18n.get('enums.reportStatuses.CLOSED'),
  INPROGRESS: i18n.get('enums.reportStatuses.INPROGRESS'),
};

export const TRANSFER_STATUSES = {
  NEW: i18n.get('enums.transferStatuses.NEW'),
  EXPORTED: i18n.get('enums.transferStatuses.EXPORTED'),
  DONE: i18n.get('enums.transferStatuses.DONE'),
};

export const PLATFORMS = {
  IOS: i18n.get('enums.platforms.IOS'),
  ANDROID: i18n.get('enums.platforms.ANDROID'),
  WEB: i18n.get('enums.platforms.WEB'),
};

export const DEVICES = {
  PHONE: i18n.get('enums.devices.PHONE'),
  TABLET: i18n.get('enums.devices.TABLET'),
  LAPTOP: i18n.get('enums.devices.LAPTOP'),
};

export const BOOKING_STATUS = {
  OK: i18n.get('enums.bookingStatus.OK'),
  CANCELED: i18n.get('enums.bookingStatus.CANCELED'),
  POSTPONED: i18n.get('enums.bookingStatus.POSTPONED'),
};

export const DELAY_REASONS = {
  EMERGENCY: i18n.get('enums.delayReasons.EMERGENCY'),
  OPERATION: i18n.get('enums.delayReasons.OPERATION'),
  OTHER: i18n.get('enums.delayReasons.OTHER'),
};
