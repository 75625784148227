import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { AdminContextConsumer } from '../main/Admin';

export default function ChipLink(props) {
  return (
    <AdminContextConsumer>
      {({ onLinkWithData }) => (
        <Chip
          {...props}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
            onLinkWithData(props.link.page, props.link.data);
          }}
        />
      )}
    </AdminContextConsumer>
  );
}

ChipLink.prototype = {
  link: PropTypes.object.isRequired,
};
