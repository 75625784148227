import ApolloClient from 'apollo-boost';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHLQL_ENDPOINT,
});

export function clientWithToken(token) {
  const headers = { Authorization: `Bearer ${token}` };
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHLQL_ENDPOINT,
    headers: headers,
  });
}
