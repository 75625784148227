import React from 'react';
import { withStyles } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/CloudUpload';
import Message from '../components/Message';
import * as i18n from '../common/i18n';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import * as graphql from '../common/graphql';
import { withIAM } from '../common/iamV2';

const styles = (theme) => ({});

class MarkdownEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      working: false,
      originalMD: null,
      md: null,
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    };
  }

  componentDidMount() {
    this.fetchMD();
  }

  handleChange = (md) => {
    this.setState({
      md: md,
    });
  };

  fetchMD = async () => {
    try {
      const result = await graphql.clientWithToken(this.props.accessToken).query(this.props.query);

      this.setState({
        md: result.data.md || '',
        originalMD: result.data.md || '',
      });
    } catch (error) {
      this.showMessage(i18n.get('markdown.message.fetch.error'), 'error', error.message);
    }
  };

  handleSave = async () => {
    this.setState({ working: true }, async () => {
      try {
        const result = await graphql.clientWithToken(this.props.accessToken).mutate(this.props.mutate(this.state.md));
        this.setState({ md: result.data.md, originalMD: result.data.md, working: false });
        this.showMessage(this.props.successMessage, 'success');
      } catch (error) {
        this.showMessage(i18n.get('markdown.message.save.error'), 'error', error.message);
        this.setState({ working: false });
      }
    });
  };

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: '100%' }}>
        {this.state.md !== null ? (
          <React.Fragment>
            <div className="container">
              <MDEditor
                value={this.state.md}
                onChange={this.handleChange}
                height={700}
                textareaProps={{
                  disabled: this.working,
                }}
              />
            </div>
            <Fab
              disabled={this.working || this.state.md === this.state.originalMD}
              onClick={this.handleSave}
              color="primary"
              aria-label="add"
              style={{
                position: 'fixed',
                bottom: '30px',
                right: '30px',
              }}
            >
              <SaveIcon />
            </Fab>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box className={classes.box}>
              <Skeleton variant="rect" width={'100%'} height={150} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width={'80%'} />
            </Box>
          </React.Fragment>
        )}
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

MarkdownEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(MarkdownEditor));
