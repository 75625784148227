import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import gql from 'graphql-tag';
import * as graphql from '../common/graphql';
import * as i18n from '../common/i18n';
import Message from '../components/Message';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core';
import { withIAM } from '../common/iamV2';
import * as Colors from '../common/colors';
import Box from '@material-ui/core/Box';
import { DateRangePicker } from 'react-dates';
import ReportSkeleton from '../components/ReportSkeleton';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  head: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  header: {
    margin: theme.spacing(5),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: '#AAAAAA33',
  },
});

function SimpleLineChart(props) {
  const { data } = props;
  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width="99%" height={320}>
      <LineChart data={data}>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          name={i18n.get('financeReports.chart.inAppPurchasesInEUR.name')}
          dataKey="InAppPurchasesInEUR"
          stroke={Colors.primary}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name={i18n.get('financeReports.chart.feesInEUR.name')}
          dataKey="feesInEUR"
          stroke={Colors.secondary}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

class FinanceReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      loading: true,
      period: 7,
      data: [
        {
          dateValue: 0,
          InAppPurchasesInEUR: 0,
          feesInEUR: 0,
        },
      ],
      fetching: false,
      sumOfPurchases: '',
      sumOfFees: '',
    };
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  handleChangeSelect = (event) => {
    const data = {
      [event.target.name]: event.target.value,
      starts:
        event.target.value !== 'custom'
          ? moment()
              .subtract(event.target.value - 1, 'day')
              .startOf('day')
          : this.state.starts,
      ends: event.target.value !== 'custom' ? moment() : this.state.ends,
    };
    this.setState(data, () => {
      if (event.target.value !== 'custom') {
        this.fetchKPIs();
      }
    });
  };

  handleChangeRange = async (starts, ends) => {
    await this.setState({ starts, ends });
  };

  fetchKPIs = async () => {
    await this.setState({ fetching: true });
    const wheres = `[
      ${
        this.state.starts
          ? `{column: "dateValue", comparator: GTE, datetime: ${this.state.starts.startOf('day').unix()}},`
          : ''
      }
      ${this.state.ends ? `{column: "dateValue", comparator: LTE, datetime: ${this.state.ends.unix()}},` : ''}
      {column: "label", comparator: IN, values: ["InAppPurchasesInEUR","feesInEUR"]},
    ]`;

    try {
      let kpis = [];
      let current = 0;
      let totalCount = 0;
      do {
        const result = await graphql.clientWithToken(this.props.accessToken).query({
          query: gql`
                        query {
                            kpis(first: 100, offset: ${current}, where: ${wheres} ) {
                                totalCount
                                elements {
                                    ... on KPI {
                                        id
                                        label
                                        dateValue {
                                          X
                                        }
                                        intValue
                                        floatValue
                                    }
                                }
                            }
                        }
                    `,
          fetchPolicy: 'no-cache',
        });

        totalCount = result.data.kpis.totalCount;
        current = current + result.data.kpis.elements.length;
        kpis = kpis.concat(result.data.kpis.elements);
      } while (current < totalCount);

      const groupedKpis = Object.entries(
        kpis.reduce((r, a) => {
          if (r && r.hasOwnProperty && r.hasOwnProperty(a.dateValue.X)) {
            r[a.dateValue.X].push(a);
          } else {
            const sameDay = Object.keys(r).find((key) => moment.unix(key).isSame(moment.unix(a.dateValue.X), 'day'));
            if (sameDay) {
              r[a.dateValue.X] = r[sameDay];
              delete r[sameDay];
              r[a.dateValue.X].push(a);
            } else {
              r[a.dateValue.X] = [];
              r[a.dateValue.X].push(a);
            }
          }
          return r;
        }, {})
      ).map((kpi) => {
        return {
          date: moment(kpi[0], 'X').format('DD.MM.YYYY'),
          InAppPurchasesInEUR: kpi[1].find((k) => k.label === 'InAppPurchasesInEUR').floatValue,
          feesInEUR: kpi[1].find((k) => k.label === 'feesInEUR').floatValue,
        };
      });

      const sumOfPurchases = kpis.reduce((acc, kpi) => {
        if (kpi.label === 'InAppPurchasesInEUR') {
          acc = acc + kpi.floatValue;
        }
        return acc;
      }, 0);

      const sumOfFees = kpis.reduce((acc, kpi) => {
        if (kpi.label === 'feesInEUR') {
          acc = acc + kpi.floatValue;
        }
        return acc;
      }, 0);

      this.setState({ data: groupedKpis, sumOfPurchases, sumOfFees, fetching: false });
    } catch (error) {
      this.showMessage(error.message, 'error');
    }
  };

  componentDidMount() {
    this.setState({
      loading: false,
    });
    this.handleChangeSelect({
      target: {
        name: 'period',
        value: 7,
      },
    });
    // this.fetchKPIs(this.state.period);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.loading ? (
          <div />
        ) : (
          <React.Fragment>
            <div className={classes.head}>
              <Typography variant={'h5'} className={classes.header} align={'center'}>
                {i18n.get('financeReports.period.label')}{' '}
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">{i18n.get('financeReports.period')}</InputLabel>
                <Select
                  value={this.state.period}
                  onChange={this.handleChangeSelect}
                  inputProps={{
                    name: 'period',
                    id: 'period',
                  }}
                >
                  <MenuItem value={7}>{i18n.get('financeReports.period.lastSevenDays')}</MenuItem>
                  <MenuItem value={14}>{i18n.get('financeReports.period.lastTwoWeeks')}</MenuItem>
                  <MenuItem value={'custom'}>{i18n.get('financeReports.period.custom')}</MenuItem>
                </Select>
              </FormControl>
              <Box marginLeft={2}>
                <DateRangePicker
                  small
                  isOutsideRange={(day) => !day.isBefore(moment())}
                  displayFormat={'DD.MM.YYYY'}
                  disabled={this.state.period !== 'custom'}
                  startDate={this.state.starts} // momentPropTypes.momentObj or null,
                  startDateId="starts" // PropTypes.string.isRequired,
                  endDate={this.state.ends} // momentPropTypes.momentObj or null,
                  endDateId="ends" // PropTypes.string.isRequired,
                  onClose={async ({ startDate, endDate }) => {
                    await this.handleChangeRange(startDate, endDate);
                    await this.fetchKPIs();
                  }}
                  onDatesChange={({ startDate, endDate }) => {
                    this.handleChangeRange(startDate, endDate);
                  }} // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                />
              </Box>
            </div>
            {this.state.fetching ? (
              <ReportSkeleton />
            ) : (
              <React.Fragment>
                <SimpleLineChart data={this.state.data} />

                <Typography variant={'h5'} className={classes.header} align={'center'}>
                  {i18n.get('financeReports.header')}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2" align={'center'}>
                          {i18n.get('financeReports.grid.1.text', { number: this.state.sumOfPurchases })}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2" align={'center'}>
                          {i18n.get('financeReports.grid.2.text', { number: this.state.sumOfFees })}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2" align={'center'}>
                          {i18n.get('financeReports.grid.3.text', {
                            number: this.state.sumOfPurchases - this.state.sumOfFees,
                          })}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </React.Fragment>
    );
  }
}

FinanceReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withIAM(FinanceReport));
