import intl from 'react-intl-universal';

const i18nConfig = {
  locale: 'de',
  messages: {
    required: 'Pflichtfeld',
    optioned: 'Optionsfeld',

    'admin.logout': 'Abmelden',
    'admin.changeName': 'Namen ändern',
    'admin.changePassword': 'Passwort ändern',
    'admin.changeProfilePicture': 'Profilbild ändern',
    'general.reload': 'Tabelle neu laden',
    'general.exportSepaXml': 'Export Sepa-XML',
    'general.table.actions.resetAllFilters': 'Alle Filter zurücksetzen',

    'welcomeDialog.title': 'Willkommen',
    'welcomeDialog.message': 'Guten Tag, ##name##. Willkommen bei der Hermine Admin-App.',
    'welcomeDialog.buttonLabel': 'OK',

    'changeProfilePicDialog.title': 'Profilbild ändern',
    'changeProfilePicDialog.action.cancel': 'Stornieren',
    'changeProfilePicDialog.action.submit': 'Aktualisieren',
    'changeProfilePicDialog.message.update.success': 'Profilbild wurde erfolgreich angepasst',
    'changeProfilePicDialog.message.update.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    'changePasswordDialog.title': 'Passwort ändern',
    'changePasswordDialog.field.password.label': 'Passwort',
    'changePasswordDialog.field.confirmPassword.label': 'Passwortbestätigung',
    'changePasswordDialog.action.cancel': 'Stornieren',
    'changePasswordDialog.action.submit': 'Einreichen',
    'changePasswordDialog.action.success': 'Erfolg',
    'changePasswordDialog.action.error': 'Error',

    'changeNameDialog.title': 'Namen ändern',
    'changeNameDialog.field.name.label': 'Namen',
    'changeNameDialog.action.cancel': 'Stornieren',
    'changeNameDialog.action.submit': 'Einreichen',
    'changeNameDialog.action.success': 'Erfolg',
    'changeNameDialog.action.error': 'Error',

    'menus.dashboard': 'Übersicht',
    'menus.healthProvider': 'Health Provider',
    'menus.practices': 'Alle Praxen',
    'menus.enrolledpractices': 'Praxen',
    'menus.onboardingpractices': 'Onboarding Praxen',
    'menus.locations': 'Standorte',
    'menus.cities': 'Städte',
    'menus.bankAccounts': 'Bankverbindungen',
    'menus.equipments': 'Ausstattungen',
    'menus.qualifications': 'QS-Leistungen',
    'menus.hcps': 'Alle Ärzte/-innen',
    'menus.enrolledhcps': 'Ärzte/-innen',
    'menus.onboardinghcps': 'Onboarding Ärzte/-innen',
    'menus.identification': 'Identifikation',
    'menus.contractlevels': 'Vertragsstufen',
    'menus.constraints': 'Einschränkungen',
    'menus.lots': 'Sprechstunden',
    'menus.photos': 'Fotos',
    'menus.patients': 'Patienten',
    'menus.customers': 'Kunden',
    'menus.bookings': 'Buchungen',
    'menus.reviews': 'Bewertungen',
    'menus.performance': 'Performance',
    'menus.finance': 'Finanzen',
    'menus.purchases': 'In-App-Käufe',
    'menus.points': 'Buchungspunkte',
    'menus.fees': 'Gebühren',
    'menus.invoices': 'Rechnungen',
    'menus.transfers': 'Überweisungen',
    'menus.reports': 'Berichte',
    'menus.financialReport': 'Finanzbericht',
    'menus.reviewReport': 'Bewertungsbericht',
    'menus.workloadReport': 'Auslastungsbericht',
    'menus.fraudDetection': 'Fraud Detection',
    'menus.unwantedKeywords': 'Unerwünschte Inhalte',
    'menus.unwantedImages': 'Unerwünschte Bilder',
    'menus.content': 'Inhalte',
    'menus.text': 'Texte',
    'menus.textOnboarding': 'Texte Onboarding',
    'menus.phones': 'Telefone',
    'menus.systemmessages': 'Systemmeldungen',
    'menus.imprint': 'Impressum',
    'menus.privacy': 'Datenschutzerklärung',
    'menus.tos': 'Nutzungsbedingungen',
    'menus.faq': 'FAQs',
    'menus.cooperativeAgreements': 'Kooperationsvertrages ',
    'menus.masters': 'Konfigurationen',
    'menus.specializations': 'Fachrichtungen',
    'menus.vauchers': 'Gutscheine',
    'menus.companies': 'Unternehmen',
    'menus.codes': 'Codes',
    'menus.other': 'Andere',
    'menus.journals': 'Journale',
    'menus.organization': 'Organisation',
    'menus.users': 'Benutzer',

    'enums.countries.DE': 'Deutschland',
    'enums.statuses.ENROLLED': 'Freigegeben',
    'enums.statuses.NEW': 'Neu',
    'enums.statuses.REJECTED': 'Abgelehnt',
    'enums.statuses.SUBMITTED': 'Eingereicht',
    'enums.statuses.SUSPENDED': 'Suspendiert',
    'enums.statuses.UNDERREVIEW': 'Wird geprüft',
    'enums.hcpTypes.PHYSICIAN': 'Arzt',
    'enums.hcpTypes.MEDICALASSISTANT': 'Medizinischer Assistent',
    'enums.hcpTypes.EMPLOYEDPHYSICIAN': 'Angestellter Arzt',
    'enums.hcpTypes.ADMINISTRATOR': 'Verwalter',
    'enums.hcpTitles.DR': 'Dr.',
    'enums.hcpTitles.PROF': 'Prof.',
    'enums.hcpTitles.OTHER': 'Andere',
    'enums.actions.CREATED': 'Erstellt',
    'enums.actions.UPDATED': 'Aktualisiert',
    'enums.actions.DELETED': 'Gelöscht',
    'enums.roles.ADMIN': 'Administrator',
    'enums.roles.SUPPORT': 'Support',
    'enums.roles.FINANCE': 'Finanzen',
    'enums.roles.TECHNICAL': 'Techniker',
    'enums.legalEntity.GMBH': 'GbmH',
    'enums.legalEntity.GBR': 'GbR',
    'enums.legalEntity.GMBH_COKG': 'GmbH & Co.KG',
    'enums.legalEntity.OHG': 'OHG',
    'enums.legalEntity.UG': 'UG',
    'enums.legalEntity.KG': 'KG',
    'enums.legalEntity.AG': 'AG',
    'enums.legalEntity.PARTG': 'PartG',
    'enums.legalEntity.PARTG_MBB': 'PartG mbB',
    'enums.legalEntity.KGAA': 'KGAA',
    'enums.legalEntity.EK': 'EK',
    'enums.leadTimes.IMMEDIATE': 'Sofortig',
    'enums.leadTimes.NEXT_WEEKEND': 'Nächstes Wochenende',
    'enums.leadTimes.SEVEN_DAYS_WEEKEND': 'Wochenende nach 7 Tagen',
    'enums.purchaseTypes.STANDARD': 'Standard',
    'enums.purchaseTypes.WEEKEND': 'Wochenende',
    'enums.pointTypes.STANDARD': 'Standard',
    'enums.pointTypes.WEEKEND': 'Wochenende',
    'enums.currencies.EUR': '€',
    'enums.currencies.RSD': 'RSD',
    'enums.redeemStatuses.NEW': 'Neu',
    'enums.redeemStatuses.ACTIVE': 'Aktiv',
    'enums.redeemStatuses.USED': 'Eingelöst',
    'enums.redeemStatuses.REVOKED': 'Widerrufen',
    'enums.languages.DE': 'Deutsch',
    'enums.languages.EN': 'English',
    'enums.reportStatuses.NEW': 'Neu',
    'enums.reportStatuses.CLOSED': 'Geschlossen',
    'enums.reportStatuses.INPROGRESS': 'in Bearbeitung',
    'enums.reportReasons.COPYRIGHT': 'Urheberrechte',
    'enums.reportReasons.HARASSMENT': 'Belästigung',
    'enums.reportReasons.SPAM': 'Spam',
    'enums.reportReasons.FRAUD': 'Betrug',
    'enums.reportReasons.MISC': 'Sonstiges',
    'enums.transferStatuses.NEW': 'Neu',
    'enums.transferStatuses.EXPORTED': 'Exportiert',
    'enums.transferStatuses.DONE': 'Erledigt',
    'enums.platforms.IOS': 'Nur iOS',
    'enums.platforms.ANDROID': 'Nur Android',
    'enums.platforms.WEB': 'Erledigt',
    'enums.devices.PHONE': 'Nur Phones',
    'enums.devices.TABLET': 'Nur Tablets',
    'enums.devices.LAPTOP': 'Nur Laptops',
    'enums.bookingStatus.OK': 'Ok',
    'enums.bookingStatus.CANCELED': 'Abgesagt',
    'enums.bookingStatus.POSTPONED': 'Verschoben',
    'enums.delayReasons.EMERGENCY': 'Notfall',
    'enums.delayReasons.OPERATION': 'Operation',
    'enums.delayReasons.OTHER': 'Andere',

    'tooltips.clear': 'Klar',

    'imprint.message.success':
      'Das Impressum wurde erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, das neue Impressum zu ziehen und darzustellen',
    'privacy.message.success':
      'Die Datenschutzerklärung wurde erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neue Datenschutzerklärung zu ziehen und darzustellen',
    'tos.message.success':
      'Die Nutzungsbedingungen wurde erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neuen Nutzungsbedingungen zu ziehen und darzustellen',
    'faq.message.success':
      'Die FAQ wurde erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neue FAQ zu ziehen und darzustellen',
    'cooperativeAgreements.message.success':
      'Die Kooperationsvertrages wurde erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neue Kooperationsvertrages  zu ziehen und darzustellen',

    'markdown.message.fetch.error':
      'Es ist ein technischer Fehler aufgetreten. Bitte laden Sie die Seite neu oder wenden Sie sich an den Support, falls der Fehler weiterhin besteht.',
    'markdown.message.save.error':
      'Es ist ein technischer Fehler aufgetreten. Bitte speichern Sie erneut oder wenden Sie sich an den Support, falls der Fehler weiterhin besteht.',

    'dashboard.chart.yaxis.label': 'Anzahl',
    'dashboard.chart.countOfBookings.name': 'Buchungen',
    'dashboard.chart.countOfLots.name': 'Sprechstunden',
    'dashboard.header': 'Die nächsten Schritte',
    'dashboard.period.label': 'Entwicklung seit',
    'dashboard.period': 'Zeitraum',
    'dashboard.period.lastSevenDays': 'Letzten 7 Tage',
    'dashboard.period.lastTwoWeeks': 'Letzte 2 Wochen',
    'dashboard.period.custom': 'Benutzerdefiniert',
    'dashboard.grid.1.title': 'Buchungen / Srechstunden',
    'dashboard.grid.1.subtitle': 'Verwalten Sie einfach Praxen',
    'dashboard.grid.1.action': 'Praxen öffnen',
    'dashboard.grid.1.button.menuIndex': '1',
    'dashboard.grid.1.button.entriesIndex': '0',
    'dashboard.grid.2.title': 'Ärzte/-innen',
    'dashboard.grid.2.firstLine': 'Anzahl Ärzte gesamt: ##number##',
    'dashboard.grid.2.secondLine': 'Neuanmeldungen: ##number##',
    'dashboard.grid.3.title': 'Buchungen',
    'dashboard.grid.3.firstLine': 'Anzahl Buchungen gesamt: ##number##',
    'dashboard.grid.3.secondLine': 'neue Buchungen: ##number##',

    // Practices
    'practices.allTitle': 'Alle Praxen',
    'practices.title': 'Praxen',
    'practices.onboardingTitle': 'Onboarding Praxen',
    'practices.id': 'ID',
    'practices.number': 'Nummer',
    'practices.status': 'Status',
    'practices.legalEntity': 'Rechtsform',
    'practices.name': 'Name',
    'practices.web': 'Webseite',
    'practices.email': 'Email',
    'practices.phone': 'Telefon',
    'practices.locations': 'Standorte',
    'practices.bankAccounts': 'Bankverbindungen',
    'practices.hcps': 'Ärzte/-innen',
    'practices.workload': 'Auslastung',
    'practices.fraudScore': 'Betrugsverdacht',
    'practices.equipments': 'Ausstattungen',
    'practices.equipments.label': 'Ausstattungen',
    'practices.photos': 'Fotos',
    'practices.lots.pagination.title': 'Sprechstunde',
    'practices.fees.pagination.title': 'Gebühren',
    'practices.invoices.pagination.title': 'Rechnungen',
    'practices.transfers.pagination.title': 'Überweisungen',
    'practices.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'practices.message.onRowUpdate.success': 'Praxis wurde erfolgreich angepasst',
    'practices.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'practices.message.onRowAdd.success': 'Praxis wurde erfolgreich angelegt',
    'practices.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'practices.message.onRowDelete.success': 'Praxis wurde erfolgreich gelöscht',
    'practices.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'practices.actions.toEnroll': 'Einschreiben',
    'practices.actions.toReject': 'Ablehnen',
    'practices.actions.toReview': 'Überprüfen',

    // Locations
    'locations.title': 'Standorte',
    'locations.id': 'ID',
    'locations.bsnr': 'BSNR',
    'locations.street': 'Straße',
    'locations.zipCode': 'Postleitzahl',
    'locations.geoPosition.lat': 'Breite',
    'locations.geoPosition.long': 'Längengrad',
    'locations.geoPosition.hash': 'Geohash',
    'locations.city': 'Stadt',
    'locations.city.label': 'Stadt',
    'locations.country': 'Land',
    'locations.practice': 'Praxis',
    'locations.practice.label': 'Praxis',
    'locations.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'locations.message.onRowUpdate.success': 'Standort wurde erfolgreich angepasst',
    'locations.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'locations.message.onRowAdd.success': 'Standort wurde erfolgreich angelegt',
    'locations.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'locations.message.onRowDelete.success': 'Standort wurde erfolgreich gelöscht',
    'locations.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Cities
    'cities.title': 'Städte',
    'cities.id': 'ID',
    'cities.name.de': 'Name (Deutsch)',
    'cities.name.en': 'Name (English)',
    'cities.geoPosition.lat': 'Breite',
    'cities.geoPosition.long': 'Längengrad',
    'cities.geoPosition.hash': 'Geohash',
    'cities.country': 'Land',
    'cities.available': 'Verfügbar',
    'cities.locations': 'Standorte',
    'cities.pagination.title': 'Alle Standorte der Stadt',
    'cities.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'cities.message.onRowUpdate.success': 'Stadt wurde erfolgreich angepasst',
    'cities.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'cities.message.onRowAdd.success': 'Stadt wurde erfolgreich angelegt',
    'cities.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'cities.message.onRowDelete.success': 'Stadt wurde erfolgreich gelöscht',
    'cities.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // BankAccounts
    'bankAccounts.title': 'Bankverbindungen',
    'bankAccounts.id': 'ID',
    'bankAccounts.owner': 'Inhaber',
    'bankAccounts.bic': 'BIC',
    'bankAccounts.iban': 'IBAN',
    'bankAccounts.institute': 'Institut',
    'bankAccounts.practice': 'Praxis',
    'bankAccounts.practice.label': 'Praxis',
    'bankAccounts.hcps': 'Ärzte/-innen',
    'bankAccounts.transfers.pagination.title': 'Überweisungen',
    'bankAccounts.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'bankAccounts.message.onRowUpdate.success': 'Bankverbindungen wurde erfolgreich angepasst',
    'bankAccounts.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'bankAccounts.message.onRowAdd.success': 'Bankverbindungen wurde erfolgreich angelegt',
    'bankAccounts.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'bankAccounts.message.onRowDelete.success': 'Bankverbindungen wurde erfolgreich gelöscht',
    'bankAccounts.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // HCPS
    'hcps.table.title': 'Ärzte/-innen',
    'hcps.id': 'ID',
    'hcps.number': 'Nummer',
    'hcps.status': 'Status',
    'hcps.type': 'Art',
    'hcps.title': 'Titel',
    'hcps.firstname': 'Vorname',
    'hcps.lastname': 'Familienname',
    'hcps.lanr': 'LANR',
    'hcps.avgReview': 'Durchschnittlich Review',
    'hcps.fraudScore': 'Betrugsverdacht',
    'hcps.practices': 'Praxen',
    'hcps.practices.label': 'Praxen',
    'hcps.bankAccounts': 'Bankverbindungen',
    'hcps.bankAccounts.label': 'Bankverbindungen',
    'hcps.lots': 'Sprechstunde',
    'hcps.specializations': 'Fachrichtungen',
    'hcps.specializations.label': 'Fachrichtungen',
    'hcps.qualifications': 'QS-Leistungen',
    'hcps.qualifications.label': 'QS-Leistungen',
    'hcps.contractlevel': 'Vertragsstufe',
    'hcps.contractlevel.label': 'Vertragsstufe',
    'hcps.photo': 'Foto',
    'hcps.resetPassword.label': 'Passwort zurücksetzen',
    'hcps.resetPassword.success': 'E-Mail zum Zurücksetzen des Passworts wird gesendet',
    'hcps.resetPassword.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'hcps.capacities.pagination.title': 'Alle Kapazitäten der Ärzte/-innen',
    'hcps.lots.pagination.title': 'Alle Sprechstunde der Ärzte/-innen',
    'hcps.reviews.pagination.title': 'Alle Reviews der Ärzte/-innen',
    'hcps.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'hcps.message.onRowUpdate.success': 'Ärzte/-innen wurde erfolgreich angepasst',
    'hcps.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'hcps.message.onRowAdd.success': 'Ärzte/-innen wurde erfolgreich angelegt',
    'hcps.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'hcps.message.onRowDelete.success': 'Ärzte/-innen wurde erfolgreich gelöscht',
    'hcps.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'hcps.actions.toEnroll': 'Einschreiben',
    'hcps.actions.toReject': 'Ablehnen',
    'hcps.actions.toReview': 'Überprüfen',

    // Specializations
    'specializations.title': 'Fachrichtungen',
    'specializations.id': 'ID',
    'specializations.label.de': 'DE',
    'specializations.label.en': 'EN',
    'specializations.hcps': 'Ärzte/-innen',
    'specializations.qualifications': 'QS-Leistungen',
    'specializations.qualifications.label': 'QS-Leistungen',
    'specializations.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'specializations.message.onRowUpdate.success': 'Spezialisierung wurde erfolgreich angepasst',
    'specializations.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'specializations.message.onRowAdd.success': 'Spezialisierung wurde erfolgreich angelegt',
    'specializations.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'specializations.message.onRowDelete.success': 'Spezialisierung wurde erfolgreich gelöscht',
    'specializations.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'specializations.pagination.cancel': 'Schliessen',
    'specializations.pagination.title': 'Alle Ärzte/-innen der Spezialisierung',

    // Equipments
    'equipments.title': 'Ausrüstungen',
    'equipments.id': 'ID',
    'equipments.label.de': 'DE',
    'equipments.label.en': 'EN',
    'equipments.practices': 'Praxen',
    'equipments.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'equipments.message.onRowUpdate.success': 'Ausrüstung wurde erfolgreich angepasst',
    'equipments.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'equipments.message.onRowAdd.success': 'Ausrüstung wurde erfolgreich angelegt',
    'equipments.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'equipments.message.onRowDelete.success': 'Ausrüstung wurde erfolgreich gelöscht',
    'equipments.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'equipments.pagination.cancel': 'Schliessen',
    'equipments.pagination.title': 'Alle Praxen der Ausrüstungen',

    // Qualifications
    'qualifications.title': 'QS-Leistungen',
    'qualifications.id': 'ID',
    'qualifications.label.de': 'DE',
    'qualifications.label.en': 'EN',
    'qualifications.specialization': 'Spezialisierung',
    'qualifications.specialization.label': 'Spezialisierung',
    'qualifications.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'qualifications.message.onRowUpdate.success': 'QS-Leistungen wurde erfolgreich angepasst',
    'qualifications.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'qualifications.message.onRowAdd.success': 'QS-Leistungen wurde erfolgreich angelegt',
    'qualifications.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'qualifications.message.onRowDelete.success': 'QS-Leistungen wurde erfolgreich gelöscht',
    'qualifications.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'qualifications.pagination.cancel': 'Schliessen',
    'qualifications.pagination.title': 'Alle Ärzte/-innen der QS-Leistungen',

    // Contractlevel
    'contractlevels.title': 'Vertragsstufen',
    'contractlevels.id': 'ID',
    'contractlevels.label': 'Name',
    'contractlevels.tooltip.de': 'Tooltip DE',
    'contractlevels.tooltip.en': 'Tooltip EN',
    'contractlevels.fee': 'Gebühren',
    'contractlevels.maxMin': 'Max in Min.',
    'contractlevels.lotSizeMin': 'Losgröße in Min.',
    'contractlevels.bookingSizeMin': 'Buchungsgröße in Min.',
    'contractlevels.minWeekAmount': 'Min. Woche',
    'contractlevels.maxWeekAmount': 'Max. Woche',
    'contractlevels.leadTime': 'Vorlaufzeit',
    'contractlevels.weeks': 'Wochen',
    'contractlevels.capacityPerWeek': 'Kapazität pro Woche',
    'contractlevels.constraints': 'Einschränkungen',
    'contractlevels.constraints.label': 'Einschränkungen',
    'contractlevels.hcps': 'Ärzte/-innen',
    'contractlevels.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'contractlevels.message.onRowUpdate.success': 'Vertragsstufe wurde erfolgreich angepasst',
    'contractlevels.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'contractlevels.message.onRowAdd.success': 'Vertragsstufe wurde erfolgreich angelegt',
    'contractlevels.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'contractlevels.message.onRowDelete.success': 'Vertragsstufe wurde erfolgreich gelöscht',
    'contractlevels.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'contractlevels.pagination.cancel': 'Schliessen',
    'contractlevels.pagination.title': 'Alle Ärzte/-innen der Vertragsstufe',
    'contractlevels.fees.pagination.title': 'Alle Gebühren der Vertragsstufe',

    // Constraints
    'constraints.title': 'Einschränkungen',
    'constraints.id': 'ID',
    'constraints.label': 'Name',
    'constraints.weekdays': 'Wochentags',
    'constraints.holidays': 'Ferien',
    'constraints.saturdays': 'Samstags',
    'constraints.sundays': 'Sonntags',
    'constraints.starts': 'Start',
    'constraints.ends': 'Ende',
    'constraints.contractlevels': 'Vertragsstufen',
    'constraints.contractlevels.label': 'Vertragsstufen',
    'constraints.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'constraints.message.onRowUpdate.success': 'Einschränkung wurde erfolgreich angepasst',
    'constraints.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'constraints.message.onRowAdd.success': 'Einschränkung wurde erfolgreich angelegt',
    'constraints.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'constraints.message.onRowDelete.success': 'Einschränkung wurde erfolgreich gelöscht',
    'constraints.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Photos
    'photos.title': 'Fotos',
    'photos.id': 'ID',
    'photos.thumbnail': 'Miniaturansicht',
    'photos.fraudScore': 'Betrugsverdacht',
    'photos.practice': 'Praxis',
    'photos.practice.label': 'Praxis',
    'photos.hcp': 'Arzt/-in',
    'photos.hcp.label': 'Arzt/-in',
    'photos.patient': 'Patiente',
    'photos.patient.label': 'Patiente',
    'photos.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'photos.message.onRowUpdate.success': 'Foto wurde erfolgreich angepasst',
    'photos.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'photos.message.onRowAdd.success': 'Foto wurde erfolgreich angelegt',
    'photos.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'photos.message.onRowDelete.success': 'Foto wurde erfolgreich gelöscht',
    'photos.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Lots
    'lots.title': 'Sprechstunde',
    'lots.id': 'ID',
    'lots.starts': 'Start',
    'lots.ends': 'Ende',
    'lots.practice': 'Praxis',
    'lots.practice.label': 'Praxis',
    'lots.location': 'Standort',
    'lots.location.label': 'Standort',
    'lots.hcp': 'Arzt/-in',
    'lots.hcp.label': 'Arzt/-in',
    'lots.bookings': 'Buchungen',
    'lots.bookings.label': 'Buchungen',
    'lots.fee': 'Gebühr',
    'lots.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'lots.message.onRowUpdate.success': 'Sprechstunde wurde erfolgreich angepasst',
    'lots.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'lots.message.onRowAdd.success': 'Sprechstunde wurde erfolgreich angelegt',
    'lots.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'lots.message.onRowDelete.success': 'Sprechstunde wurde erfolgreich gelöscht',
    'lots.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Patients
    'patients.title': 'Patienten',
    'patients.id': 'ID',
    'patients.firstname': 'Vorname',
    'patients.lastname': 'Familienname',
    'patients.phone': 'Telefon',
    'patients.birthday': 'Geburtstag',
    'patients.fraudScore': 'Betrugsverdacht',
    'patients.bookings': 'Buchungen',
    'patients.bookings.label': 'Buchungen',
    'patients.reviews': 'Reviews',
    'patients.reviews.label': 'Reviews',
    'patients.purchases': 'In-App-Käufe',
    'patients.points': 'Buchungspunkten',
    'patients.photo': 'Foto',
    'patients.hcps.pagination.title': 'Ärzte/-innen',
    'patients.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'patients.message.onRowUpdate.success': 'Patient wurde erfolgreich angepasst',
    'patients.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'patients.message.onRowAdd.success': 'Patient wurde erfolgreich angelegt',
    'patients.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'patients.message.onRowDelete.success': 'Patient wurde erfolgreich gelöscht',
    'patients.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Bookings
    'bookings.title': 'Buchungen',
    'bookings.id': 'ID',
    'bookings.starts': 'Start',
    'bookings.ends': 'Ende',
    'bookings.created': 'Zeitstempel',
    'bookings.status': 'Status',
    'bookings.cancelable': 'Stornierbar',
    'bookings.didNotCome': 'Nicht gekommen',
    'bookings.delayReason': 'Verzögerungsgrund',
    'bookings.delayTimeMin': 'Verzögerungszeit',
    'bookings.isForAnother': 'Ist für einen anderen',
    'bookings.anotherFirstname': 'Ein anderer Vorame',
    'bookings.anotherLastname': 'Ein anderer Familienname',
    'bookings.anotherBirthday': 'Ein weiterer Geburtstag',
    'bookings.specialization': 'Spezialisierung',
    'bookings.specialization.label': 'Spezialisierung',
    'bookings.lot': 'Sprechstunde',
    'bookings.lot.label': 'Sprechstunde',
    'bookings.patient': 'Patient',
    'bookings.patient.label': 'Patient',
    'bookings.review': 'Review',
    'bookings.review.label': 'Review',
    'bookings.point': 'Buchungspunkt',
    'bookings.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'bookings.message.onRowUpdate.success': 'Buchung wurde erfolgreich angepasst',
    'bookings.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'bookings.message.onRowAdd.success': 'Buchung wurde erfolgreich angelegt',
    'bookings.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'bookings.message.onRowDelete.success': 'Buchung wurde erfolgreich gelöscht',
    'bookings.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Reviews
    'reviews.title': 'Reviews',
    'reviews.id': 'ID',
    'reviews.rating': 'Bewertung',
    'reviews.description': 'Beschreibung',
    'reviews.anonymous': 'Anonym',
    'reviews.fraudScore': 'Betrugsverdacht',
    'reviews.booking': 'Buchung',
    'reviews.booking.label': 'Buchung',
    'reviews.patient': 'Patient',
    'reviews.patient.label': 'Patient',
    'reviews.hcp': 'Arzt/-in',
    'reviews.hcp.label': 'Arzt/-in',
    'reviews.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'reviews.message.onRowUpdate.success': 'Review wurde erfolgreich angepasst',
    'reviews.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'reviews.message.onRowAdd.success': 'Review wurde erfolgreich angelegt',
    'reviews.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'reviews.message.onRowDelete.success': 'Review wurde erfolgreich gelöscht',
    'reviews.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Purchases
    'purchases.title': 'In-App-Käufe',
    'purchases.id': 'Schlüssel',
    'purchases.type': 'Art',
    'purchases.amount': 'Netto',
    'purchases.date': 'Datum',
    'purchases.estimatedTransfer': 'Überweisungen Datum',
    'purchases.patient': 'Patient',
    'purchases.patient.label': 'Patient',
    'purchases.point': 'Buchungspunkten',
    'purchases.point.label': 'Buchungspunkten',
    'purchases.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'purchases.message.onRowUpdate.success': 'Käufe wurde erfolgreich angepasst',
    'purchases.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'purchases.message.onRowAdd.success': 'Käufe wurde erfolgreich angelegt',
    'purchases.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'purchases.message.onRowDelete.success': 'Käufe wurde erfolgreich gelöscht',
    'purchases.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Points
    'points.title': 'Buchungspunkten',
    'points.id': 'ID',
    'points.used': 'Benutzt',
    'points.type': 'Art',
    'points.patient': 'Patient',
    'points.patient.label': 'Patient',
    'points.purchase': 'Käufe',
    'points.purchase.label': 'Käufe',
    'points.booking': 'Buchung',
    'points.booking.label': 'Buchung',
    'points.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'points.message.onRowUpdate.success': 'Buchungspunkt wurde erfolgreich angepasst',
    'points.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'points.message.onRowAdd.success': 'Buchungspunkt wurde erfolgreich angelegt',
    'points.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'points.message.onRowDelete.success': 'Buchungspunkt wurde erfolgreich gelöscht',
    'points.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Fees
    'fees.title': 'Gebühren',
    'fees.id': 'ID',
    'fees.amount': 'Brutto',
    'fees.netAmount': 'Netto-Betrag',
    'fees.vat': 'Mehrwertsteuer',
    'fees.date': 'Datum',
    'fees.estimatedTransfer': 'Überweisung Datum',
    'fees.contractlevel': 'Vertragsstufe',
    'fees.contractlevel.label': 'Vertragsstufe',
    'fees.practice': 'Praxis',
    'fees.practice.label': 'Praxis',
    'fees.hcp': 'Arzt/-in',
    'fees.hcp.label': 'Arzt/-in',
    'fees.lot': 'Sprechstunde',
    'fees.lot.label': 'Sprechstunde',
    'fees.invoice': 'Rechnung',
    'fees.invoice.label': 'Rechnung',
    'fees.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'fees.message.onRowUpdate.success': 'Gebühr wurde erfolgreich angepasst',
    'fees.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'fees.message.onRowAdd.success': 'Gebühr wurde erfolgreich angelegt',
    'fees.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'fees.message.onRowDelete.success': 'Gebühr wurde erfolgreich gelöscht',
    'fees.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Invoices
    'invoices.title': 'Rechnungen',
    'invoices.id': 'ID',
    'invoices.number': 'Rechnungsnummer',
    'invoices.amount': 'Brutto',
    'invoices.netAmount': 'Netto-Betrag',
    'invoices.vat': 'Mehrwertsteuer',
    'invoices.date': 'Datum',
    'invoices.practice': 'Praxis',
    'invoices.practice.label': 'Praxis',
    'invoices.hcp': 'Arzt/-in',
    'invoices.hcp.label': 'Arzt/-in',
    'invoices.transfer': 'Überweisung',
    'invoices.transfer.label': 'Überweisung',
    'invoices.fees': 'Gebühren',
    'invoices.downloadAction': 'Herunterladen',
    'invoices.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'invoices.message.onRowUpdate.success': 'Rechnung wurde erfolgreich angepasst',
    'invoices.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'invoices.message.onRowAdd.success': 'Rechnung wurde erfolgreich angelegt',
    'invoices.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'invoices.message.onRowDelete.success': 'Rechnung wurde erfolgreich gelöscht',
    'invoices.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Transfers
    'transfers.title': 'Überweisungen',
    'transfers.id': 'ID',
    'transfers.date': 'Datum',
    'transfers.status': 'Status',
    'transfers.practice': 'Praxis',
    'transfers.practice.label': 'Praxis',
    'transfers.hcp': 'Arzt/-in',
    'transfers.hcp.label': 'Arzt/-in',
    'transfers.bankAccount': 'Bankverbindung',
    'transfers.bankAccount.label': 'Bankverbindung',
    'transfers.invoice': 'Rechnung',
    'transfers.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'transfers.message.onRowUpdate.success': 'Überweisung wurde erfolgreich angepasst',
    'transfers.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'transfers.message.onRowAdd.success': 'Überweisung wurde erfolgreich angelegt',
    'transfers.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'transfers.message.onRowDelete.success': 'Überweisung wurde erfolgreich gelöscht',
    'transfers.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'transfers.message.export.error': 'Export Fehler',
    'transfers.message.export.emptyArray': 'Es gibt keine zu exportierenden Überweisungen',

    // Finance Report
    'financeReports.chart.inAppPurchasesInEUR.name': 'Einnahmen',
    'financeReports.chart.feesInEUR.name': 'Arzthonorar',
    'financeReports.period.label': 'Finanzen seit',
    'financeReports.period': 'Zeitraum',
    'financeReports.period.lastSevenDays': 'Letzten 7 Tage',
    'financeReports.period.lastTwoWeeks': 'Letzten 2 Wochen',
    'financeReports.period.custom': 'Benutzerdefiniert',
    'financeReports.header': 'Finanzbericht',
    'financeReports.grid.1.text': 'Einnahmen: ##number##€',
    'financeReports.grid.2.text': 'Arzthonorar: ##number##€',
    'financeReports.grid.3.text': 'Gewinn: ##number##€',

    // Review Report
    'reviewReports.chart.oneStarsRatings.name': '1 Stern',
    'reviewReports.chart.twoStarsRatings.name': '2 Sterne',
    'reviewReports.chart.threeStarsRatings.name': '3 Sterne',
    'reviewReports.chart.fourStarsRatings.name': '4 Sterne',
    'reviewReports.chart.fiveStarsRatings.name': '5 Sterne',
    'reviewReports.period.label': 'Bewertungsbericht seit',
    'reviewReports.period': 'Zeitraum',
    'reviewReports.period.lastSevenDays': 'Letzten 7 Tage',
    'reviewReports.period.lastTwoWeeks': 'Letzten 2 Wochen',
    'reviewReports.period.custom': 'Benutzerdefiniert',
    'reviewReports.header': 'Bewertungsbericht',
    'reviewReports.grid.1.text': 'Durchschnittliche Bewertung',

    // Workload Report
    'workloadReports.chart.lotsInMinutes.name': 'Sprechstunden in Min.',
    'workloadReports.chart.bookingsInMinutes.name': 'Buchungen in Min.',
    'workloadReports.period.label': 'Auslastungsbericht seit',
    'workloadReports.period': 'Zeitraum',
    'workloadReports.period.lastSevenDays': 'Letzten 7 Tage',
    'workloadReports.period.lastTwoWeeks': 'Letzten 2 Wochen',
    'workloadReports.period.custom': 'Benutzerdefiniert',

    // Text
    'texts.title': 'Texte',
    'texts.id': 'ID',
    'texts.label.de': 'Text (Deutsch)',
    'texts.label.en': 'Text (English)',
    'texts.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'texts.message.onRowUpdate.success':
      'Texte wurde erfolgreich zwischengespeichert. Bitte veröffentlichen Sie nun die Texte',
    'texts.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'texts.message.onRowAdd.success': 'Text wurde erfolgreich angelegt. Bitte veröffentlichen Sie nun die Texte.',
    'texts.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'texts.message.onRowDelete.success': 'Text wurde erfolgreich gelöscht. Bitte veröffentlichen Sie nun die Texte',
    'texts.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'texts.publish.tooltip': 'Texte veröffentlichen',
    'texts.publish.success':
      'Die Texte wurden erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neuen Texte zu ziehen und darzustellen',
    'texts.publish.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Phones
    'phones.title': 'Telefone',
    'phones.id': 'ID',
    'phones.name': 'Name',
    'phones.dialCode': 'Vorwahl',
    'phones.code': 'Code',
    'phones.latitude': 'Breite',
    'phones.longitude': 'Längengrad',
    'phones.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'phones.message.onRowUpdate.success':
      'Telefone wurde erfolgreich zwischengespeichert. Bitte veröffentlichen Sie nun die Telefone',
    'phones.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'phones.message.onRowAdd.success':
      'Telefone wurde erfolgreich angelegt. Bitte veröffentlichen Sie nun die Telefone.',
    'phones.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'phones.message.onRowDelete.success':
      'Telefone wurde erfolgreich gelöscht. Bitte veröffentlichen Sie nun die Telefone',
    'phones.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'phones.publish.tooltip': 'Telefone veröffentlichen',
    'phones.publish.success':
      'Die Telefone wurden erfolgreich aktualisiert. Die App wird etwas Zeit benötigen, die neuen Telefone zu ziehen und darzustellen',
    'phones.publish.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Master
    'masters.title': 'Konfigurationen',
    'masters.id': 'ID',
    'masters.key': 'Schlüssel',
    'masters.value': 'Wert',
    'masters.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'masters.message.onRowUpdate.success': 'Konfiguration wurde erfolgreich angepasst',
    'masters.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'masters.message.onRowAdd.success': 'Konfiguration wurde erfolgreich angelegt',
    'masters.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'masters.message.onRowDelete.success': 'Konfiguration wurde erfolgreich gelöscht',
    'masters.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Company
    'companies.title': 'Unternehmen',
    'companies.id': 'ID',
    'companies.number': 'Nummer',
    'companies.name': 'Name',
    'companies.legalEntity': 'Rechtsform',
    'companies.street': 'Straße',
    'companies.zip': 'Postleitzahl',
    'companies.city': 'Stadt',
    'companies.country': 'Land',
    'companies.codes': 'Codes',
    'companies.codes.label': 'Codes',
    'companies.pagination.title': 'Alle Codes der Unternehmen',
    'companies.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'companies.message.onRowUpdate.success': 'Unternehmen wurde erfolgreich angepasst',
    'companies.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'companies.message.onRowAdd.success': 'Unternehmen wurde erfolgreich angelegt',
    'companies.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'companies.message.onRowDelete.success': 'Unternehmen wurde erfolgreich gelöscht',
    'companies.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Code
    'codes.title': 'Codes',
    'codes.id': 'ID',
    'codes.label': 'Code',
    'codes.created': 'Zeitstempel',
    'codes.redeemStatus': 'Status',
    'codes.company': 'Unternehmen',
    'codes.company.label': 'Unternehmen',
    'codes.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'codes.message.onRowUpdate.success': 'Code wurde erfolgreich angepasst',
    'codes.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'codes.message.onRowAdd.success': 'Code wurde erfolgreich angelegt',
    'codes.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'codes.message.onRowDelete.success': 'Code wurde erfolgreich gelöscht',
    'codes.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Reports
    'reports.title': 'Berichte',
    'reports.id': 'ID',
    'reports.reporter': 'Gemeldet von',
    'reports.created': 'Zeitstempel',
    'reports.reason': 'Grund',
    'reports.comment': 'Kommentar',
    'reports.status': 'Status',
    'reports.hcp': 'Betrifft',
    'reports.hcp.label': 'Betrifft',
    'reports.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'reports.message.onRowUpdate.success': 'Bericht wurde erfolgreich angepasst',
    'reports.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'reports.message.onRowAdd.success': 'Bericht wurde erfolgreich angelegt',
    'reports.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'reports.message.onRowDelete.success': 'Bericht wurde erfolgreich gelöscht',
    'reports.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // UnwantedKeyword
    'unwantedKeywords.title': 'Unerwünschte Inhalte',
    'unwantedKeywords.id': 'ID',
    'unwantedKeywords.word': 'Wort',
    'unwantedKeywords.score': 'Ergebnis',
    'unwantedKeywords.created': 'Zeitstempel',
    'unwantedKeywords.languages': 'Sprachen',
    'unwantedKeywords.languages.label': 'Sprachen',
    'unwantedKeywords.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'unwantedKeywords.message.onRowUpdate.success': 'Unerwünschte Inhalte wurde erfolgreich angepasst',
    'unwantedKeywords.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'unwantedKeywords.message.onRowAdd.success': 'Unerwünschte Inhalte wurde erfolgreich angelegt',
    'unwantedKeywords.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'unwantedKeywords.message.onRowDelete.success': 'Unerwünschte Inhalte wurde erfolgreich gelöscht',
    'unwantedKeywords.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // UnwantedImages
    'unwantedImages.title': 'Unerwünschte Bilder',
    'unwantedImages.id': 'ID',
    'unwantedImages.phash': 'Phash',
    'unwantedImages.score': 'Ergebnis',
    'unwantedImages.comment': 'Kommentar',
    'unwantedImages.created': 'Zeitstempel',
    'unwantedImages.countries': 'Land',
    'unwantedImages.countries.label': 'Land',
    'unwantedImages.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'unwantedImages.message.onRowUpdate.success': 'Unerwünschte Bilder wurde erfolgreich angepasst',
    'unwantedImages.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'unwantedImages.message.onRowAdd.success': 'Unerwünschte Bilder wurde erfolgreich angelegt',
    'unwantedImages.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'unwantedImages.message.onRowDelete.success': 'Unerwünschte Bilder wurde erfolgreich gelöscht',
    'unwantedImages.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // SystemMessages
    'systemmessages.title': 'Systemmeldungen',
    'systemmessages.id': 'ID',
    'systemmessages.platform': 'Phash',
    'systemmessages.devices': 'Publikum (nach Gerät)',
    'systemmessages.os': 'Publikum (nach OS)',
    'systemmessages.version': 'Publikum (nach Version)',
    'systemmessages.title.en': 'Titel (Englisch)',
    'systemmessages.title.de': 'Titel (Deutsch)',
    'systemmessages.message.en': 'Nachricht (Englisch)',
    'systemmessages.message.de': 'Nachricht (Deutsch)',
    'systemmessages.validFrom': 'Gültig ab',
    'systemmessages.validUntil': 'Gültig bis',
    'systemmessages.error.validation':
      'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'systemmessages.message.onRowUpdate.success': 'Systemmeldung wurde erfolgreich angepasst',
    'systemmessages.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'systemmessages.message.onRowAdd.success': 'Systemmeldung wurde erfolgreich angelegt',
    'systemmessages.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'systemmessages.message.onRowDelete.success': 'Systemmeldung wurde erfolgreich gelöscht',
    'systemmessages.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Users
    'users.title': 'Benutzer',
    'users.id': 'ID',
    'users.email': 'Email',
    'users.displayName': 'Anzeigename',
    'users.roles': 'Rollen',
    'users.roles.label': 'Rollen',
    'users.resetPassword.label': 'Passwort zurücksetzen',
    'users.resetPassword.success': 'E-Mail zum Zurücksetzen des Passworts wird gesendet',
    'users.resetPassword.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'users.changeUserStatus.label.enable': 'Benutzer aktivieren',
    'users.changeUserStatus.label.disable': 'Benutzer deaktivieren',
    'users.changeUserStatus.action.enable.success': 'Benutzer wurde erfolgreich angepasst',
    'users.changeUserStatus.action.disable.success': 'Benutzer wurde erfolgreich angepasst',
    'users.changeUserStatus.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'users.error.validation': 'Es sind nicht alle Daten eingegegen oder Daten entsprechen nicht dem benötigten Format.',
    'users.message.onRowUpdate.success': 'Benutzer wurde erfolgreich angepasst',
    'users.message.onRowUpdate.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'users.message.onRowAdd.success': 'Benutzer wurde erfolgreich angelegt',
    'users.message.onRowAdd.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',
    'users.message.onRowDelete.success': 'Benutzer wurde erfolgreich gelöscht',
    'users.message.onRowDelete.error': 'Es ist ein Fehler aufgetreten. Beachten Sie die weiteren Hinweise',

    // Journals
    'journals.title': 'Journale',
    'journals.id': 'ID',
    'journals.collection': 'Sammlung',
    'journals.ref': 'Ref',
    'journals.action': 'Aktion',
    'journals.who': 'Who',
    'journals.created': 'Zeitstempel',
    'journals.oldData': 'Alt',
    'journals.newData': 'Neu',

    'general.pagination.cancel': 'Schliessen',

    'dateTimePicker.cancelLabel': 'Abbrechen',
    'dateTimePicker.okLabel': 'Übernehmen',
    'dateTimePicker.todayLabel': 'Heute',
    'dateTimePicker.starts': 'Start Datum',
    'dateTimePicker.ends': 'Ende Datum',
  },
};

export const materialTableLocalizationDE = {
  pagination: {
    labelDisplayedRows: '{from}-{to} von {count}',
    labelRowsSelect: 'Zeilen',
    labelRowsPerPage: 'Zeilen pro Seite:',
    firstTooltip: 'Erste Seite',
    previousTooltip: 'Vorherige Seite',
    lastTooltip: 'Letzte Seite',
    nextTooltip: 'Nächste Seite',
  },
  header: {
    actions: 'Aktionen',
  },
  body: {
    emptyDataSourceMessage: 'Keine Einträge gefunden',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      deleteText: 'Möchten Sie wirklich diesen Einträg löschen?',
    },
  },
  toolbar: {
    searchTooltip: 'Suchen',
    nRowsSelected: '{0} Zeilen ausgewählt',
    searchPlaceholder: 'Suchen',
    addRemoveColumns: 'Spalten hinzufügen oder entfernen',
    showColumnsTitle: 'Spalten anzeigen',
    exportName: 'Als CSV',
  },
};

intl.init({
  currentLocale: i18nConfig.locale,
  locales: {
    [i18nConfig.locale]: i18nConfig.messages,
  },
});

export const get = (id, values) => {
  if (intl.get(id)) {
    let text = intl.get(id);

    if (values) {
      for (const key of Object.keys(values)) {
        text = text.split(`##${key}##`).join(values[key]);
      }
    }

    return text;
  } else {
    return `$$${id}$$`;
  }
};

export const getMaterialTableLocalization = (custom) => {
  switch (intl.getInitOptions().currentLocale) {
    case 'de':
      return custom ? { ...materialTableLocalizationDE, ...custom } : { ...materialTableLocalizationDE };
    default:
      return custom ? { ...custom } : {};
  }
};
