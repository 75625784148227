import React, { useState } from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
  navButton: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#AAAAAA4d',
    top: 0,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#AAAAAA4d',
    },
  },
  prev: {
    left: 0,
  },
  next: {
    right: 0,
  },
});

export default function ImagesViewer(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);

  const handleImageClick = (photo) => {
    setIsOpened(true);
    setCurrentPhoto(photo);
  };

  const handleCloseAction = () => {
    setIsOpened(false);
    setCurrentPhoto(null);
  };

  const handleNextAction = () => {
    const currentIndex = props.photos.findIndex((photo) => photo.thumbnailUri === currentPhoto.thumbnailUri);
    const nextIndex = currentIndex === props.photos.length - 1 ? 0 : currentIndex + 1;
    setCurrentPhoto(props.photos[nextIndex]);
  };

  const handlePrevAction = () => {
    const currentIndex = props.photos.findIndex((photo) => photo.thumbnailUri === currentPhoto.thumbnailUri);
    const prevIndex = currentIndex === 0 ? props.photos.length - 1 : currentIndex - 1;
    setCurrentPhoto(props.photos[prevIndex]);
  };

  const classes = useStyles();

  return (
    <div>
      {props.photos.map((photo) => (
        <img
          key={photo.thumbnailUri}
          alt=""
          src={photo.thumbnailUri}
          width={60}
          onClick={() => {
            handleImageClick(photo);
          }}
        />
      ))}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpened}
        onClose={(e) => {
          handleCloseAction();
          e.stopPropagation();
        }}
      >
        <DialogContent>
          <Box position="relative">
            {props.photos.length > 1 && (
              <IconButton onClick={handlePrevAction} className={clsx(classes.navButton, classes.prev)}>
                <NavigateBeforeIcon fontSize="large" />
              </IconButton>
            )}
            {currentPhoto && <img alt="" loading="lazy" src={currentPhoto.originalUri} width={'100%'} />}
            {props.photos.length > 1 && (
              <IconButton onClick={handleNextAction} className={clsx(classes.navButton, classes.next)}>
                <NavigateNextIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
