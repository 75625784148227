import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import deepEqual from 'deep-equal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  labelEN: false,
  labelDE: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.textsOnboarding.defaults';

class Texts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (validator.isEmpty(data.label.de) || validator.isEmpty(data.label.en)) {
        throw new Error(i18n.get('texts.error.validation'));
      }

      const input = {
        en: data.label.en,
        de: data.label.de,
      };

      return input;
    } catch (error) {
      throw new Error(i18n.get('texts.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  componentDidMount() {
    this.fetchJSON();
  }

  fetchJSON = async () => {
    this.setState({ working: true }, async () => {
      try {
        const result = await graphql.clientWithToken(this.props.accessToken).query({
          query: gql`
            query {
              json: textsOnboarding
            }
          `,
          fetchPolicy: 'no-cache',
        });

        this.setState({
          data: result.data.json !== '' ? JSON.parse(result.data.json) : [],
          originalData: result.data.json !== '' ? JSON.parse(result.data.json) : [],
          working: false,
        });
      } catch (error) {
        this.showMessage(i18n.get('texts.message.fetch.error'), 'error', error.message);
        this.setState({ working: false });
      }
    });
  };

  saveJSON = async () => {
    this.setState({ working: true }, async () => {
      try {
        const result = await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            json: JSON.stringify(this.state.data),
          },
          mutation: gql`
            mutation updateOnboardingTexts($json: String) {
              json: updateOnboardingTexts(json: $json)
            }
          `,
        });
        this.setState({
          data: JSON.parse(result.data.json),
          originalData: JSON.parse(result.data.json),
          working: false,
        });
        this.showMessage(i18n.get('texts.publish.success'), 'success');
      } catch (error) {
        this.showMessage(i18n.get('texts.publish.error'), 'error', error.message);
        this.setState({ working: false });
      }
    });
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            // FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          isLoading={this.state.working}
          columns={[
            {
              title: i18n.get('texts.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: false,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('texts.label.en'),
              field: 'labelEN',
              hidden: this.default('labelEN'),
              sorting: true,
              filtering: false,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('texts.label.de'),
              field: 'labelDE',
              hidden: this.default('labelEN'),
              sorting: true,
              filtering: false,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
          ]}
          data={Object.keys(this.state.data).map((key) => {
            return { id: key, labelEN: this.state.data[key]['en'], labelDE: this.state.data[key]['de'] };
          })}
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]) &&
            !deepEqual(this.state.data, this.state.originalData) &&
            !this.state.working
              ? {
                  icon: CloudUploadIcon,
                  isFreeAction: true,
                  tooltip: i18n.get('texts.publish.tooltip'),
                  onClick: (event, rowData) => this.saveJSON(),
                }
              : null,
            // this.props.getJournalAction({ collection: 'texts' }),
          ]}
          title={i18n.get('texts.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]) && !this.state.working,
            isDeletable: false,
            onRowAdd: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                const data = { ...this.state.data };
                data[newData.id] = { de: newData['labelDE'], en: newData['labelEN'] };
                this.setState(
                  {
                    data: data,
                  },
                  () => {
                    this.showMessage(i18n.get('texts.message.onRowAdd.success'), 'success');
                    resolve();
                  }
                );
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                const data = { ...this.state.data };
                if (!data[newData.id]) {
                  reject();
                  this.showMessage(i18n.get('texts.message.onRowUpdate.error'), 'error');
                } else {
                  data[newData.id] = { de: newData['labelDE'], en: newData['labelEN'] };
                  this.setState(
                    {
                      data: data,
                    },
                    () => {
                      this.showMessage(i18n.get('texts.message.onRowUpdate.success'), 'success');
                      resolve();
                    }
                  );
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                const data = { ...this.state.data };
                delete data[oldData.id];
                this.setState(
                  {
                    data: data,
                  },
                  () => {
                    this.showMessage(i18n.get('texts.message.onRowDelete.success'), 'success');
                    resolve();
                  }
                );
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Texts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Texts)));
