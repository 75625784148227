import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ResetIcon from '@material-ui/icons/Cached';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';

import * as validator from 'validator';

import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { STATUSES, HCP_TYPES, HCP_TITLES, CURRENCIES } from '../common/enums';
import EventNoteIcon from '@material-ui/icons/EventNote';
import EuroIcon from '@material-ui/icons/Euro';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import PaginationDialog from '../components/PaginationDialog';
import moment from 'moment';
import ImagesViewer from '../components/ImagesViewer';
import StarIcon from '@material-ui/icons/Star';
import Rating from '@material-ui/lab/Rating';
import { STATUSES_COLORS } from '../common/colors';

const styles = (theme) => ({});

const defaults = {
  id: true,
  number: false,
  status: false,
  type: false,
  title: false,
  firstname: false,
  lastname: false,
  lanr: false,
  fraudScore: false,
  practices: false,
  bankAccounts: false,
  specializations: false,
  qualifications: false,
  contractlevel: false,
  photo: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.enroledHcps.defaults';

class Hcps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationLotsData = async (query) => {
    try {
      const where = `{column:"hcp", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            lots(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Lot {
                                  id
                                  starts {
                                    isoString
                                  }
                                  ends {
                                    isoString
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.lots.elements.map((element) => ({
          ...element,
          starts: element.starts.isoString ? moment(element.starts.isoString).toDate() : null,
          ends: element.ends.isoString ? moment(element.ends.isoString).toDate() : null,
        })),
        totalCount: result.data.lots.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationReviewsData = async (query) => {
    try {
      const where = `{column:"hcp", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            reviews(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Review {
                                  id
                                  rating
                                  description
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.reviews.elements,
        totalCount: result.data.reviews.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationFeesData = async (query) => {
    try {
      const where = `{column:"hcp", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            fees(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Fee {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.fees.elements,
        totalCount: result.data.fees.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationInvoicesData = async (query) => {
    try {
      const where = `{column:"hcp", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            invoices(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Invoice {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.invoices.elements,
        totalCount: result.data.invoices.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationTransfersData = async (query) => {
    try {
      const where = `{column:"hcp", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            transfers(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Transfer {
                                  id
                                  date {
                                    isoString
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.transfers.elements.map((element) => ({
          ...element,
          date: element.date.isoString ? moment(element.date.isoString).toDate() : null,
        })),
        totalCount: result.data.transfers.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.status) ||
        validator.isEmpty(data.type) ||
        validator.isEmpty(data.title) ||
        validator.isEmpty(data.firstname) ||
        validator.isEmpty(data.lastname) ||
        validator.isNumeric(toString(data.avgReview)) ||
        validator.isNumeric(toString(data.fraudScore)) ||
        validator.isEmpty(data.lanr)
      ) {
        throw new Error(i18n.get('hcps.error.validation'));
      }

      const input = {};

      helper.set(input, data, 'status');
      helper.set(input, data, 'type');
      helper.set(input, data, 'title');
      helper.set(input, data, 'firstname');
      helper.set(input, data, 'lastname');
      helper.set(input, data, 'lanr');
      helper.set(input, data, 'avgReview', parseFloat);
      helper.set(input, data, 'fraudScore', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('hcps.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'practices':
        return 'practices_search_string';
      case 'bankAccounts':
        return 'bankAccounts_search_string';
      case 'specializations':
        return 'specializations_search_string';
      case 'qualifications':
        return 'qualifications_search_string';
      case 'contractlevel':
        return 'contractlevel_ref';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getBankAccountsSuggestions = async (value) => {
    try {
      const where = `[{column: "iban", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bankAccounts(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on BankAccount {
                            id
                            iban
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bankAccounts.elements.map((element) => {
        return {
          name: `${element.iban}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBankAccountsSuggestions = () => {
    return [];
  };

  getSpecializationsSuggestions = async (value) => {
    try {
      const where = `[{column: "label.de", comparator: LT, string: "${value}~"},{column: "label.de", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                specializations(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Specialization {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.specializations.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultSpecializationsSuggestions = () => {
    return [];
  };

  getQualificationsSuggestions = async (value) => {
    try {
      const where = `[{column: "label.de", comparator: LT, string: "${value}~"},{column: "label.de", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                qualifications(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Qualification {
                            id,
                            label {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.qualifications.elements.map((element) => {
        return {
          name: `${element.label.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultQualificationsSuggestions = () => {
    return [];
  };

  getContractlevelsSuggestions = async (value) => {
    try {
      const where = `[{column: "label", comparator: LT, string: "${value}~"},{column: "label", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                contractlevels(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Contractlevel {
                            id
                            label
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.contractlevels.elements.map((element) => {
        return {
          name: `${element.label}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultContractlevelsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticesIds = newData.practices ? newData.practices.map((p) => (p.value ? p.value : p.id)) : [];
      const oldPracticesIds = oldData && oldData.practices ? oldData.practices.map((p) => p.id) : [];

      const addedPracticesIds = helper.diffArray(newPracticesIds, oldPracticesIds);

      for (const addedPracticeId of addedPracticesIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: addedPracticeId,
            hcpId: newData.id,
          },
          mutation: gql`
            mutation assignHCPToPractice($id: ID!, $hcpId: ID!) {
              assignHCPToPractice(id: $id, hcpId: $hcpId) {
                id
              }
            }
          `,
        });
      }

      // these I need to remove
      const removedPracticesIds = helper.diffArray(oldPracticesIds, newPracticesIds);

      for (const removedPracticeId of removedPracticesIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: removedPracticeId,
            hcpId: newData.id,
          },
          mutation: gql`
            mutation unassignHCPFromPractice($id: ID!, $hcpId: ID!) {
              unassignHCPFromPractice(id: $id, hcpId: $hcpId) {
                id
              }
            }
          `,
        });
      }

      const newBankAccountsIds = newData.bankAccounts
        ? newData.bankAccounts.map((p) => (p.value ? p.value : p.id))
        : [];
      const oldBankAccountsIds = oldData && oldData.bankAccounts ? oldData.bankAccounts.map((p) => p.id) : [];

      const addedBankAccountsIds = helper.diffArray(newBankAccountsIds, oldBankAccountsIds);

      for (const addedBankAccountId of addedBankAccountsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bankAccountId: addedBankAccountId,
          },
          mutation: gql`
            mutation assignBankAccountToHCP($id: ID!, $bankAccountId: ID!) {
              assignBankAccountToHCP(id: $id, bankAccountId: $bankAccountId) {
                id
              }
            }
          `,
        });
      }

      const removedBankAccountsIds = helper.diffArray(oldBankAccountsIds, newBankAccountsIds);

      for (const removedBankAccountId of removedBankAccountsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bankAccountId: removedBankAccountId,
          },
          mutation: gql`
            mutation unassignBankAccountFromHCP($id: ID!, $bankAccountId: ID!) {
              unassignBankAccountFromHCP(id: $id, bankAccountId: $bankAccountId) {
                id
              }
            }
          `,
        });
      }

      const newSpecializationsIds = newData.specializations
        ? newData.specializations.map((p) => (p.value ? p.value : p.id))
        : [];
      const oldSpecializationsIds = oldData && oldData.specializations ? oldData.specializations.map((p) => p.id) : [];

      const addedSpecializationsIds = helper.diffArray(newSpecializationsIds, oldSpecializationsIds);

      for (const addedSpecializationId of addedSpecializationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            specializationId: addedSpecializationId,
          },
          mutation: gql`
            mutation assignSpecializationToHCP($id: ID!, $specializationId: ID!) {
              assignSpecializationToHCP(id: $id, specializationId: $specializationId) {
                id
              }
            }
          `,
        });
      }

      const removedSpecializationsIds = helper.diffArray(oldSpecializationsIds, newSpecializationsIds);

      for (const removedSpecializationId of removedSpecializationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            specializationId: removedSpecializationId,
          },
          mutation: gql`
            mutation unassignSpecializationFromHCP($id: ID!, $specializationId: ID!) {
              unassignSpecializationFromHCP(id: $id, specializationId: $specializationId) {
                id
              }
            }
          `,
        });
      }

      const newQualificationsIds = newData.qualifications
        ? newData.qualifications.map((p) => (p.value ? p.value : p.id))
        : [];
      const oldQualificationsIds = oldData && oldData.qualifications ? oldData.qualifications.map((p) => p.id) : [];

      const addedQualificationsIds = helper.diffArray(newQualificationsIds, oldQualificationsIds);

      for (const addedQualificationId of addedQualificationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            qualificationId: addedQualificationId,
          },
          mutation: gql`
            mutation assignQualificationToHCP($id: ID!, $qualificationId: ID!) {
              assignQualificationToHCP(id: $id, qualificationId: $qualificationId) {
                id
              }
            }
          `,
        });
      }

      const removedQualificationsIds = helper.diffArray(oldQualificationsIds, newQualificationsIds);

      for (const removedQualificationId of removedQualificationsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            qualificationId: removedQualificationId,
          },
          mutation: gql`
            mutation unassignQualificationFromHCP($id: ID!, $qualificationId: ID!) {
              unassignQualificationFromHCP(id: $id, qualificationId: $qualificationId) {
                id
              }
            }
          `,
        });
      }

      const newContractlevelID =
        newData.contractlevel && newData.contractlevel.length > 0
          ? newData.contractlevel[0].value
          : newData.contractlevel
          ? newData.contractlevel.id
          : null;
      const oldContractlevelID = oldData && oldData.contractlevel ? oldData.contractlevel.id : null;

      if (newContractlevelID !== oldContractlevelID) {
        if (oldContractlevelID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              contractlevelId: oldContractlevelID,
            },
            mutation: gql`
              mutation unassignContractlevelFromHCP($id: ID!, $contractlevelId: ID!) {
                unassignContractlevelFromHCP(id: $id, contractlevelId: $contractlevelId) {
                  id
                }
              }
            `,
          });
        }

        if (newContractlevelID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newData.id,
              contractlevelId: newContractlevelID,
            },
            mutation: gql`
              mutation assignContractlevelToHCP($id: ID!, $contractlevelId: ID!) {
                assignContractlevelToHCP(id: $id, contractlevelId: $contractlevelId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('hcps.id'),
              field: 'id',
              hidden: this.default('id'),
              customSort: (a, b) => parseInt(a.id) - parseInt(b.id),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.number'),
              field: 'number',
              hidden: this.default('number'),
              editable: 'never',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.status'),
              field: 'status',
              hidden: this.default('status'),
              lookup: STATUSES,
              sorting: true,
              filtering: false,
              defaultFilter: ['ENROLLED'],
              render: (rowData) => {
                if (rowData) {
                  return rowData.status ? (
                    <Chip
                      label={`${STATUSES[rowData.status]}`}
                      style={{
                        backgroundColor: STATUSES_COLORS[rowData.status],
                        color: 'white',
                      }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.type'),
              field: 'type',
              hidden: this.default('type'),
              sorting: true,
              filtering: true,
              lookup: HCP_TYPES,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.title'),
              field: 'title',
              hidden: this.default('title'),
              sorting: true,
              filtering: true,
              lookup: HCP_TITLES,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.firstname'),
              field: 'firstname',
              hidden: this.default('firstname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.lastname'),
              field: 'lastname',
              hidden: this.default('lastname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.lanr'),
              field: 'lanr',
              hidden: this.default('lanr'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.avgReview'),
              field: 'avgReview',
              hidden: this.default('avgReview'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: helper.RatingFilter,
              render: (rowData) => {
                if (rowData.avgReview) {
                  return <Rating precision={0.1} value={rowData.avgReview} readOnly />;
                } else {
                  return '-';
                }
              },
              editComponent: (props) => {
                return (
                  <Rating
                    precision={0.1}
                    value={props.value === undefined ? '' : props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                  />
                );
              },
              comparator: 'LTE',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('practices.workload'),
              field: 'workload',
              hidden: this.default('workload'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 200,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.fraudScore'),
              field: 'fraudScore',
              hidden: this.default('fraudScore'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.practices'),
              field: 'practices',
              hidden: this.default('practices'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                if (rowData && rowData.practices) {
                  return rowData.practices ? rowData.practices.map((practice) => helper.practiceChip(practice)) : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: helper.practiceChip(v, true), value: v.id };
                      })
                    : [],
                  i18n.get('hcps.practices.label'),
                  null,
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.bankAccounts'),
              field: 'bankAccounts',
              hidden: this.default('bankAccounts'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.iban} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getBankAccountsSuggestions,
                this.getDefaultBankAccountsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.bankAccounts
                    ? rowData.bankAccounts.map((bankAccount) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.bankAccounts'), data: { id: bankAccount.id } }}
                          key={bankAccount.id}
                          label={`${bankAccount.iban} (${bankAccount.id})`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.iban} (${v.id})`, value: v.id };
                      })
                    : [],
                  i18n.get('hcps.bankAccounts.label'),
                  null,
                  this.getBankAccountsSuggestions,
                  this.getDefaultBankAccountsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.specializations'),
              field: 'specializations',
              hidden: this.default('specializations'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label.de} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getSpecializationsSuggestions,
                this.getDefaultSpecializationsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.specializations
                    ? rowData.specializations.map((specialization) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.specializations'), data: { id: specialization.id } }}
                          key={specialization.id}
                          label={`${specialization.label.de} (${specialization.id})`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label?.de} (${v.id})`, value: v.id };
                      })
                    : [],
                  i18n.get('hcps.specializations.label'),
                  null,
                  this.getSpecializationsSuggestions,
                  this.getDefaultSpecializationsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.qualifications'),
              field: 'qualifications',
              hidden: this.default('qualifications'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label.de} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getQualificationsSuggestions,
                this.getDefaultQualificationsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.qualifications
                    ? rowData.qualifications.map((qualification) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.qualifications'), data: { id: qualification.id } }}
                          key={qualification.id}
                          label={`${qualification.label.de} (${qualification.id})`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label?.de} (${v.id})`, value: v.id };
                      })
                    : [],
                  i18n.get('hcps.qualifications.label'),
                  null,
                  this.getQualificationsSuggestions,
                  this.getDefaultQualificationsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.contractlevel'),
              field: 'contractlevel',
              hidden: this.default('contractlevel'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.label} (${v.id})`,
                      value: v.id,
                    };
                  }),
                this.getContractlevelsSuggestions,
                this.getDefaultContractlevelsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.contractlevel ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.contractlevels'), data: { id: rowData.contractlevel.id } }}
                      key={rowData.contractlevel.id}
                      label={`${rowData.contractlevel.label} (${rowData.contractlevel.id})`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [{ name: `${props.value.label} (${props.value.id})`, value: props.value.id }]
                    : [],
                  i18n.get('hcps.contractlevel.label'),
                  i18n.get('required'),
                  this.getContractlevelsSuggestions,
                  this.getDefaultContractlevelsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('hcps.photo'),
              field: 'photo',
              hidden: this.default('photo'),
              sorting: false,
              filtering: false,
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData && rowData.photo?.thumbnail?.uri ? (
                    <ImagesViewer
                      photos={[
                        {
                          thumbnailUri: rowData.photo.thumbnail.uri,
                          originalUri: rowData.photo.original?.uri,
                        },
                      ]}
                    />
                  ) : (
                    ' - '
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'number', ['number']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                    query {
                      hcps(first: ${query.pageSize}, offset: ${
                    query.pageSize * query.page
                  }, orderBy:${orderBy}, where:${where}) {
                        totalCount
                        elements {
                          ... on HCP {
                            id
                            number
                            status
                            type
                            title
                            firstname
                            lastname
                            lanr
                            avgReview
                            workload
                            fraudScore
                            practices {id status name legalEntity}
                            bankAccounts {id iban}
                            specializations {
                              id
                              label {
                                de
                              }
                            }
                            qualifications {
                              id
                              label {
                                de
                              }
                            }
                            contractlevel {
                              id
                              label
                            }
                            photo {
                              thumbnail {
                                uri
                              }
                              original {
                                uri
                              }
                            }
                          }
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.hcps.elements.map((element) => {
                    return {
                      ...element,
                      workload: `${Math.round(element.workload * 100) / 100 || 0}%`,
                    };
                  }),
                  totalCount: result.data.hcps.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: EventNoteIcon,
              tooltip: i18n.get('menus.lots'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('lots.id'), field: 'id', hidden: true },
                    { title: i18n.get('lots.starts'), field: 'starts', type: 'datetime' },
                    { title: i18n.get('lots.ends'), field: 'ends', type: 'datetime' },
                  ],
                  paginationTitle: i18n.get('hcps.lots.pagination.title'),
                  fetchPaginationData: this.fetchPaginationLotsData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: StarIcon,
              tooltip: i18n.get('menus.reviews'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('reviews.id'), field: 'id', hidden: true },
                    {
                      title: i18n.get('reviews.rating'),
                      field: 'rating',
                      type: 'numeric',
                      render: (rowData) => {
                        return <Rating value={rowData.rating} readOnly />;
                      },
                    },
                    { title: i18n.get('reviews.description'), field: 'description' },
                  ],
                  paginationTitle: i18n.get('hcps.reviews.pagination.title'),
                  fetchPaginationData: this.fetchPaginationReviewsData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: EuroIcon,
              tooltip: i18n.get('menus.fees'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('fees.id'), field: 'id', hidden: true },
                    {
                      title: i18n.get('fees.amount'),
                      field: 'amount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.amount.float}${CURRENCIES[rowData.amount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('fees.netAmount'),
                      field: 'netAmount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.netAmount.float}${CURRENCIES[rowData.netAmount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('fees.vat'),
                      field: 'vat',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.vat.float}${CURRENCIES[rowData.vat.currency]}`}</span>;
                      },
                    },
                  ],
                  paginationTitle: i18n.get('practices.fees.pagination.title'),
                  fetchPaginationData: this.fetchPaginationFeesData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: ReceiptIcon,
              tooltip: i18n.get('menus.invoices'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('invoices.id'), field: 'id', hidden: true },
                    {
                      title: i18n.get('invoices.amount'),
                      field: 'amount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.amount.float}${CURRENCIES[rowData.amount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('invoices.netAmount'),
                      field: 'netAmount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.netAmount.float}${CURRENCIES[rowData.netAmount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('invoices.vat'),
                      field: 'vat',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.vat.float}${CURRENCIES[rowData.vat.currency]}`}</span>;
                      },
                    },
                  ],
                  paginationTitle: i18n.get('practices.invoices.pagination.title'),
                  fetchPaginationData: this.fetchPaginationInvoicesData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: SyncAltIcon,
              tooltip: i18n.get('menus.transfers'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('transfers.id'), field: 'id' },
                    {
                      title: i18n.get('transfers.date'),
                      field: 'date',
                      type: 'datetime',
                    },
                  ],
                  paginationTitle: i18n.get('practices.transfers.pagination.title'),
                  fetchPaginationData: this.fetchPaginationTransfersData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: ResetIcon,
              tooltip: i18n.get('hcps.resetPassword.label'),
              onClick: async (event, rowData) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: rowData.id,
                    },
                    mutation: gql`
                      mutation resetHCPPassword($id: ID!) {
                        resetHCPPassword(id: $id)
                      }
                    `,
                  });
                  if (result.data.resetHCPPassword) {
                    this.showMessage(i18n.get('hcps.resetPassword.success'), 'success');
                  } else {
                    this.showMessage('hcps.resetPassword.error', 'error');
                  }
                } catch (error) {
                  this.showMessage('hcps.resetPassword.error', 'error');
                }
              },
            },
            this.props.getJournalAction({ collection: 'hcps' }),
          ]}
          title={i18n.get('hcps.table.title')}
          options={{
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),

            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateHCP($id: ID!, $input: HCPInput!) {
                        updateHCP(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('hcps.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('hcps.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Hcps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Hcps)));
