import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ChipLink from '../components/ChipLink';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { CURRENCIES } from '../common/enums';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  starts: false,
  ends: false,
  practice: false,
  location: false,
  hcp: false,
  bookings: false,
  fee: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.lots.defaults';

class Lots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.starts.toString()) ||
        validator.isEmpty(data.ends.toString())
      ) {
        throw new Error(i18n.get('lots.error.validation'));
      }

      const input = {};

      const starts = moment(data.starts).unix();
      const ends = moment(data.ends).unix();
      data['starts'] = starts;
      data['ends'] = ends;

      helper.set(input, data, 'starts');
      helper.set(input, data, 'ends');

      return input;
    } catch (error) {
      throw new Error(i18n.get('lots.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'bookings':
        return 'bookings_search_string';
      case 'fee':
        return 'fee_ref';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getHCPSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"},{column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            number
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPSuggestions = () => {
    return [];
  };

  getBookingsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bookings(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Booking {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bookings.elements.map((element) => {
        const starts = element.starts.isoString
          ? moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')
          : '';
        return {
          name: `${starts}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBookingsSuggestions = () => {
    return [];
  };

  getFeesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: IS, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                fees(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Fee {
                            id
                            amount {
                              float
                              currency
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.fees.elements.map((element) => {
        return {
          name: `${helper.formatFloatNumber(element.amount.float)}${CURRENCIES[element.amount.currency]} (${
            element.id
          })`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultFeesSuggestions = () => {
    return [];
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getLocationsSuggestions = async (value) => {
    try {
      const where = `[{column: "street", comparator: LIKE_OR, string: "${value}"}, {column: "zipCode", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                locations(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Location {
                            id
                            bsnr
                            street
                            zipCode
                            city {
                              name {
                                de
                              }
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.locations.elements.map((element) => {
        return {
          name: `${element.street}, ${element.zipCode} ${element.city?.name.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultLocationsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newHcpID =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHcpID = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHcpID !== oldHcpID) {
        if (oldHcpID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHcpID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation unassignLotFromHCP($id: ID!, $lotId: ID!) {
                unassignLotFromHCP(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }

        if (newHcpID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHcpID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation assignLotToHCP($id: ID!, $lotId: ID!) {
                assignLotToHCP(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation unassignLotFromPractice($id: ID!, $lotId: ID!) {
                unassignLotFromPractice(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation assignLotToPractice($id: ID!, $lotId: ID!) {
                assignLotToPractice(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newLocationID =
        newData.location && newData.location.length > 0
          ? newData.location[0].value
          : newData.location
          ? newData.location.id
          : null;
      const oldLocationID = oldData && oldData.location ? oldData.location.id : null;

      if (newLocationID !== oldLocationID) {
        if (oldLocationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldLocationID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation unassignLotFromLocation($id: ID!, $lotId: ID!) {
                unassignLotFromLocation(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }

        if (newLocationID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newLocationID,
              lotId: newData.id,
            },
            mutation: gql`
              mutation assignLotToLocation($id: ID!, $lotId: ID!) {
                assignLotToLocation(id: $id, lotId: $lotId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newBookingIds = newData.bookings ? newData.bookings.map((p) => (p.value ? p.value : p.id)) : [];
      const oldBookingIds = oldData && oldData.bookings ? oldData.bookings.map((p) => p.id) : [];

      const addedBookingIds = helper.diffArray(newBookingIds, oldBookingIds);

      for (const addedBookingId of addedBookingIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bookingId: addedBookingId,
          },
          mutation: gql`
            mutation assignBookingToLot($id: ID!, $bookingId: ID!) {
              assignBookingToLot(id: $id, bookingId: $bookingId) {
                id
              }
            }
          `,
        });
      }

      const removedBookingIds = helper.diffArray(oldBookingIds, newBookingIds);

      for (const removedBookingId of removedBookingIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bookingId: removedBookingId,
          },
          mutation: gql`
            mutation unassignBookingFromLot($id: ID!, $bookingId: ID!) {
              unassignBookingFromLot(id: $id, bookingId: $bookingId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('lots.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('lots.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('lots.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.location'),
              field: 'location',
              hidden: this.default('location'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.street}, ${v.zipCode} ${v.city?.name.de} (${v.bsnr})`,
                      value: v.id,
                    };
                  }),
                this.getLocationsSuggestions,
                this.getDefaultLocationsSuggestions
              ),
              render: (rowData) => {
                if (rowData.location) {
                  return (
                    <ChipLink
                      link={{ page: i18n.get('menus.locations'), data: { id: rowData.location.id } }}
                      label={`${rowData.location.street}, ${rowData.location.zipCode} ${rowData.location?.city.name.de} (${rowData.location.bsnr})`}
                    />
                  );
                } else {
                  return '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.street}, ${props.value.zipCode} ${props.value.city?.name.de} (${props.value.bsnr})`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('lots.location.label'),
                  i18n.get('required'),
                  this.getLocationsSuggestions,
                  this.getDefaultLocationsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPSuggestions,
                this.getDefaultHCPSuggestions
              ),
              render: (rowData) => {
                return helper.hcpChip(rowData.hcp);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('lots.hcp.label'),
                  i18n.get('required'),
                  this.getHCPSuggestions,
                  this.getDefaultHCPSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.starts'),
              field: 'starts',
              hidden: this.default('starts'),
              type: 'datetime',
              comparator: 'GTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.ends'),
              field: 'ends',
              hidden: this.default('ends'),
              type: 'datetime',
              comparator: 'LTE',
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.bookings'),
              field: 'bookings',
              hidden: this.default('bookings'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    const starts = v.starts.isoString
                      ? moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')
                      : '';
                    return {
                      name: `${starts}`,
                      value: v.id,
                    };
                  }),
                this.getBookingsSuggestions,
                this.getDefaultBookingsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.bookings
                    ? rowData.bookings.map((booking) => {
                        const starts = booking.starts.isoString
                          ? moment(booking.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')
                          : '';
                        return (
                          <ChipLink
                            link={{ page: i18n.get('menus.bookings'), data: { id: booking.id } }}
                            key={booking.id}
                            label={`${starts}`}
                            style={{ marginRight: 8, marginBottom: 8 }}
                          />
                        );
                      })
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        if (v.name) {
                          return { name: v.name, value: v.id };
                        } else {
                          const starts = v.starts.isoString
                            ? moment(v.starts.isoString).format('DD.MM.YYYY, HH:mm [Uhr]')
                            : '';
                          return { name: `${starts}`, value: v.id };
                        }
                      })
                    : [],
                  i18n.get('lots.bookings.label'),
                  null,
                  this.getBookingsSuggestions,
                  this.getDefaultBookingsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('lots.fee'),
              field: 'fee',
              hidden: this.default('fee'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${helper.formatFloatNumber(v.amount.float)}${CURRENCIES[v.amount.currency]}`,
                      value: v.id,
                    };
                  }),
                this.getFeesSuggestions,
                this.getDefaultFeesSuggestions
              ),
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editable: 'never',
              render: (rowData) => {
                if (rowData && rowData.fee && rowData.fee.amount)
                  return (
                    <ChipLink
                      link={{ page: i18n.get('menus.fees'), data: { id: rowData.fee.id } }}
                      label={`${helper.formatFloatNumber(rowData.fee.amount.float)}${
                        CURRENCIES[rowData.fee.amount.currency]
                      }`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  );
                else return '-';
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'starts', ['starts']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          lots(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Lot {
                                  id,
                                  starts {
                                    isoString
                                  }
                                  ends {
                                    isoString
                                  }
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity 
                                  }
                                  location {
                                    id
                                    bsnr
                                    zipCode
                                    street
                                    city {
                                      name { de }
                                    }
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                  bookings{
                                    id
                                    starts {
                                      isoString
                                    }
                                  }
                                  fee {
                                    id
                                    amount {
                                      float
                                      currency
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.lots.elements.map((element) => ({
                    ...element,
                    starts: element.starts.isoString ? moment(element.starts.isoString).toDate() : null,
                    ends: element.ends.isoString ? moment(element.ends.isoString).toDate() : null,
                  })),
                  totalCount: result.data.lots.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'lots' }),
          ]}
          title={i18n.get('lots.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createLot($id: ID!, $input: LotInput!) {
                            createLot(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('lots.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('lots.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateLot($id: ID!, $input: LotInput!) {
                        updateLot(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  // assign it
                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('lots.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('lots.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteLot($id: ID!) {
                        deleteLot(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('lots.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('lots.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Lots.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Lots)));
