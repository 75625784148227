import React, { useState } from 'react';
import moment from 'moment';
import Chips from '../components/Chips';
import { makeStyles } from '@material-ui/core/styles';
import CustomTableCell from '../components/CustomTableCell';
import CustomFilterRow from '../components/CustomFilterRow';
import TextField from '@material-ui/core/TextField';
import * as i18n from '../common/i18n';
import ImageUpload from '../components/ImageUpload';
import FilterIcon from '@material-ui/icons/FilterList';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import Rating from '@material-ui/lab/Rating';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { DateRangePicker } from 'react-dates';

import { LEGAL_ENTITY, HCP_TITLES } from './enums';
import { STATUSES_COLORS } from './colors';
import { IconButton } from '@material-ui/core';
var deepEqual = require('deep-equal');

export const set = (object, data, key, fn) => {
  if (data[key] && data[key] !== null) {
    object[key] = fn ? fn(data[key]) : data[key];
  }
};

export const orderByString = (query, mapField, defaultID, collation = []) => {
  const direction = query['orderDirection'] === 'asc' ? 'ASC' : 'DESC';
  const orderByfield = query['orderBy'] === undefined ? defaultID : query['orderBy']['field'];
  const _collation = collation.findIndex((e) => e === orderByfield) > -1;

  let str = '';

  str += `{column: "${mapField(orderByfield)}", direction: ${direction}, collation:${_collation}}`;
  return `[${str}]`;
};

export const whereString = (filters, mapField, exact) => {
  let str = '';
  for (const filter of filters) {
    if (filter['column']['type'] === 'numeric') {
      if (Array.isArray(filter['value'])) {
        str += `{column: "${mapField(filter['column']['field'])}", comparator: GTE, float: ${filter['value'][0]}},`;
        str += `{column: "${mapField(filter['column']['field'])}", comparator: LTE, float: ${filter['value'][1]}},`;
      } else {
        str += `{column: "${mapField(filter['column']['field'])}", comparator:${
          filter['column']['comparator']
        }, float: ${filter['value']}},`;
      }
    } else if (filter['column']['type'] === 'datetime' || filter['column']['type'] === 'date') {
      if (filter['value'].startDate || filter['value'].endDate) {
        if (filter['value'].startDate) {
          str += `{column: "${mapField(filter['column']['field'])}", comparator: GTE, datetime: ${moment(
            filter['value'].startDate
          ).unix()}},`;
        }
        if (filter['value'].endDate) {
          str += `{column: "${mapField(filter['column']['field'])}", comparator: LTE, datetime: ${moment(
            filter['value'].endDate
          ).unix()}},`;
        }
      } else {
        str += `{column: "${mapField(filter['column']['field'])}", comparator:${
          filter['column']['comparator']
        } ,datetime: ${moment(filter['value']).unix()}},`;
      }
    } else if (filter['column']['type'] === 'boolean') {
      str += `{column: "${mapField(filter['column']['field'])}", comparator: IS, boolean: ${
        filter['value'] === 'checked'
      }},`;
    } else if (filter['column']['lookup'] !== undefined && filter['value'].length > 0) {
      str += `{column: "${mapField(filter['column']['field'])}", comparator: IN, values: [${filter['value'].map(
        (value) => `"${value}"`
      )}]},`;
    } else if (filter['column']['lookup'] === undefined) {
      const array = exact ? exact : [];

      if (array.includes(mapField(filter['column']['field']))) {
        str += `{column: "${mapField(filter['column']['field'])}", comparator: IS, string: "${filter['value']}"},`;
      } else {
        if (Array.isArray(filter['value'])) {
          if (filter['column']['comparator'] && filter['column']['comparator'] === 'IN' && filter['value'].length > 0) {
            str += `{column: "${mapField(filter['column']['field'])}", comparator: IN, values: [${filter['value'].map(
              (value) => `"${value}"`
            )}]},`;
          } else {
            // eslint-disable-next-line
            filter['value'].forEach((value) => {
              str += `{column: "${mapField(filter['column']['field'])}", comparator: ${
                filter['column']['comparator'] || 'LIKE_OR'
              }, string: "${value}"},`;
            });
          }
        } else {
          str += `{column: "${mapField(filter['column']['field'])}", comparator: LIKE, string: "${filter['value']}"},`;
        }
      }
    }
  }
  return `[${str}]`;
};

export const chips = (
  props,
  value,
  placeholder,
  label,
  getSuggestions,
  getDefaultSuggestions,
  maxLength,
  multiSection,
  renderSectionTitle,
  getSectionSuggestions,
  chipRenderer,
  debounceOff
) => {
  return (
    <Chips
      maxLength={maxLength ? maxLength : 1}
      value={value}
      getSuggestions={getSuggestions}
      getDefaultSuggestions={getDefaultSuggestions}
      onChanged={(value) => props.onChange(value)}
      placeholder={placeholder}
      label={label}
      multiSection={multiSection === true}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      chipRenderer={chipRenderer}
      debounceOff={debounceOff}
    />
  );
};

export const required = (props) => {
  return (
    <TextField
      style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
      type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      placeholder={props.columnDef.title}
      value={props.value === undefined ? '' : props.value}
      onChange={(event) => props.onChange(event.target.value)}
      multiline={props.columnDef.type !== 'numeric'}
      label={`${i18n.get('required')}`}
      rowsMax={props.columnDef.type !== 'numeric' ? 3 : 1}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          fontSize: 13,
        },
      }}
    />
  );
};

export const defaultCell = (props) => {
  return <CustomTableCell defaultCellStyle={{ minWidth: '150px', maxWidth: '400px' }} {...props} />;
};

export const defaultFilter = (props) => {
  return <CustomFilterRow {...props} />;
};

export const diffArray = (leftArray, rightArray) => {
  const diff = [];
  for (const element of leftArray) {
    if (rightArray.filter((v) => deepEqual(element, v)).length === 0) {
      diff.push(element);
    }
  }

  return diff;
};

export const defaultImageUpload = (props) => {
  return <ImageUpload onDrop={(files) => props.onChange(files[0])} {...props} />;
};

export const formatFloatNumber = (number) => {
  return number !== null && number !== undefined
    ? number.toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    : '-';
};

export const chipsFilter = (onChange, getValues, getSuggestions, getDefaultSuggestions) => (props) => {
  return (
    <Box display="flex" alignItems="center">
      <FilterIcon />
      {chips(
        {
          onChange: onChange
            ? (items) => {
                onChange(items, props);
              }
            : (items) => {
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  items.map((item) => `,${item.value},`)
                );
              },
        },
        getValues(props.value || []),
        null,
        null,
        getSuggestions,
        getDefaultSuggestions,
        50
      )}
    </Box>
  );
};

export const DateRangeFilter = (props) => {
  const [starts, setStarts] = useState(null);
  const [ends, setEnds] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleChangeRange = async (starts, ends) => {
    await setStarts(starts);
    await setEnds(ends);
  };

  return (
    <Box display="flex" alignItems="center" width="300px">
      <DateRangePicker
        customInputIcon={<FilterIcon />}
        small
        noBorder
        showClearDates
        displayFormat={'DD.MM.YYYY'}
        startDatePlaceholderText={i18n.get('dateTimePicker.starts')}
        endDatePlaceholderText={i18n.get('dateTimePicker.ends')}
        isOutsideRange={() => false}
        startDate={starts}
        startDateId="starts"
        endDate={ends}
        endDateId="ends"
        onClose={async ({ startDate, endDate }) => {
          await handleChangeRange(startDate, endDate);
          if (startDate !== starts || endDate !== ends)
            props.onFilterChanged(props.columnDef.tableData.id, { startDate, endDate });
        }}
        onDatesChange={({ startDate, endDate }) => {
          handleChangeRange(startDate, endDate);
          if (startDate === null && endDate === null) {
            props.onFilterChanged(props.columnDef.tableData.id, { startDate, endDate });
          }
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      />
    </Box>
  );
};

export const RatingFilter = (props) => {
  const [value, setValue] = useState(0);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Rating
        precision={0.1}
        name="ratingFilterValue"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.onFilterChanged(props.columnDef.tableData.id, newValue === 0 ? null : newValue);
        }}
      />
      {value > 0 && (
        <Tooltip title={i18n.get('tooltips.clear')}>
          <IconButton
            size="small"
            onClick={() => {
              setValue(0);
              props.onFilterChanged(props.columnDef.tableData.id, null);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  valueLabel: {
    '& > span > span': {
      color: '#fff',
    },
  },
});

export const NumberRangeFilter = (props) => {
  const INIT_VALUE = [props.min || 0, props.max || 100];
  const [value, setValue] = React.useState(INIT_VALUE);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onFilterChanged(props.columnDef.tableData.id, value);
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end" width="200px" height="60px">
      <Slider
        classes={classes}
        min={props.min || 0}
        step={props.step || 1}
        max={props.max || 100}
        value={value}
        onChange={handleChange}
        aria-labelledby="range-slider"
        valueLabelDisplay="on"
      />
      <Tooltip title={i18n.get('tooltips.clear')}>
        <span>
          <IconButton
            style={{
              marginLeft: '10px',
            }}
            disabled={diffArray(INIT_VALUE, value).length === 0}
            size="small"
            onClick={() => {
              setValue(INIT_VALUE);
              props.onFilterChanged(props.columnDef.tableData.id, null);
            }}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const practiceChip = (practice, disableLink) => {
  const ChipComponent = disableLink ? Chip : ChipLink;
  return practice ? (
    <ChipComponent
      link={{ page: i18n.get('menus.practices'), data: { id: practice.id } }}
      key={practice.id}
      label={`${practice.name} ${practice.legalEntity ? LEGAL_ENTITY[practice.legalEntity] : ''} (${
        practice.number || ''
      }) `}
      style={{
        backgroundColor: STATUSES_COLORS[practice.status],
        color: 'white',
        marginRight: 8,
        marginBottom: 8,
      }}
    />
  ) : (
    ' - '
  );
};

export const hcpChip = (hcp, disableLink) => {
  const ChipComponent = disableLink ? Chip : ChipLink;
  return hcp ? (
    <ChipComponent
      link={{ page: i18n.get('menus.hcps'), data: { id: hcp.id } }}
      key={hcp.id}
      label={`${HCP_TITLES[hcp.title]} ${hcp.firstname || ''} ${hcp.lastname || ''} (${hcp.number || ''})`}
      style={{
        backgroundColor: STATUSES_COLORS[hcp.status],
        color: 'white',
        marginRight: 8,
        marginBottom: 8,
      }}
    />
  ) : (
    ' - '
  );
};

export const exportFile = (dataType, fileName, data) => {
  if (window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([data]);
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const charBom = '\uFEFF';
    const encodedData = encodeURIComponent(data);
    let content = `data:text/${dataType};charset=utf-8,${charBom}${encodedData}`;

    const link = document.createElement('a');
    link.setAttribute('href', content);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();
  }
};
