import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import { withIAM } from '../common/iamV2';
import { ACTIONS } from '../common/enums';

const styles = (theme) => ({});

const defaults = {
  id: true,
  collection: false,
  ref: false,
  oldData: false,
  newData: false,
  action: false,
  who: false,
  created: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.journals.defaults';

class Journals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('journals.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.collection'),
              field: 'collection',
              hidden: this.default('collection'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.ref'),
              field: 'ref',
              hidden: this.default('ref'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.action'),
              field: 'action',
              hidden: this.default('action'),
              lookup: ACTIONS,
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.who'),
              field: 'who',
              hidden: this.default('who'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.created'),
              field: 'created',
              hidden: this.default('created'),
              sorting: true,
              filtering: true,
              filterComponent: helper.DateRangeFilter,
              type: 'datetime',
              editable: 'never',
            },
            {
              title: i18n.get('journals.oldData'),
              field: 'oldData',
              hidden: this.default('oldData'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
            {
              title: i18n.get('journals.newData'),
              field: 'newData',
              hidden: this.default('newData'),
              sorting: true,
              filtering: true,
              editable: 'never',
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'collection');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          journals(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Journal {
                                  id
                                  collection
                                  ref
                                  oldData
                                  newData
                                  action
                                  who
                                  created {
                                    isoString
                                  }                                  
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.journals.elements.map((element) => {
                    return {
                      ...element,
                      created: element.created.isoString ? moment(element.created.isoString).toDate() : null,
                    };
                  }),
                  totalCount: result.data.journals.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
          ]}
          title={i18n.get('journals.title')}
          options={{
            resetFiltersButton: true,
            // addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Journals.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(Journals));
