import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { isNullOrUndefined } from 'util';
import { AdminContextConsumer } from './Admin';
import { menus } from './Menus';
import { v1 as uuidv1 } from 'uuid';
import { withIAM } from '../common/iamV2';
import gql from 'graphql-tag';
import * as graphql from '../common/graphql';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({});

class DrawerMenu extends React.Component {
  state = {
    enroledPractices: '',
    enroledHCPs: '',
  };

  componentDidMount() {
    const fetch = async () => {
      const where = `[{column: "status", comparator: IS, string: "ENROLLED"}]`;
      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                    query {
                      practices(where:${where}) {
                        totalCount
                      }
                      hcps(where:${where}) {
                        totalCount
                      }
                    }
                  `,
        fetchPolicy: 'no-cache',
      });
      this.setState({
        enroledPractices: result.data.practices.totalCount,
        enroledHCPs: result.data.hcps.totalCount,
      });
    };
    fetch();
  }

  render() {
    return (
      <React.Fragment>
        <AdminContextConsumer>
          {({ drawerOpen, onMenuChange, selected }) => (
            <React.Fragment>
              {menus
                .filter((menu) => {
                  return menu.roles.includes(this.props.roles[0]);
                })
                .map((menu) => {
                  return (
                    <div key={uuidv1()}>
                      {!isNullOrUndefined(menu.title) && drawerOpen ? (
                        <ListSubheader disableSticky={true}>{menu.title}</ListSubheader>
                      ) : (
                        !isNullOrUndefined(menu.title) && !drawerOpen && <Divider />
                      )}
                      {menu.entries.map((entry) => {
                        return (
                          <ListItem
                            button
                            onClick={(e) => onMenuChange(entry)}
                            key={entry.primary}
                            // selected={selected.primary === entry.primary}
                            component={NavLink}
                            activeClassName="Mui-selected"
                            to={`/${entry.primary}`}
                          >
                            <Tooltip title={!isNullOrUndefined(entry.tooltip) ? entry.tooltip : entry.primary}>
                              <ListItemIcon>
                                {React.cloneElement(entry.icon, {
                                  data: this.state,
                                })}
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={entry.primary} />
                          </ListItem>
                        );
                      })}
                    </div>
                  );
                })}
            </React.Fragment>
          )}
        </AdminContextConsumer>
      </React.Fragment>
    );
  }
}

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(DrawerMenu));
