import React from 'react';
import { withStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import * as helper from '../common/helper';

const styles = (theme) => ({});

export class CustomTableCell extends React.Component {
  getRenderValue() {
    if (
      this.props.columnDef.emptyValue !== undefined &&
      (this.props.value === undefined || this.props.value === null)
    ) {
      return this.getEmptyValue(this.props.columnDef.emptyValue);
    }
    if (this.props.columnDef.render) {
      if (this.props.rowData) {
        return this.props.columnDef.render(this.props.rowData, 'row');
      } else {
        return this.props.columnDef.render(this.props.value, 'group');
      }
    } else if (this.props.columnDef.type === 'boolean') {
      const style = { textAlign: 'left', verticalAlign: 'middle', width: 48 };
      if (this.props.value) {
        return <this.props.icons.Check style={style} />;
      } else {
        return <this.props.icons.ThirdStateCheck style={style} />;
      }
    } else if (this.props.columnDef.type === 'date') {
      if (this.props.value instanceof Date) {
        return moment(this.props.value).format('DD.MM.YYYY');
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === 'time') {
      if (this.props.value instanceof Date) {
        return moment(this.props.value).format('HH:mm');
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === 'datetime') {
      if (this.props.value instanceof Date) {
        return this.props.value.toString() !== 'Invalid Date'
          ? moment(this.props.value).format('DD.MM.YYYY, HH:mm')
          : '-';
      } else {
        return this.props.value;
      }
    } else if (this.props.columnDef.type === 'currency') {
      return this.getCurrencyValue(this.props.columnDef.currencySetting, this.props.value);
    } else if (this.props.columnDef.type === 'numeric') {
      return this.props.value !== undefined ? helper.formatFloatNumber(this.props.value) : '';
    }

    return this.props.value;
  }

  getEmptyValue(emptyValue) {
    if (typeof emptyValue === 'function') {
      return this.props.columnDef.emptyValue(this.props.rowData);
    } else {
      return emptyValue;
    }
  }

  getCurrencyValue(currencySetting, value) {
    if (currencySetting !== undefined) {
      return new Intl.NumberFormat(currencySetting.locale !== undefined ? currencySetting.locale : 'en-US', {
        style: 'currency',
        currency: currencySetting.currencyCode !== undefined ? currencySetting.currencyCode : 'USD',
        minimumFractionDigits:
          currencySetting.minimumFractionDigits !== undefined ? currencySetting.minimumFractionDigits : 2,
        maximumFractionDigits:
          currencySetting.maximumFractionDigits !== undefined ? currencySetting.maximumFractionDigits : 2,
      }).format(value !== undefined ? value : 0);
    } else {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        value !== undefined ? value : 0
      );
    }
  }

  handleClickCell = (e) => {
    if (this.props.columnDef.disableClick) {
      e.stopPropagation();
    }
  };

  getStyle = () => {
    let cellStyle = {};

    if (typeof this.props.columnDef.cellStyle === 'function') {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle(this.props.value, this.props.rowData) };
    } else {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle };
    }

    if (this.props.columnDef.disableClick) {
      cellStyle.cursor = 'default';
    }

    if (this.props.rowData && this.props.rowData.shadowed) {
      if (this.props.rowData.shadowed.includes(this.props.columnDef.field)) {
        cellStyle.fontWeight = 'bold';
        cellStyle.color = '#00254C';
      } else {
        cellStyle.color = '#777777';
      }
    } else {
      cellStyle.color = '#00254C';
    }

    return { ...this.props.defaultCellStyle, ...this.props.style, ...cellStyle };
  };

  render() {
    const { icons, columnDef, rowData, defaultCellStyle, ...cellProps } = this.props;

    return (
      <TableCell
        key={moment().valueOf()}
        size={this.props.size}
        {...cellProps}
        style={this.getStyle()}
        align={['numeric'].indexOf(this.props.columnDef.type) !== -1 ? 'right' : 'left'}
        onClick={this.handleClickCell}
      >
        {this.props.children}
        {this.getRenderValue()}
      </TableCell>
    );
  }
}

CustomTableCell.propTypes = {
  columnDef: PropTypes.object.isRequired,
  value: PropTypes.any,
  rowData: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(CustomTableCell);
