import React from 'react';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { client as graphqlClient } from '../common/graphql';
import gql from 'graphql-tag';

import { auth } from '../common/firebase';
import AlertDialog from '../components/AlertDialog';
const styles = (theme) => ({
  root: {
    width: '400px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
class CompleteRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validationDone: false,
      submiting: false,
      isCancelDialogOpened: false,
      email: '',
      password: '',
      confirmPassword: '',
      error: '',
      invitation: null,
    };
  }

  handleInput = (e) => {
    this.setState({
      error: '',
      [e.target.name]: e.target.value,
    });
  };

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpened: false });
  };

  handleCancel = () => {
    this.setState({ isCancelDialogOpened: true });
  };

  handleCancelSubmit = () => {
    window.location.href = '/';
  };

  handleSubmit = async () => {
    const { password, confirmPassword, invitation } = this.state;
    if (password.length === 0 || confirmPassword.length === 0) {
      this.setState({ error: 'All fields are reqired.' });
    } else if (password !== confirmPassword) {
      this.setState({ error: 'Passwords are not equal.' });
    } else {
      await this.setState({ submiting: true });
      const result = await graphqlClient.mutate({
        variables: {
          id: invitation.id,
          password: password,
        },
        mutation: gql`
          mutation submitInvitation($id: String!, $password: String!) {
            submitInvitation(id: $id, password: $password)
          }
        `,
      });
      if (result.data.submitInvitation) {
        await auth
          .signInWithEmailAndPassword(invitation.email, password)
          .then(() => {
            window.location.href = '/';
          })
          .catch((e) => {
            this.setState({ error: e.message });
          });
      } else {
        this.setState({ error: 'Something went wrong. Please try again.' });
      }
      this.setState({ submiting: false });
    }
  };

  async componentDidMount() {
    const invitationID = new URLSearchParams(window.location.search).get('invitation');
    if (invitationID) {
      const result = await graphqlClient.mutate({
        variables: {
          id: invitationID,
        },
        mutation: gql`
          mutation validateInvitation($id: String!) {
            validateInvitation(id: $id) {
              isValid
              id
              email
            }
          }
        `,
      });
      const invitation = result.data.validateInvitation;

      if (invitation.isValid) {
        this.setState({ validationDone: true, invitation });
      } else {
        window.location.href = '/';
      }
    } else {
      window.location.href = '/';
    }
  }

  render() {
    return (
      this.state.validationDone && (
        <React.Fragment>
          <Card className={this.props.classes.root} variant="outlined">
            <CardHeader title="Welcome" subheader="Please fill the form" />
            <CardContent>
              <Typography color="error">{this.state.error}</Typography>
              <TextField
                margin="dense"
                variant="outlined"
                label="E-mail"
                fullWidth
                required
                disabled
                name="email"
                value={this.state.invitation.email}
                // onChange={this.handleInput}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Password"
                fullWidth
                required
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInput}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Confirm password"
                fullWidth
                required
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleInput}
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </CardActions>
          </Card>
          <AlertDialog
            open={this.state.isCancelDialogOpened}
            onClose={this.closeCancelDialog}
            onCancel={this.closeCancelDialog}
            onSubmit={this.handleCancelSubmit}
            title="Cancel registration"
            text="Are you sure you want to cancel?"
          />
          <Backdrop style={{ zIndex: 10 }} open={this.state.submiting}>
            <CircularProgress />
          </Backdrop>
        </React.Fragment>
      )
    );
  }
}

export default withStyles(styles, { withTheme: true })(CompleteRegistration);
