import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as i18n from '../common/i18n';
import { auth } from '../common/firebase';

import Message from './Message';

class ChangePasswordDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      password: '',
      confirmPassword: '',
    };
  }

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetForm = () => {
    this.setState({
      password: '',
      confirmPassword: '',
    });
  };

  submitForm = async () => {
    const { password, confirmPassword } = this.state;
    if (password.length > 3 && confirmPassword.length > 3 && password === confirmPassword) {
      try {
        await auth.currentUser.updatePassword(password);
        this.showMessage(i18n.get('changePasswordDialog.action.success'), 'success');
        this.resetForm();
        this.props.closeChangePasswordDialog();
      } catch (error) {
        this.showMessage(i18n.get('changePasswordDialog.action.error'), 'error', error.message);
      }
    } else {
      this.showMessage(i18n.get('changePasswordDialog.validation.error'), 'error');
    }
  };

  render() {
    const { password, confirmPassword } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open={this.props.isChangePasswordOpened}
          onClose={this.props.closeChangePasswordDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{i18n.get('changePasswordDialog.title')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              label={i18n.get('changePasswordDialog.field.password.label')}
              type="password"
              name="password"
              value={password}
              onChange={this.onFieldChange}
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label={i18n.get('changePasswordDialog.field.confirmPassword.label')}
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.onFieldChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.props.closeChangePasswordDialog} color="secondary">
              {i18n.get('changePasswordDialog.action.cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={!(password.length > 0 && confirmPassword.length > 0)}
              onClick={this.submitForm}
              color="primary"
            >
              {i18n.get('changePasswordDialog.action.submit')}
            </Button>
          </DialogActions>
        </Dialog>
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </React.Fragment>
    );
  }
}

export default ChangePasswordDialog;
