import React from 'react';
import ListIcon from '@material-ui/icons/List';
import * as i18n from '../common/i18n';
import { ACTIONS } from '../common/enums';
import PaginationDialog from '../components/PaginationDialog';
import moment from 'moment-timezone';
import gql from 'graphql-tag';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';

export function withJournal(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        pagination: false,
        paginationObject: null,
        paginationColumns: null,
        paginationTitle: null,
        fetchPaginationData: null,
      };
    }

    mapField(column) {
      return column;
    }

    handlePaginationCancel = () => {
      this.setState({
        pagination: false,
        pagginationCollection: false,
        paginationObject: null,
        paginationColumns: null,
        paginationTitle: null,
        fetchPaginationData: null,
      });
    };

    fetchPaginationJournalData = async (query) => {
      try {
        const where = `[
        { column: "collection", comparator: IS, string: "${this.state.pagginationCollection}" },
        { column: "ref", comparator: IS, string: "${this.state.paginationObject.id}" },
      ]`;
        const orderBy = helper.orderByString(query, this.mapField, 'created');

        const result = await graphql.clientWithToken(this.props.accessToken).query({
          query: gql`
          query {
              journals(first: ${query.pageSize}, offset: ${query.pageSize * query.page},
                orderBy:${orderBy}, where:${where}){
                totalCount
                elements {
                  ... on Journal {
                    id
                    ref
                    oldData
                    newData
                    action
                    who
                    created {
                      isoString
                    }                 
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        return {
          page: query.page,
          data: result.data.journals.elements.map((element) => {
            return {
              ...element,
              created: moment(element.created.isoString).format('DD.MM.YYYY, HH:mm:ss'),
            };
          }),
          totalCount: result.data.journals.totalCount,
        };
      } catch (error) {
        throw error;
      }
    };

    getJournalAction = (options) => {
      return {
        icon: options.icon || ListIcon,
        tooltip: options.icon || i18n.get('menus.journals'),
        onClick:
          options.onClick ||
          ((event, rowData) => {
            this.setState({
              pagginationCollection: options.collection,
              pagination: true,
              paginationColumns: options.paginationColumns || [
                { title: i18n.get('journals.action'), field: 'action', lookup: ACTIONS },
                { title: i18n.get('journals.who'), field: 'who' },
                { title: i18n.get('journals.created'), field: 'created' },
                { title: i18n.get('journals.oldData'), field: 'oldData', hidden: true, sorting: false },
                { title: i18n.get('journals.newData'), field: 'newData', hidden: true, sorting: false },
              ],
              paginationTitle: options.paginationTitle || i18n.get('journals.title'),
              fetchPaginationData: options.fetchPaginationData || this.fetchPaginationJournalData,
              paginationObject: rowData,
            });
          }),
      };
    };

    render() {
      return (
        <React.Fragment>
          <WrappedComponent getJournalAction={this.getJournalAction} {...this.props} />
          <PaginationDialog
            columns={this.state.paginationColumns}
            fetchData={this.state.fetchPaginationData}
            open={this.state.pagination}
            onCancel={this.handlePaginationCancel}
            dialogTitle={this.state.paginationTitle}
            cancelText={i18n.get('general.pagination.cancel')}
          />
        </React.Fragment>
      );
    }
  };
}
