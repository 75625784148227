import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ResetIcon from '@material-ui/icons/Cached';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import * as validator from 'validator';
import { ROLES } from '../common/enums';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT, ROLE_FINANCE, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import { auth } from '../common/firebase';

const styles = (theme) => ({});

const defaults = {
  id: true,
  displayName: false,
  email: false,
  roles: false,
  pageSize: 10,
};

const suggestions = [
  { name: ROLES.ADMIN, value: ROLE_ADMIN },
  { name: ROLES.SUPPORT, value: ROLE_SUPPORT },
  { name: ROLES.FINANCE, value: ROLE_FINANCE },
  { name: ROLES.TECHNICAL, value: ROLE_TECHNICAL },
];

const defaultsPropertyName = 'p37.users.defaults';

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (!validator.isEmail(data.email) || data.roles.length === 0) {
        throw new Error(i18n.get('users.error.validation'));
      }

      const input = {
        email: data.email,
        roles: data.roles.map((r) => r.value),
      };

      return input;
    } catch (error) {
      throw new Error(i18n.get('users.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getSuggestions(value) {
    const inputValue = value ? value.trim().toLowerCase() : '';
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep = count < 5 && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  getDefaultSuggestions() {
    return suggestions;
  }

  changeUserStatusAction = (rowData) => {
    return {
      icon: rowData.disabled ? CheckIcon : BlockIcon,
      tooltip: rowData.disabled
        ? i18n.get('users.changeUserStatus.label.enable')
        : i18n.get('users.changeUserStatus.label.disable'),
      onClick: async (event, rowData) => {
        try {
          if (rowData.disabled) {
            await graphql.clientWithToken(this.props.accessToken).mutate({
              variables: {
                id: rowData.id,
              },
              mutation: gql`
                mutation unlockAccount($id: ID!) {
                  unlockAccount(id: $id) {
                    id
                  }
                }
              `,
            });
            this.showMessage(i18n.get('users.changeUserStatus.action.enable.success'), 'success');
          } else {
            await graphql.clientWithToken(this.props.accessToken).mutate({
              variables: {
                id: rowData.id,
              },
              mutation: gql`
                mutation lockAccount($id: ID!) {
                  lockAccount(id: $id) {
                    id
                  }
                }
              `,
            });
            this.showMessage(i18n.get('users.changeUserStatus.action.disable.success'), 'success');
          }
          this.tableRef.current.onQueryChange();
        } catch (error) {
          this.showMessage('users.changeUserStatus.error', 'error');
        }
      },
    };
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('users.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              editable: 'never',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('users.displayName'),
              field: 'displayName',
              hidden: this.default('displayName'),
              sorting: true,
              filtering: true,
              editable: 'never',
              emptyValue: '-',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('users.email'),
              field: 'email',
              hidden: this.default('email'),
              sorting: true,
              filtering: true,
              editable: 'onAdd',
              emptyValue: '-',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return rowData.email ? <Link href={`mailto:${rowData.email}`}>{rowData.email}</Link> : '-';
              },
            },
            {
              title: i18n.get('users.roles'),
              field: 'roles',
              hidden: this.default('roles'),
              filtering: false,
              sorting: false,
              render: (rowData) => {
                return rowData && rowData.roles.length > 0
                  ? rowData.roles.map((role) => (
                      <Chip key={role} label={ROLES[role]} style={{ marginRight: 8, marginBottom: 8 }} />
                    ))
                  : '-';
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((value) => {
                        return { name: ROLES[value], value: value };
                      })
                    : [],
                  i18n.get('users.roles.label'),
                  i18n.get('required'),
                  this.getSuggestions,
                  this.getDefaultSuggestions,
                  4
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          accounts(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Account {
                                  id,
                                  email
                                  displayName
                                  roles
                                  disabled
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.accounts.elements,
                  totalCount: result.data.accounts.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: ResetIcon,
              tooltip: i18n.get('users.resetPassword.label'),
              onClick: async (event, rowData) => {
                try {
                  await auth.sendPasswordResetEmail(rowData.email);
                  this.showMessage(i18n.get('users.resetPassword.success'), 'success');
                } catch (error) {
                  this.showMessage('users.resetPassword.error', 'error');
                }
              },
            },
            this.changeUserStatusAction,
            this.props.getJournalAction({ collection: 'accounts' }),
          ]}
          title={i18n.get('users.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          roles: input.roles,
                          email: input.email,
                        },
                        mutation: gql`
                          mutation createAccount($email: String!, $roles: [Role]) {
                            createAccount(email: $email, roles: $roles) {
                              id
                            }
                          }
                        `,
                      });

                      this.showMessage(i18n.get('users.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('users.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const addedRoles = helper.diffArray(
                    newData.roles.map((g) => g.value),
                    oldData.roles
                  );
                  const removedRoles = helper.diffArray(
                    oldData.roles,
                    newData.roles.map((g) => g.value)
                  );

                  for (const addedRole of addedRoles) {
                    await graphql.clientWithToken(this.props.accessToken).mutate({
                      variables: {
                        id: newData.id,
                        role: addedRole,
                      },
                      mutation: gql`
                        mutation assignRoleToAccount($id: ID!, $role: Role) {
                          assignRoleToAccount(id: $id, role: $role) {
                            id
                          }
                        }
                      `,
                    });
                  }

                  for (const removedRole of removedRoles) {
                    await graphql.clientWithToken(this.props.accessToken).mutate({
                      variables: {
                        id: newData.id,
                        role: removedRole,
                      },
                      mutation: gql`
                        mutation unassignRoleFromAccount($id: ID!, $role: Role) {
                          unassignRoleFromAccount(id: $id, role: $role) {
                            id
                          }
                        }
                      `,
                    });
                  }

                  this.showMessage(i18n.get('users.message.onRowUpdate.success'), 'success');

                  resolve();
                } catch (error) {
                  this.showMessage(i18n.get('users.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteAccount($id: ID!) {
                        deleteAccount(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('users.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('users.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Users)));
