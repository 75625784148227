import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_TECHNICAL } from '../common/iamV2';
import { withJournal } from '../common/journal';
import deepEqual from 'deep-equal';

const styles = (theme) => ({});

const defaults = {
  name: false,
  dialCode: false,
  code: false,
  latitude: false,
  longitude: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.phones.defaults';

class Texts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  componentDidMount() {
    this.fetchJSON();
  }

  fetchJSON = async () => {
    this.setState({ working: true }, async () => {
      try {
        const result = await graphql.clientWithToken(this.props.accessToken).query({
          query: gql`
            query {
              json: phones
            }
          `,
          fetchPolicy: 'no-cache',
        });

        const data =
          result.data.json !== ''
            ? JSON.parse(result.data.json).map((r, index) => ({
                ...r,
                id: index,
                dialCode: r['dial_code'],
              }))
            : [];

        this.setState({
          data: data,
          originalData: data,
          working: false,
        });
      } catch (error) {
        this.showMessage(i18n.get('phones.message.fetch.error'), 'error', error.message);
        this.setState({ working: false });
      }
    });
  };

  saveJSON = async () => {
    this.setState({ working: true }, async () => {
      try {
        const setData = this.state.data.map((r) => ({
          name: r.name,
          dial_code: r.dialCode,
          code: r.code,
          latitude: r.latitude,
          longitude: r.longitude,
        }));
        const result = await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            json: JSON.stringify(setData),
          },
          mutation: gql`
            mutation updatePhones($json: String) {
              json: updatePhones(json: $json)
            }
          `,
        });
        const data = JSON.parse(result.data.json).map((r, index) => ({
          ...r,
          id: index,
          dialCode: r['dial_code'],
        }));
        this.setState({
          data: data,
          originalData: data,
          working: false,
        });
        this.showMessage(i18n.get('phones.publish.success'), 'success');
      } catch (error) {
        this.showMessage(i18n.get('phones.publish.error'), 'error', error.message);
        this.setState({ working: false });
      }
    });
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            // FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          isLoading={this.state.working}
          columns={[
            {
              title: i18n.get('phones.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: false,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('phones.name'),
              field: 'name',
              hidden: this.default('name'),
              sorting: true,
              filtering: false,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('phones.dialCode'),
              field: 'dialCode',
              hidden: this.default('dialCode'),
              sorting: true,
              filtering: false,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('phones.code'),
              field: 'code',
              hidden: this.default('code'),
              sorting: true,
              filtering: false,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('phones.latitude'),
              field: 'latitude',
              hidden: this.default('latitude'),
              sorting: true,
              filtering: false,
              type: 'numeric',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('phones.longitude'),
              field: 'longitude',
              hidden: this.default('longitude'),
              sorting: true,
              filtering: false,
              type: 'numeric',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
          ]}
          data={this.state.data}
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]) &&
            !deepEqual(this.state.data, this.state.originalData) &&
            !this.state.working
              ? {
                  icon: CloudUploadIcon,
                  isFreeAction: true,
                  tooltip: i18n.get('phones.publish.tooltip'),
                  onClick: (event, rowData) => this.saveJSON(),
                }
              : null,
            // this.props.getJournalAction({ collection: 'texts' }),
          ]}
          title={i18n.get('phones.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_TECHNICAL]) && !this.state.working,
            isDeletable: false,
            onRowAdd: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                const data = [...this.state.data, newData];
                this.setState(
                  {
                    data: data,
                  },
                  () => {
                    this.showMessage(i18n.get('phones.message.onRowAdd.success'), 'success');
                    resolve();
                  }
                );
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                const data = this.state.data.map((row) => {
                  if (row.id === newData.id) {
                    return {
                      ...newData,
                    };
                  }
                  return row;
                });
                this.setState(
                  {
                    data: data,
                  },
                  () => {
                    this.showMessage(i18n.get('phones.message.onRowUpdate.success'), 'success');
                    resolve();
                  }
                );
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                const data = this.state.data.filter((row) => row.id !== oldData.id);
                this.setState(
                  {
                    data: data,
                  },
                  () => {
                    this.showMessage(i18n.get('phones.message.onRowDelete.success'), 'success');
                    resolve();
                  }
                );
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Texts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Texts)));
