import React from 'react';
import Message from '../components/Message';

const withMessages = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        message: null,
        details: null,
        hideAfter: 3000,
        variant: 'info',
      };
    }

    showMessage = (message, variant, details, hideAfter) => {
      this.setState({
        message: message,
        variant: variant ? variant : 'info',
        hideAfter: hideAfter ? hideAfter : 3000,
        details: details ? details : null,
      });
    };

    closeMessage = () => {
      this.setState({
        message: null,
        details: null,
        hideAfter: 3000,
        variant: 'info',
      });
    };

    render() {
      return (
        <React.Fragment>
          <WrappedComponent showMessage={this.showMessage} {...this.props} />
          <Message
            hideAfter={this.state.hideAfter}
            onClose={this.closeMessage}
            variant={this.state.variant}
            message={this.state.message}
            details={this.state.details}
            open={this.state.message !== null}
          />
        </React.Fragment>
      );
    }
  };
};

export default withMessages;
