import { createMuiTheme } from '@material-ui/core';
import * as Colors from './colors';

export const adminTheme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  typography: {
    fontFamily: 'Abel',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true,
  },
  anchor: {
    main: Colors.primary,
    selected: Colors.secondary,
  },
  overrides: {
    MuiTable: {
      root: {
        outline: 'none',
      },
    },
    MuiIconButton: {
      colorInherit: {
        color: Colors.primary,
      },
    },
  },
});
