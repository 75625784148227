import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Rating from '@material-ui/lab/Rating';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import ImagesViewer from '../components/ImagesViewer';
import * as validator from 'validator';
import { CURRENCIES, HCP_TITLES } from '../common/enums';
import PaginationDialog from '../components/PaginationDialog';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const styles = (theme) => ({});

const defaults = {
  id: true,
  firstname: false,
  lastname: false,
  phone: false,
  birthday: false,
  fraudScore: false,
  bookings: false,
  reviews: false,
  purchases: false,
  photo: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.patients.defaults';

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
      pagination: false,
      staticData: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.firstname) ||
        validator.isEmpty(data.lastname) ||
        validator.isNumeric(toString(data.fraudScore)) ||
        validator.isEmpty(data.birthday.toString()) ||
        validator.isEmpty(data.phone)
      ) {
        throw new Error(i18n.get('patients.error.validation'));
      }

      const birthday = moment(data.birthday).unix();
      data['birthday'] = birthday;

      const input = {};
      helper.set(input, data, 'firstname');
      helper.set(input, data, 'lastname');
      helper.set(input, data, 'phone');
      helper.set(input, data, 'birthday');
      helper.set(input, data, 'fraudScore', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('patients.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'bookings':
        return 'bookings_search_string';
      case 'reviews':
        return 'reviews_search_string';
      case 'purchases':
        return 'purchases_search_string';
      case 'points':
        return 'points_search_string';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getBookingsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bookings(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Booking {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bookings.elements.map((element) => {
        const starts = element.starts.isoString
          ? moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')
          : '';
        return {
          name: `${starts}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBookingsSuggestions = () => {
    return [];
  };

  getReviewsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE_OR, string: "${value}"}, {column: "rating", comparator: IS_OR, int: ${parseInt(
        value
      )}}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                reviews(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Review {
                            id
                            rating
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.reviews.elements.map((element) => {
        return {
          name: (
            <Chip
              label={
                <Box display="flex">
                  <Rating value={element.rating} readOnly size="small" />
                  <span>{`(${element.id})`}</span>
                </Box>
              }
              style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
            />
          ),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultReviewsSuggestions = () => {
    return [];
  };

  getPurchasesSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: IS, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                purchases(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Purchase {
                            id
                            amount {
                              float
                              currency
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.purchases.elements.map((element) => {
        return {
          name: `${helper.formatFloatNumber(element.amount.float)}${CURRENCIES[element.amount.currency]} (${
            element.id
          })`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPurchasesSuggestions = () => {
    return [];
  };

  getPointsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: IS, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                points(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Point {
                            id
                            used
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.points.elements.map((element) => {
        return {
          name: `${element.used ? '✔' : '❌'}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPointsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newBookingIds = newData.bookings ? newData.bookings.map((p) => (p.value ? p.value : p.id)) : [];
      const oldBookingIds = oldData && oldData.bookings ? oldData.bookings.map((p) => p.id) : [];

      const addedBookingIds = helper.diffArray(newBookingIds, oldBookingIds);

      for (const addedBookingId of addedBookingIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bookingId: addedBookingId,
          },
          mutation: gql`
            mutation assignBookingToPatient($id: ID!, $bookingId: ID!) {
              assignBookingToPatient(id: $id, bookingId: $bookingId) {
                id
              }
            }
          `,
        });
      }

      const removedBookingIds = helper.diffArray(oldBookingIds, newBookingIds);

      for (const removedBookingId of removedBookingIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            bookingId: removedBookingId,
          },
          mutation: gql`
            mutation unassignBookingFromPatient($id: ID!, $bookingId: ID!) {
              unassignBookingFromPatient(id: $id, bookingId: $bookingId) {
                id
              }
            }
          `,
        });
      }

      const newReviewIds = newData.reviews ? newData.reviews.map((p) => (p.value ? p.value : p.id)) : [];
      const oldReviewIds = oldData && oldData.reviews ? oldData.reviews.map((p) => p.id) : [];

      const addedReviewIds = helper.diffArray(newReviewIds, oldReviewIds);

      for (const addedReviewId of addedReviewIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            reviewId: addedReviewId,
          },
          mutation: gql`
            mutation assignReviewToPatient($id: ID!, $reviewId: ID!) {
              assignReviewToPatient(id: $id, reviewId: $reviewId) {
                id
              }
            }
          `,
        });
      }

      const removedReviewIds = helper.diffArray(oldReviewIds, newReviewIds);

      for (const removedReviewId of removedReviewIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            reviewId: removedReviewId,
          },
          mutation: gql`
            mutation unassignReviewFromPatient($id: ID!, $reviewId: ID!) {
              unassignReviewFromPatient(id: $id, reviewId: $reviewId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      staticData: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationHcpsData = async (query) => {
    try {
      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
        query {
            patient(id: "${this.state.paginationObject.id}"){
                  id
                  bookings {
                    lot {
                      hcp {
                        id
                        number
                        title
                        firstname
                        lastname
                      }
                    }
                  }
                }
              }
          `,
        fetchPolicy: 'no-cache',
      });
      return {
        data: result.data.patient.bookings
          .map((element) => {
            return element.lot?.hcp || null;
          })
          .filter((e) => e)
          // remove duplicates
          .filter((e, pos, ar) => ar.findIndex((arre) => arre.id === e.id) === pos),
      };
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('patients.id'),
              field: 'id',
              hidden: this.default('id'),
              // customSort: (a, b) => parseInt(a.id) - parseInt(b.id),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('patients.firstname'),
              field: 'firstname',
              hidden: this.default('firstname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('patients.lastname'),
              field: 'lastname',
              hidden: this.default('lastname'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('patients.phone'),
              field: 'phone',
              hidden: this.default('phone'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return rowData.phone ? <Link href={`tel:${rowData.phone}`}>{rowData.phone}</Link> : '-';
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('patients.birthday'),
              field: 'birthday',
              hidden: this.default('birthday'),
              sorting: true,
              filtering: true,
              type: 'date',
              comparator: 'LTE',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('patients.fraudScore'),
              field: 'fraudScore',
              hidden: this.default('fraudScore'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('patients.bookings'),
              field: 'bookings',
              hidden: this.default('bookings'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    const starts = v.starts.isoString
                      ? moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')
                      : '';
                    return {
                      name: `${starts}`,
                      value: v.id,
                    };
                  }),
                this.getBookingsSuggestions,
                this.getDefaultBookingsSuggestions
              ),
              render: (rowData) => {
                if (rowData && rowData.bookings) {
                  return rowData.bookings
                    ? rowData.bookings.map((booking) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.bookings'), data: { id: booking.id } }}
                          key={booking.id}
                          label={`${moment(booking.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        let name = v.name || '';
                        name = name === '' ? `${moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}` : name;
                        return {
                          name: `${name}`,
                          value: v.id,
                        };
                      })
                    : [],
                  i18n.get('patients.bookings.label'),
                  null,
                  this.getBookingsSuggestions,
                  this.getDefaultBookingsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('patients.reviews'),
              field: 'reviews',
              hidden: this.default('reviews'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: (
                        <Box display="flex">
                          <Rating value={v.rating} readOnly size="small" />
                        </Box>
                      ),
                      value: v.id,
                    };
                  }),
                this.getReviewsSuggestions,
                this.getDefaultReviewsSuggestions
              ),
              render: (rowData) => {
                if (rowData && rowData.reviews) {
                  return rowData.reviews
                    ? rowData.reviews.map((review) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.reviews'), data: { id: review.id } }}
                          key={review.id}
                          label={
                            <Box display="flex">
                              <Rating value={review.rating} readOnly size="small" />
                            </Box>
                          }
                          style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return {
                          name: (
                            <Chip
                              key={v.id}
                              label={
                                <Box display="flex">
                                  <Rating value={v.rating} readOnly size="small" />
                                </Box>
                              }
                              style={{ maxWidth: '300px', marginRight: 8, marginBottom: 8 }}
                            />
                          ),
                          value: v.id,
                        };
                      })
                    : [],
                  i18n.get('patients.reviews.label'),
                  null,
                  this.getReviewsSuggestions,
                  this.getDefaultReviewsSuggestions,
                  50
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('patients.purchases'),
              field: 'purchases',
              hidden: this.default('purchases'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${helper.formatFloatNumber(v.amount.float)}${CURRENCIES[v.amount.currency] || ''}`,
                      value: v.id,
                    };
                  }),
                this.getPurchasesSuggestions,
                this.getDefaultPurchasesSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData && rowData.purchases) {
                  return rowData.purchases
                    ? rowData.purchases.map((purchase) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.purchases'), data: { id: purchase.id } }}
                          key={purchase.id}
                          label={`${helper.formatFloatNumber(purchase.amount.float || '')}${
                            CURRENCIES[purchase.amount.currency] || ''
                          }`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
            },
            {
              title: i18n.get('patients.points'),
              field: 'points',
              hidden: this.default('points'),
              sorting: false,
              filtering: true,
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.used ? '✔' : '❌'}`,
                      value: v.id,
                    };
                  }),
                this.getPointsSuggestions,
                this.getDefaultPointsSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData && rowData.points) {
                  return rowData.points
                    ? rowData.points.map((point) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.points'), data: { id: point.id } }}
                          key={point.id}
                          label={`${point.used ? '✔' : '❌'}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
            },
            {
              title: i18n.get('patients.photo'),
              field: 'photo',
              hidden: this.default('photo'),
              sorting: false,
              filtering: false,
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData && rowData.photo?.thumbnail?.uri ? (
                    <ImagesViewer
                      photos={[
                        {
                          thumbnailUri: rowData.photo.thumbnail.uri,
                          originalUri: rowData.photo.original?.uri,
                        },
                      ]}
                    />
                  ) : (
                    ' - '
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          patients(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Patient {
                                  id,
                                  firstname
                                  lastname
                                  phone
                                  birthday {
                                    isoString
                                  }
                                  fraudScore
                                  bookings {
                                    id
                                    starts {
                                      isoString
                                    }
                                  }
                                  reviews {
                                    id
                                    rating
                                  }
                                  purchases {
                                    id
                                    amount {
                                      float
                                      currency
                                    }
                                  }
                                  points {
                                    id
                                    used
                                  }
                                  photo {
                                    thumbnail {
                                      uri
                                    }
                                    original {
                                      uri
                                    }
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.patients.elements.map((element) => ({
                    ...element,
                    birthday:
                      element.birthday && element.birthday.isoString
                        ? moment(element.birthday.isoString).toDate()
                        : null,
                  })),
                  totalCount: result.data.patients.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: PersonAddIcon,
              tooltip: i18n.get('menus.hcps'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  staticData: true,
                  paginationColumns: [
                    { title: i18n.get('hcps.number'), field: 'number' },
                    { title: i18n.get('hcps.title'), field: 'title', lookup: HCP_TITLES },
                    { title: i18n.get('hcps.firstname'), field: 'firstname' },
                    { title: i18n.get('hcps.lastname'), field: 'lastname' },
                  ],
                  paginationTitle: i18n.get('patients.hcps.pagination.title'),
                  fetchPaginationData: this.fetchPaginationHcpsData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'patients' }),
          ]}
          title={i18n.get('patients.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createPatient($id: ID!, $input: PatientInput!) {
                            createPatient(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('patients.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('patients.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updatePatient($id: ID!, $input: PatientInput!) {
                        updatePatient(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('patients.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('patients.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deletePatient($id: ID!) {
                        deletePatient(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('patients.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('patients.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          staticData={this.state.staticData}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Patients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Patients)));
