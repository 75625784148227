import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import EuroIcon from '@material-ui/icons/Euro';
import Chip from '@material-ui/core/Chip';
import ChipLink from '../components/ChipLink';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { STATUSES, CURRENCIES } from '../common/enums';
import { HCP_TITLES, HCP_TYPES, LEAD_TIMES } from '../common/enums';
import { withJournal } from '../common/journal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PaginationDialog from '../components/PaginationDialog';
import { STATUSES_COLORS } from '../common/colors';

const styles = (theme) => ({});

const defaults = {
  id: true,
  fee: false,
  label: false,
  tooltipEN: false,
  tooltipDE: false,
  maxMin: false,
  lotSizeMin: false,
  bookingSizeMin: false,
  minWeekAmount: false,
  maxWeekAmount: false,
  leadTime: false,
  weeks: false,
  capacityPerWeek: false,
  constraint: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.contractlevels.defaults';

class ContractLevels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  makeAmountInput = (amount) => {
    const splittedValue = amount.float.toString().split('.');
    return {
      value: parseInt(splittedValue[0]),
      fraction: parseInt(splittedValue[1]),
      float: parseFloat(amount.float),
      currency: 'EUR',
    };
  };

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.label) ||
        validator.isNumeric(toString(data.fee.float)) ||
        validator.isEmpty(data.tooltip.en) ||
        validator.isEmpty(data.tooltip.de) ||
        validator.isNumeric(toString(data.maxMin)) ||
        validator.isNumeric(toString(data.lotSizeMin)) ||
        validator.isNumeric(toString(data.bookingSizeMin)) ||
        validator.isNumeric(toString(data.minWeekAmount)) ||
        validator.isNumeric(toString(data.maxWeekAmount)) ||
        validator.isEmpty(data.leadTime) ||
        validator.isNumeric(toString(data.weeks)) ||
        validator.isNumeric(toString(data.capacityPerWeek))
      ) {
        throw new Error(i18n.get('contractlevels.error.validation'));
      }

      const input = {};

      helper.set(input, data, 'label');
      helper.set(input, data, 'fee', this.makeAmountInput);
      helper.set(input, data, 'tooltip');
      helper.set(input, data, 'maxMin', parseInt);
      helper.set(input, data, 'lotSizeMin', parseInt);
      helper.set(input, data, 'bookingSizeMin', parseInt);
      helper.set(input, data, 'minWeekAmount', parseInt);
      helper.set(input, data, 'maxWeekAmount', parseInt);
      helper.set(input, data, 'leadTime');
      helper.set(input, data, 'weeks', parseInt);
      helper.set(input, data, 'capacityPerWeek', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('contractlevels.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getConstraintsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LT, string: "${value}~"},{column: "id", comparator: GTE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                constraints(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Constraint {
                            id
                            label
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.constraints.elements.map((element) => {
        return {
          name: `${element.label}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultConstraintsSuggestions = () => {
    return [];
  };

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationHCPData = async (query) => {
    try {
      const where = `{column: "contractlevel_ref", comparator: IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            hcps(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on HCP {
                                  id
                                  number
                                  status
                                  title
                                  type
                                  firstname
                                  lastname  
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.hcps.elements,
        totalCount: result.data.hcps.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  fetchPaginationFeesData = async (query) => {
    try {
      const where = `{column:"contractlevel", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            fees(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Fee {
                                  id
                                  amount {
                                    float
                                    currency
                                  }
                                  netAmount {
                                    float
                                    currency
                                  }
                                  vat {
                                    float
                                    currency
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.fees.elements,
        totalCount: result.data.fees.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newConstraintIds = newData.constraints ? newData.constraints.map((p) => (p.value ? p.value : p.id)) : [];
      const oldConstraintIds = oldData && oldData.constraints ? oldData.constraints.map((p) => p.id) : [];

      const addedConstraintsIds = helper.diffArray(newConstraintIds, oldConstraintIds);

      for (const addedConstraintsId of addedConstraintsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            constraintId: addedConstraintsId,
          },
          mutation: gql`
            mutation assignConstraintToContractlevel($id: ID!, $constraintId: ID!) {
              assignConstraintToContractlevel(id: $id, constraintId: $constraintId) {
                id
              }
            }
          `,
        });
      }

      // these I need to remove
      const removedConstraintsIds = helper.diffArray(oldConstraintIds, newConstraintIds);

      for (const removedConstraintsId of removedConstraintsIds) {
        await graphql.clientWithToken(this.props.accessToken).mutate({
          variables: {
            id: newData.id,
            constraintId: removedConstraintsId,
          },
          mutation: gql`
            mutation unassignConstraintFromContractlevel($id: ID!, $constraintId: ID!) {
              unassignConstraintFromContractlevel(id: $id, constraintId: $constraintId) {
                id
              }
            }
          `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('contractlevels.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.label'),
              field: 'label',
              hidden: this.default('label'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.tooltip.en'),
              field: 'tooltip.en',
              hidden: this.default('tooltipEN'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.tooltip.de'),
              field: 'tooltip.de',
              hidden: this.default('tooltipEN'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.fee'),
              field: 'fee.float',
              hidden: this.default('fee'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'IS',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                return (
                  <span>{`${helper.formatFloatNumber(rowData.fee?.float)}${
                    CURRENCIES[rowData.fee?.currency] || ''
                  }`}</span>
                );
              },
            },
            {
              title: i18n.get('contractlevels.maxMin'),
              field: 'maxMin',
              hidden: this.default('maxMin'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 120,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.lotSizeMin'),
              field: 'lotSizeMin',
              hidden: this.default('lotSizeMin'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 100,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.bookingSizeMin'),
              field: 'bookingSizeMin',
              hidden: this.default('bookingSizeMin'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 120,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.minWeekAmount'),
              field: 'minWeekAmount',
              hidden: this.default('minWeekAmount'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 200,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.maxWeekAmount'),
              field: 'maxWeekAmount',
              hidden: this.default('maxWeekAmount'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 500,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.leadTime'),
              field: 'leadTime',
              hidden: this.default('leadTime'),
              lookup: LEAD_TIMES,
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('contractlevels.weeks'),
              field: 'weeks',
              hidden: this.default('weeks'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 100,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.capacityPerWeek'),
              field: 'capacityPerWeek',
              hidden: this.default('capacityPerWeek'),
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 40,
                });
              },
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('contractlevels.constraints'),
              field: 'constraints',
              hidden: this.default('constraints'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              render: (rowData) => {
                if (rowData.constraints) {
                  return rowData.constraints
                    ? rowData.constraints.map((constraint) => (
                        <ChipLink
                          link={{ page: i18n.get('menus.constraints'), data: { id: constraint.id } }}
                          key={constraint.id}
                          label={`${constraint.label}`}
                          style={{ marginRight: 8, marginBottom: 8 }}
                        />
                      ))
                    : '-';
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value
                    ? props.value.map((v) => {
                        return { name: `${v.label}`, value: v.id };
                      })
                    : [],
                  i18n.get('contractlevels.constraints.label'),
                  null,
                  this.getConstraintsSuggestions,
                  this.getDefaultConstraintsSuggestions,
                  50
                );
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          contractlevels(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Contractlevel {
                                  id,
                                  label
                                  tooltip {
                                    de
                                    en
                                  }
                                  fee {
                                    float
                                    currency
                                  }
                                  # tooltip
                                  maxMin
                                  lotSizeMin
                                  bookingSizeMin
                                  minWeekAmount
                                  maxWeekAmount
                                  leadTime
                                  weeks
                                  capacityPerWeek
                                  constraints {
                                    id
                                    label
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.contractlevels.elements,
                  totalCount: result.data.contractlevels.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: () => {
                return <PersonAddIcon />;
              },
              tooltip: i18n.get('contractlevels.hcps'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('hcps.id'), field: 'id', hidden: true },
                    { title: i18n.get('hcps.number'), field: 'number' },
                    {
                      title: i18n.get('hcps.status'),
                      field: 'status',
                      lookup: STATUSES,
                      render: (rowData) => {
                        return rowData.status ? (
                          <Chip
                            label={`${STATUSES[rowData.status]}`}
                            style={{
                              backgroundColor: STATUSES_COLORS[rowData.status],
                            }}
                          />
                        ) : (
                          '-'
                        );
                      },
                    },
                    { title: i18n.get('hcps.title'), field: 'title', lookup: HCP_TITLES },
                    { title: i18n.get('hcps.type'), field: 'type', lookup: HCP_TYPES },
                    { title: i18n.get('hcps.firstname'), field: 'firstname' },
                    { title: i18n.get('hcps.lastname'), field: 'lastname', sorting: false },
                  ],
                  paginationTitle: i18n.get('contractlevels.pagination.title'),
                  fetchPaginationData: this.fetchPaginationHCPData,
                  paginationObject: rowData,
                });
              },
            },
            {
              icon: EuroIcon,
              tooltip: i18n.get('menus.fees'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('fees.id'), field: 'id', hidden: true },
                    {
                      title: i18n.get('fees.amount'),
                      field: 'amount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.amount.float}${CURRENCIES[rowData.amount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('fees.netAmount'),
                      field: 'netAmount',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.netAmount.float}${CURRENCIES[rowData.netAmount.currency]}`}</span>;
                      },
                    },
                    {
                      title: i18n.get('fees.vat'),
                      field: 'vat',
                      type: 'numeric',
                      render: (rowData) => {
                        return <span>{`${rowData.vat.float}${CURRENCIES[rowData.vat.currency]}`}</span>;
                      },
                    },
                  ],
                  paginationTitle: i18n.get('contractlevels.fees.pagination.title'),
                  fetchPaginationData: this.fetchPaginationFeesData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'contractlevels' }),
          ]}
          title={i18n.get('contractlevels.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createContractlevel($id: ID!, $input: ContractlevelInput!) {
                            createContractlevel(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('contractlevels.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('contractlevels.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateContractlevel($id: ID!, $input: ContractlevelInput!) {
                        updateContractlevel(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('contractlevels.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('contractlevels.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteContractlevel($id: ID!) {
                        deleteContractlevel(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('contractlevels.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('contractlevels.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

ContractLevels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(ContractLevels)));
