import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ChipLink from '../components/ChipLink';
import Rating from '@material-ui/lab/Rating';
import ReloadIcon from '@material-ui/icons/Replay';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  rating: false,
  description: false,
  fraudScore: false,
  anonymous: false,
  booking: false,
  patient: false,
  hcp: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.reviews.defaults';

class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      working: false,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isNumeric(toString(data.rating)) ||
        validator.isNumeric(toString(data.fraudScore)) ||
        validator.isEmpty(data.description)
      ) {
        throw new Error(i18n.get('reviews.error.validation'));
      }

      const input = {
        rating: parseInt(data.rating),
        description: data.description,
        anonymous: data.anonymous || false,
      };

      helper.set(input, data, 'fraudScore', parseInt);

      return input;
    } catch (error) {
      throw new Error(i18n.get('reviews.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPatientsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                patients(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Patient {
                            id
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.patients.elements.map((element) => {
        return {
          name: `${element.firstname} ${element.lastname}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPatientsSuggestions = () => {
    return [];
  };

  getBookingsSuggestions = async (value) => {
    try {
      const where = `[{column: "id", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                bookings(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Booking {
                            id
                            starts {
                              isoString
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.bookings.elements.map((element) => {
        return {
          name: `${moment(element.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultBookingsSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"},{column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newBookingID =
        newData.booking && newData.booking.length > 0
          ? newData.booking[0].value
          : newData.booking
          ? newData.booking.id
          : null;
      const oldBookingID = oldData && oldData.booking ? oldData.booking.id : null;

      if (newBookingID !== oldBookingID) {
        if (oldBookingID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldBookingID,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation unassignReviewFromBooking($id: ID!, $reviewId: ID!) {
                unassignReviewFromBooking(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }

        if (newBookingID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newBookingID,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation assignReviewToBooking($id: ID!, $reviewId: ID!) {
                assignReviewToBooking(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newPatientID =
        newData.patient && newData.patient.length > 0
          ? newData.patient[0].value
          : newData.patient
          ? newData.patient.id
          : null;
      const oldPatientID = oldData && oldData.patient ? oldData.patient.id : null;

      if (newPatientID !== oldPatientID) {
        if (oldPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPatientID,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation unassignReviewFromPatient($id: ID!, $reviewId: ID!) {
                unassignReviewFromPatient(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }

        if (newPatientID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPatientID,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation assignReviewToPatient($id: ID!, $reviewId: ID!) {
                assignReviewToPatient(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newHCPId =
        newData.hcp && newData.hcp.length > 0 ? newData.hcp[0].value : newData.hcp ? newData.hcp.id : null;
      const oldHCPId = oldData && oldData.hcp ? oldData.hcp.id : null;

      if (newHCPId !== oldHCPId) {
        if (oldHCPId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldHCPId,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation unassignReviewFromHCP($id: ID!, $reviewId: ID!) {
                unassignReviewFromHCP(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }

        if (newHCPId) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newHCPId,
              reviewId: newData.id,
            },
            mutation: gql`
              mutation assignReviewToHCP($id: ID!, $reviewId: ID!) {
                assignReviewToHCP(id: $id, reviewId: $reviewId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('reviews.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('reviews.rating'),
              field: 'rating',
              hidden: this.default('rating'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              comparator: 'LTE',
              filterComponent: helper.RatingFilter,
              render: (rowData) => {
                return <Rating value={rowData.rating} readOnly />;
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: (props) => {
                return (
                  <Rating
                    value={props.value === undefined ? '' : props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                  />
                );
              },
            },
            {
              title: i18n.get('reviews.description'),
              field: 'description',
              hidden: this.default('description'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('reviews.anonymous'),
              field: 'anonymous',
              hidden: this.default('anonymous'),
              sorting: true,
              filtering: true,
              type: 'boolean',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('reviews.fraudScore'),
              field: 'fraudScore',
              hidden: this.default('fraudScore'),
              sorting: true,
              filtering: true,
              type: 'numeric',
              filterComponent: (props) => {
                return helper.NumberRangeFilter({
                  ...props,
                  max: 1000,
                });
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('reviews.booking'),
              field: 'booking',
              hidden: this.default('booking'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${moment(v.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                      value: v.id,
                    };
                  }),
                this.getBookingsSuggestions,
                this.getDefaultBookingsSuggestions
              ),
              editable: 'never',
              render: (rowData) => {
                if (rowData) {
                  return rowData.booking ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.bookings'), data: { id: rowData.booking.id } }}
                      key={rowData.booking.id}
                      label={`${moment(rowData.booking.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${moment(props.value.starts.isoString).format('DD.MM.YYYY, hh:mm [Uhr]')}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('reviews.booking.label'),
                  i18n.get('required'),
                  this.getBookingsSuggestions,
                  this.getDefaultBookingsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('reviews.patient'),
              field: 'patient',
              hidden: this.default('patient'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.firstname} ${v.lastname}`,
                      value: v.id,
                    };
                  }),
                this.getPatientsSuggestions,
                this.getDefaultPatientsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.patient ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.customers'), data: { id: rowData.patient.id } }}
                      key={rowData.patient.id}
                      label={`${rowData.patient.firstname} ${rowData.patient.lastname}`}
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.firstname} ${props.value.lastname}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('reviews.patient.label'),
                  i18n.get('required'),
                  this.getPatientsSuggestions,
                  this.getDefaultPatientsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('reviews.hcp'),
              field: 'hcp',
              hidden: this.default('hcp'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                return helper.hcpChip(rowData.hcp);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.hcpChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('reviews.hcp.label'),
                  i18n.get('required'),
                  this.getHCPsSuggestions,
                  this.getDefaultHCPsSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          reviews(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Review {
                                  id,
                                  rating
                                  description
                                  anonymous
                                  fraudScore
                                  booking {
                                    id
                                    starts {
                                      isoString
                                    }
                                  }
                                  patient {
                                    id
                                    firstname
                                    lastname
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.reviews.elements,
                  totalCount: result.data.reviews.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            this.props.getJournalAction({ collection: 'reviews' }),
          ]}
          title={i18n.get('reviews.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createReview($id: ID!, $input: ReviewInput!) {
                            createReview(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('reviews.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('reviews.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateReview($id: ID!, $input: ReviewInput!) {
                        updateReview(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('reviews.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('reviews.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteReview($id: ID!) {
                        deleteReview(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('reviews.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('reviews.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </div>
    );
  }
}

Reviews.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Reviews)));
