// authProvider.js
import React, { useState, useEffect, useContext } from 'react';
import * as graphql from './graphql';
import { firebaseApp } from './firebase';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ErrorContext } from '../common/error';
import { setWelcomeSettings } from '../components/WelcomeDialog';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_SUPPORT = 'SUPPORT';
export const ROLE_FINANCE = 'FINANCE';
export const ROLE_TECHNICAL = 'TECHNICAL';

export const ROLES = [ROLE_ADMIN, ROLE_SUPPORT, ROLE_FINANCE, ROLE_TECHNICAL];

export function isInRoles(userRoles, roles) {
  if (roles === null || userRoles === null) {
    return false;
  }

  let contains = false;

  for (const userRole of userRoles) {
    contains = contains || roles.includes(userRole);
  }

  return contains;
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const IAMContext = React.createContext();

export default function IAMContextProvider(props) {
  const [user, loading] = useAuthState(firebaseApp.auth());
  const [init, setInit] = useState(false);
  const [token, setToken] = useState(null);
  const [roles, setRoles] = useState(null);

  const errorContext = useContext(ErrorContext);

  const handleLogout = () => {
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        setToken(null);
        setWelcomeSettings(true);
      });
  };

  useEffect(() => {
    if (!loading && !user) {
      setInit(true);
    }
  }, [loading, user]);

  useEffect(() => {
    if (user) {
      firebaseApp
        .auth()
        .currentUser.getIdToken()
        .then((t) => {
          setToken(t);
          setInit(true);
        });
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      graphql
        .clientWithToken(token)
        .query({
          query: gql`
            query {
              me {
                roles
              }
            }
          `,
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          setRoles(result.data.me.roles);
        })
        .catch((error) => {
          errorContext.handleError(error);
        });
    }
  }, [token, errorContext]);

  const getToken = () => {
    if (user) {
      const decoded = parseJwt(token);
      if (moment().unix() > decoded.exp) {
        firebaseApp
          .auth()
          .currentUser.getIdToken()
          .then((t) => {
            setToken(t);
            setInit(true);
            return t;
          });
      }
      return token;
    }
    return token;
  };

  const render = () => {
    if (!init) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const isAuth = !loading && user && token;

    if (!isAuth) {
      window.location = '/login';
      return null;
    } else if (!roles) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return props.children;
    }
  };

  return (
    <IAMContext.Provider
      value={{
        loading,
        user,
        token,
        roles,
        getToken,
        handleLogout,
      }}
    >
      {render()}
    </IAMContext.Provider>
  );
}

export function withIAM(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <IAMContext.Consumer>
          {({ user, token, roles, handleLogout, getToken }) => {
            return (
              <WrappedComponent
                onLogout={handleLogout}
                user={user}
                accessToken={getToken()}
                roles={roles}
                {...this.props}
              />
            );
          }}
        </IAMContext.Consumer>
      );
    }
  };
}
