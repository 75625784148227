import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Message from '../components/Message';

const styles = (theme) => ({});

export const ErrorContext = React.createContext();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return error ? { hasError: true, error: error } : { hasError: false, error: null };
  }

  componentDidCatch(error, errorInfo) {}

  handleError = async (error) => {
    this.setState({
      hasError: true,
      error: error,
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '120px',
            }}
          >
            <span role="img" aria-label="">
              🤭
            </span>
          </div>
          <Message
            open={true}
            message={
              'Es ist ein Fehler aufgetreten. Laden Sie die Seite neu und wenden Sie sich an den Support sofern der Fehler wieder auftritt. Hier sind weitere technische Details zum Fehler:'
            }
            details={this.state.error.message}
            variant="error"
            onClose={() => {
              window.location.reload();
            }}
            hideAfter={50000}
          />
        </React.Fragment>
      );
    }

    return (
      <ErrorContext.Provider
        value={{
          handleError: this.handleError,
        }}
      >
        {this.props.children}
      </ErrorContext.Provider>
    );
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ErrorContextConsumer = ErrorContext.Consumer;
export default withStyles(styles, { withTheme: true })(ErrorBoundary);
