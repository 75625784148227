import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withIAM } from '../common/iamV2';
import MarkdownEditor from '../components/MarkdownEditor';
import gql from 'graphql-tag';
import * as i18n from '../common/i18n';

const styles = (theme) => ({});

class Tos extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MarkdownEditor
          successMessage={i18n.get('tos.message.success')}
          query={{
            query: gql`
              query {
                md: tos
              }
            `,
            fetchPolicy: 'no-cache',
          }}
          mutate={(md) => {
            return {
              variables: {
                md: md,
              },
              mutation: gql`
                mutation updateTos($md: String) {
                  md: updateTos(md: $md)
                }
              `,
            };
          }}
        />
      </div>
    );
  }
}

Tos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(Tos));
