import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as i18n from '../common/i18n';
import ImageUpload from './ImageUpload';
import { auth, storage } from '../common/firebase';
import { withIAM } from '../common/iamV2';
import withMessages from '../common/withMessages';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

function ChangeProfilePictureDialog(props) {
  const [progress, setProgress] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = async () => {
    await setProgress(true);
    try {
      const ref = storage.ref('users/' + props.user.uid + '/profile.jpg');
      if (file) {
        await ref.put(file);
        const url = await ref.getDownloadURL();
        auth.currentUser.updateProfile({
          photoURL: url,
        });
      } else {
        await ref.delete();
        auth.currentUser.updateProfile({
          photoURL: null,
        });
      }
      setChanged(false);
      // image reload fix
      setTimeout(() => {
        handleClose();
        setProgress(false);
        props.showMessage(i18n.get('changeProfilePicDialog.message.update.success'), 'success');
      }, 500);
    } catch (error) {
      props.showMessage(i18n.get('changeProfilePicDialog.message.update.error'), 'error', error.message);
    }
  };

  const handleOnDrop = (files) => {
    setFile(files[0]);
    setChanged(true);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        aria-labelledby="change-profile-pic-dialog-title"
        aria-describedby="change-profile-pic-dialog-description"
      >
        <DialogTitle id="change-profile-pic-dialog-title">{i18n.get('changeProfilePicDialog.title')}</DialogTitle>
        <DialogContent className={classes.content}>
          {progress && <CircularProgress />}
          <ImageUpload onDrop={handleOnDrop} value={{ uri: props.user.photoURL }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={progress} color="secondary" variant="contained">
            {i18n.get('changeProfilePicDialog.action.cancel')}
          </Button>
          <Button onClick={handleSubmit} disabled={!changed || progress} color="primary" variant="contained">
            {i18n.get('changeProfilePicDialog.action.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withIAM(withMessages(ChangeProfilePictureDialog));
