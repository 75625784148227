import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as i18n from '../common/i18n';
import { auth } from '../common/firebase';

import Message from './Message';

class ChangeNameDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      name: this.props.placeholder || '',
    };
  }

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = async () => {
    const { name } = this.state;
    if (name.length > 2) {
      try {
        await auth.currentUser.updateProfile({
          displayName: name,
        });
        this.showMessage(i18n.get('changeNameDialog.action.success'), 'success');
        this.props.closeChangeNameDialog();
      } catch (error) {
        this.showMessage(i18n.get('changeNameDialog.action.error'), 'error', error.message);
      }
    } else {
      this.showMessage(i18n.get('changeNameDialog.validation.error'), 'error');
    }
  };

  render() {
    const { name } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open={this.props.isChangeNameOpened}
          onClose={this.props.closeChangeNameDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{i18n.get('changeNameDialog.title')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              label={i18n.get('changeNameDialog.field.name.label')}
              name="name"
              value={name}
              onChange={this.onFieldChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.props.closeChangeNameDialog} color="secondary">
              {i18n.get('changeNameDialog.action.cancel')}
            </Button>
            <Button variant="contained" disabled={!(name.length > 0)} onClick={this.submitForm} color="primary">
              {i18n.get('changeNameDialog.action.submit')}
            </Button>
          </DialogActions>
        </Dialog>
        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
      </React.Fragment>
    );
  }
}

export default ChangeNameDialog;
