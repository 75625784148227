import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import ChipLink from '../components/ChipLink';
import * as validator from 'validator';
import { Countries } from '../common/enums';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PaginationDialog from '../components/PaginationDialog';
import GoogleMap from '../components/Map';
import moment from 'moment';

const styles = (theme) => ({});

const defaults = {
  id: true,
  bsnr: false,
  street: false,
  zipCode: false,
  // geoLat: false,
  // geoLong: false,
  // geoHash: false,
  city: false,
  country: false,
  practice: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.locations.defaults';

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.country) ||
        validator.isEmpty(data.bsnr) ||
        validator.isEmpty(data.street) ||
        // validator.isNumeric(toString(data.geoPosition.lat)) ||
        // validator.isNumeric(toString(data.geoPosition.long)) ||
        // validator.isEmpty(data.geoPosition.hash) ||
        validator.isEmpty(data.zipCode)
      ) {
        throw new Error(i18n.get('locations.error.validation'));
      }

      const input = {
        // geoPosition: {
        //   lat: parseFloat(data.geoPosition.lat),
        //   long: parseFloat(data.geoPosition.long),
        //   hash: data.geoPosition.hash,
        // },
      };

      helper.set(input, data, 'bsnr');
      helper.set(input, data, 'street');
      helper.set(input, data, 'country');
      helper.set(input, data, 'zipCode');

      return input;
    } catch (error) {
      throw new Error(i18n.get('locations.error.validation'));
    }
  };

  mapField(column) {
    return column;
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  fetchPaginationLotsData = async (query) => {
    try {
      const where = `{column:"location", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'id');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            lots(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Lot {
                                  id,
                                  starts {
                                    isoString
                                  }
                                  ends {
                                    isoString
                                  }
                                  hcp {
                                    id
                                    number
                                    status
                                    title
                                    firstname
                                    lastname
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.lots.elements.map((element) => ({
          ...element,
          starts: element.starts.isoString ? moment(element.starts.isoString).toDate() : null,
          ends: element.ends.isoString ? moment(element.ends.isoString).toDate() : null,
        })),
        totalCount: result.data.lots.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            number
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getCitiesSuggestions = async (value) => {
    try {
      const where = `[{column: "name_en", comparator: LIKE_OR, string: "${value}"}, {column: "name_de", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                cities(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on City {
                            id
                            name {
                              de
                            }
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.cities.elements.map((element) => {
        return {
          name: `${element.name.de}`,
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultCitiesSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              locationId: newData.id,
            },
            mutation: gql`
              mutation unassignLocationFromPractice($id: ID!, $locationId: ID!) {
                unassignLocationFromPractice(id: $id, locationId: $locationId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              locationId: newData.id,
            },
            mutation: gql`
              mutation assignLocationToPractice($id: ID!, $locationId: ID!) {
                assignLocationToPractice(id: $id, locationId: $locationId) {
                  id
                }
              }
            `,
          });
        }
      }

      const newCityID =
        newData.city && newData.city.length > 0 ? newData.city[0].value : newData.city ? newData.city.id : null;
      const oldCityID = oldData && oldData.city ? oldData.city.id : null;

      if (newCityID !== oldCityID) {
        if (oldCityID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldCityID,
              locationId: newData.id,
            },
            mutation: gql`
              mutation unassignLocationFromCity($id: ID!, $locationId: ID!) {
                unassignLocationFromCity(id: $id, locationId: $locationId) {
                  id
                }
              }
            `,
          });
        }

        if (newCityID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newCityID,
              locationId: newData.id,
            },
            mutation: gql`
              mutation assignLocationToCity($id: ID!, $locationId: ID!) {
                assignLocationToCity(id: $id, locationId: $locationId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('locations.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('locations.bsnr'),
              field: 'bsnr',
              hidden: this.default('bsnr'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('locations.street'),
              field: 'street',
              hidden: this.default('street'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('locations.zipCode'),
              field: 'zipCode',
              hidden: this.default('zipCode'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            // {
            //   title: i18n.get('locations.geoPosition.lat'),
            //   field: 'geoPosition.lat',
            //   hidden: this.default('geoLat'),
            //   sorting: true,
            //   filtering: true,
            //   type: 'numeric',
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            // {
            //   title: i18n.get('locations.geoPosition.long'),
            //   field: 'geoPosition.long',
            //   hidden: this.default('geoLong'),
            //   sorting: true,
            //   filtering: true,
            //   type: 'numeric',
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            // {
            //   title: i18n.get('locations.geoPosition.hash'),
            //   field: 'geoPosition.hash',
            //   hidden: this.default('geoHash'),
            //   sorting: true,
            //   filtering: true,
            //   editable: 'never',
            //   cellStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   headerStyle: {
            //     width: '400px',
            //     maxWidth: '400px',
            //   },
            //   editComponent: helper.required,
            // },
            {
              title: i18n.get('locations.city'),
              field: 'city',
              hidden: this.default('city'),
              sorting: false,
              filtering: true,
              comparator: 'IN',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: `${v.name.de}`,
                      value: v.id,
                    };
                  }),
                this.getCitiesSuggestions,
                this.getDefaultCitiesSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.city ? (
                    <ChipLink
                      link={{ page: i18n.get('menus.cities'), data: { id: rowData.city.id } }}
                      key={rowData.city.id}
                      label={`${rowData.city.name.de}`}
                    />
                  ) : (
                    '-'
                  );
                }
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: `${props.value.name.de}`,
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('locations.city.label'),
                  i18n.get('required'),
                  this.getCitiesSuggestions,
                  this.getDefaultCitiesSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('locations.country'),
              field: 'country',
              hidden: this.default('country'),
              sorting: true,
              filtering: true,
              lookup: Countries,
              initialEditValue: 'DE',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('locations.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IN',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('locations.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'bsnr', ['bsnr']);

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          locations(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on Location {
                                  id,
                                  bsnr
                                  street,
                                  zipCode,
                                  geoPosition {
                                    lat
                                    long
                                    hash
                                  }
                                  city {
                                    id
                                    name { de }
                                  }
                                  country
                                  practice {
                                    id
                                    number
                                    status
                                    name
                                    legalEntity
                                  }
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.locations.elements,
                  totalCount: result.data.locations.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: EventNoteIcon,
              tooltip: i18n.get('menus.lots'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('lots.id'), field: 'id', hidden: true },
                    {
                      title: i18n.get('lots.starts'),
                      field: 'starts',
                      type: 'datetime',
                    },
                    {
                      title: i18n.get('lots.ends'),
                      field: 'ends',
                      type: 'datetime',
                    },
                    {
                      title: i18n.get('lots.hcp'),
                      field: 'hcp',
                      render: (rowData) => {
                        return helper.hcpChip(rowData.hcp);
                      },
                    },
                  ],
                  paginationTitle: i18n.get('practices.lots.pagination.title'),
                  fetchPaginationData: this.fetchPaginationLotsData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'locations' }),
          ]}
          title={i18n.get('locations.title')}
          detailPanel={(rowData) => {
            return <GoogleMap lat={rowData.geoPosition?.lat} long={rowData.geoPosition?.long} />;
          }}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            // isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            // onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
            //   ? (newData) =>
            //       new Promise(async (resolve, reject) => {
            //         try {
            //           const id = newData.id;
            //           const input = this.getInput(newData);

            //           await graphql.clientWithToken(this.props.accessToken).mutate({
            //             variables: {
            //               input: input,
            //               id: id,
            //             },
            //             mutation: gql`
            //               mutation createLocation($id: ID!, $input: LocationInput!) {
            //                 createLocation(id: $id, input: $input) {
            //                   id
            //                 }
            //               }
            //             `,
            //           });

            //           await this.updateRelationships(newData);

            //           this.showMessage(i18n.get('locations.message.onRowAdd.success'), 'success');

            //           resolve();
            //         } catch (error) {
            //           this.showMessage(i18n.get('locations.message.onRowAdd.error'), 'error', error.message);
            //           reject(error);
            //         }
            //       })
            //   : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateLocation($id: ID!, $input: LocationInput!) {
                        updateLocation(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  // assign it
                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('locations.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('locations.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            // onRowDelete: (oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     try {
            //       const result = await graphql.clientWithToken(this.props.accessToken).mutate({
            //         variables: {
            //           id: oldData.id,
            //         },
            //         mutation: gql`
            //           mutation deleteLocation($id: ID!) {
            //             deleteLocation(id: $id)
            //           }
            //         `,
            //       });

            //       this.showMessage(i18n.get('locations.message.onRowDelete.success'), 'success');

            //       resolve(result);
            //     } catch (error) {
            //       this.showMessage(i18n.get('locations.message.onRowDelete.error'), 'error', error.message);
            //       reject(error);
            //     }
            //   }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(Locations)));
