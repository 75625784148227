import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as i18n from '../common/i18n';
import MaterialTable from '../components/MaterialTable';
import { reactLocalStorage } from 'reactjs-localstorage';

import moment from 'moment';
import gql from 'graphql-tag';
import Message from '../components/Message';
import * as graphql from '../common/graphql';
import * as helper from '../common/helper';
import ReloadIcon from '@material-ui/icons/Replay';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import PaginationDialog from '../components/PaginationDialog';
import * as validator from 'validator';
import { withIAM, isInRoles, ROLE_ADMIN, ROLE_SUPPORT } from '../common/iamV2';
import { withJournal } from '../common/journal';

const styles = (theme) => ({});

const defaults = {
  id: true,
  owner: false,
  bic: false,
  iban: false,
  institute: false,
  practice: false,
  hcps: false,
  pageSize: 5,
};

const defaultsPropertyName = 'p37.bankAccounts.defaults';

class BankAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
      building: null,
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
      userSettings: reactLocalStorage.getObject(defaultsPropertyName, defaults),
    };

    this.tableRef = React.createRef();
  }

  handlePaginationCancel = () => {
    this.setState({
      pagination: false,
      paginationObject: null,
      paginationColumns: null,
      paginationTitle: null,
      fetchPaginationData: null,
    });
  };

  fetchPaginationTransfersData = async (query) => {
    try {
      const where = `{column:"bankAccount", comparator:IS, string: "${this.state.paginationObject.id}"}`;
      const orderBy = helper.orderByString(query, this.mapField, 'hcp');

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
                        query {
                            transfers(first: ${query.pageSize}, offset: ${
          query.pageSize * query.page
        }, orderBy:${orderBy}, where:${where}){
                              totalCount
                              elements {
                                ... on Transfer {
                                  id
                                  date {
                                    isoString
                                  }
                                }
                              }
                            }
                          }
                        `,
        fetchPolicy: 'no-cache',
      });

      return {
        page: query.page,
        data: result.data.transfers.elements.map((element) => ({
          ...element,
          date: element.date.isoString ? moment(element.date.isoString).toDate() : null,
        })),
        totalCount: result.data.transfers.totalCount,
      };
    } catch (error) {
      throw error;
    }
  };

  default(id) {
    return this.state.userSettings[id] !== undefined ? this.state.userSettings[id] : defaults[id];
  }

  setDefault(id, value) {
    const newUserSettings = { ...this.state.userSettings };
    newUserSettings[id] = value;

    this.setState({ userSettings: newUserSettings }, () => {
      reactLocalStorage.setObject(defaultsPropertyName, newUserSettings);
    });
  }

  getInput = (data) => {
    try {
      if (
        validator.isEmpty(data.id) ||
        validator.isEmpty(data.owner) ||
        validator.isEmpty(data.bic) ||
        validator.isEmpty(data.iban) ||
        validator.isEmpty(data.institute)
      ) {
        throw new Error(i18n.get('bankAccounts.error.validation'));
      }

      const input = {};

      helper.set(input, data, 'bic');
      helper.set(input, data, 'owner');
      helper.set(input, data, 'iban');
      helper.set(input, data, 'institute');

      return input;
    } catch (error) {
      throw new Error(i18n.get('bankAccounts.error.validation'));
    }
  };

  mapField(column) {
    switch (column) {
      case 'hcps':
        return 'hcps_search_string';
      default:
        return column;
    }
  }

  showMessage = (message, variant, details, hideAfter) => {
    this.setState({
      message: message,
      variant: variant ? variant : 'info',
      hideAfter: hideAfter ? hideAfter : 3000,
      details: details ? details : null,
    });
  };

  closeMessage = () => {
    this.setState({
      message: null,
      details: null,
      hideAfter: 3000,
      variant: 'info',
    });
  };

  getPracticesSuggestions = async (value) => {
    try {
      const where = `[{column: "name", comparator: LIKE, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                practices(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on Practice {
                            id
                            status
                            name
                            legalEntity
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.practices.elements.map((element) => {
        return {
          name: helper.practiceChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultPracticesSuggestions = () => {
    return [];
  };

  getHCPsSuggestions = async (value) => {
    try {
      const where = `[{column: "firstname", comparator: LIKE_OR, string: "${value}"}, {column: "lastname", comparator: LIKE_OR, string: "${value}"}]`;

      const result = await graphql.clientWithToken(this.props.accessToken).query({
        query: gql`
            query {
                hcps(first: 5, offset: 0, where:${where}) {
                    totalCount
                    elements {
                        ... on HCP {
                            id
                            status
                            title
                            firstname
                            lastname
                       }
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
      });

      return result.data.hcps.elements.map((element) => {
        return {
          name: helper.hcpChip(element, true),
          value: element.id,
        };
      });
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  getDefaultHCPsSuggestions = () => {
    return [];
  };

  updateRelationships = async (newData, oldData) => {
    try {
      const newPracticeID =
        newData.practice && newData.practice.length > 0
          ? newData.practice[0].value
          : newData.practice
          ? newData.practice.id
          : null;
      const oldPracticeID = oldData && oldData.practice ? oldData.practice.id : null;

      if (newPracticeID !== oldPracticeID) {
        if (oldPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: oldPracticeID,
              bankAccountId: newData.id,
            },
            mutation: gql`
              mutation unassignBankAccountFromPractice($id: ID!, $bankAccountId: ID!) {
                unassignBankAccountFromPractice(id: $id, bankAccountId: $bankAccountId) {
                  id
                }
              }
            `,
          });
        }

        if (newPracticeID) {
          await graphql.clientWithToken(this.props.accessToken).mutate({
            variables: {
              id: newPracticeID,
              bankAccountId: newData.id,
            },
            mutation: gql`
              mutation assignBankAccountToPractice($id: ID!, $bankAccountId: ID!) {
                assignBankAccountToPractice(id: $id, bankAccountId: $bankAccountId) {
                  id
                }
              }
            `,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          components={{
            Cell: helper.defaultCell,
            FilterRow: helper.defaultFilter,
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setDefault('pageSize', pageSize);
          }}
          onChangeColumnHidden={(data) => {
            this.setDefault(data['field'], data['hidden']);
          }}
          tableRef={this.tableRef}
          columns={[
            {
              title: i18n.get('bankAccounts.id'),
              field: 'id',
              hidden: this.default('id'),
              sorting: true,
              filtering: true,
              defaultFilter: (this.props.pageData && this.props.pageData.id) || null,
              editable: 'onAdd',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bankAccounts.owner'),
              field: 'owner',
              hidden: this.default('owner'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bankAccounts.bic'),
              field: 'bic',
              hidden: this.default('bic'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bankAccounts.iban'),
              field: 'iban',
              hidden: this.default('iban'),
              sorting: true,
              filtering: true,
              defaultSort: 'asc',
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bankAccounts.institute'),
              field: 'institute',
              hidden: this.default('institute'),
              sorting: true,
              filtering: true,
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              editComponent: helper.required,
            },
            {
              title: i18n.get('bankAccounts.practice'),
              field: 'practice',
              hidden: this.default('practice'),
              sorting: false,
              filtering: true,
              comparator: 'IS_OR',
              filterComponent: helper.chipsFilter(
                (items, props) =>
                  props.onFilterChanged(
                    props.columnDef.tableData.id,
                    items.map((item) => `${item.value}`)
                  ),
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.practiceChip(v),
                      value: v.id,
                    };
                  }),
                this.getPracticesSuggestions,
                this.getDefaultPracticesSuggestions
              ),
              render: (rowData) => {
                return helper.practiceChip(rowData.practice);
              },
              editComponent: (props) => {
                return helper.chips(
                  props,
                  props.value && props.value.id
                    ? [
                        {
                          name: helper.practiceChip(props.value, true),
                          value: props.value.id,
                        },
                      ]
                    : [],
                  i18n.get('bankAccounts.practice.label'),
                  i18n.get('required'),
                  this.getPracticesSuggestions,
                  this.getDefaultPracticesSuggestions
                );
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
            {
              title: i18n.get('bankAccounts.hcps'),
              field: 'hcps',
              hidden: this.default('hcps'),
              sorting: false,
              filtering: true,
              editable: 'never',
              filterComponent: helper.chipsFilter(
                null,
                (value) =>
                  value.map((v) => {
                    return {
                      name: helper.hcpChip(v),
                      value: v.id,
                    };
                  }),
                this.getHCPsSuggestions,
                this.getDefaultHCPsSuggestions
              ),
              render: (rowData) => {
                if (rowData) {
                  return rowData.hcps ? rowData.hcps.map((hcp) => helper.hcpChip(hcp)) : '-';
                }
              },
              cellStyle: {
                width: '400px',
                maxWidth: '400px',
              },
              headerStyle: {
                width: '400px',
                maxWidth: '400px',
              },
            },
          ]}
          data={(query) =>
            new Promise(async (resolve, reject) => {
              try {
                const where = helper.whereString(query['filters'], this.mapField, ['id']);
                const orderBy = helper.orderByString(query, this.mapField, 'id');

                const result = await graphql.clientWithToken(this.props.accessToken).query({
                  query: gql`
                        query {
                          bankAccounts(first: ${query.pageSize},
                           offset: ${query.pageSize * query.page}, orderBy:${orderBy}, where:${where}) 
                           {
                              totalCount
                              elements {
                                ... on BankAccount {
                                  id
                                  owner
                                  bic
                                  iban
                                  institute
                                  practice {id number status name legalEntity}
                                  hcps {id number status title firstname lastname}
                                }
                              }
                            }
                          }
                        `,
                  fetchPolicy: 'no-cache',
                });

                const searchResult = {
                  page: query.page,
                  data: result.data.bankAccounts.elements,
                  totalCount: result.data.bankAccounts.totalCount,
                };
                resolve(searchResult);
              } catch (error) {
                this.showMessage(error.message, 'error');
                reject(error);
              }
            })
          }
          actions={[
            {
              isFreeAction: true,
              icon: () => {
                return <ReloadIcon />;
              },
              tooltip: i18n.get('general.reload'),
              onClick: async (event, rowData) => {
                this.tableRef.current.onQueryChange();
              },
            },
            {
              icon: SyncAltIcon,
              tooltip: i18n.get('menus.transfers'),
              onClick: (event, rowData) => {
                this.setState({
                  pagination: true,
                  paginationColumns: [
                    { title: i18n.get('transfers.id'), field: 'id', hidden: false },
                    {
                      title: i18n.get('transfers.date'),
                      field: 'date',
                      type: 'datetime',
                    },
                  ],
                  paginationTitle: i18n.get('practices.transfers.pagination.title'),
                  fetchPaginationData: this.fetchPaginationTransfersData,
                  paginationObject: rowData,
                });
              },
            },
            this.props.getJournalAction({ collection: 'bankAccounts' }),
          ]}
          title={i18n.get('bankAccounts.title')}
          options={{
            resetFiltersButton: true,
            actionsColumnIndex: -1,
            addRowPosition: 'first',
            exportButton: false,
            filtering: true,
            showTitle: false,
            debounceInterval: 500,
            search: false,
            columnsButton: true,
            pageSize: this.default('pageSize'),
          }}
          editable={{
            isEditable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN, ROLE_SUPPORT]),
            isDeletable: (rowData) => isInRoles(this.props.roles, [ROLE_ADMIN]),
            onRowAdd: isInRoles(this.props.roles, [ROLE_ADMIN])
              ? (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const id = newData.id;
                      const input = this.getInput(newData);

                      await graphql.clientWithToken(this.props.accessToken).mutate({
                        variables: {
                          input: input,
                          id: id,
                        },
                        mutation: gql`
                          mutation createBankAccount($id: ID!, $input: BankAccountInput!) {
                            createBankAccount(id: $id, input: $input) {
                              id
                            }
                          }
                        `,
                      });

                      await this.updateRelationships(newData);

                      this.showMessage(i18n.get('bankAccounts.message.onRowAdd.success'), 'success');

                      resolve();
                    } catch (error) {
                      this.showMessage(i18n.get('bankAccounts.message.onRowAdd.error'), 'error', error.message);
                      reject(error);
                    }
                  })
              : null,
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const input = this.getInput(newData);

                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: newData.id,
                      input: input,
                    },
                    mutation: gql`
                      mutation updateBankAccount($id: ID!, $input: BankAccountInput!) {
                        updateBankAccount(id: $id, input: $input) {
                          id
                        }
                      }
                    `,
                  });

                  // assign it
                  await this.updateRelationships(newData, oldData);

                  this.showMessage(i18n.get('bankAccounts.message.onRowUpdate.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('bankAccounts.message.onRowUpdate.error'), 'error', error.message);
                  reject(error);
                }
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                try {
                  const result = await graphql.clientWithToken(this.props.accessToken).mutate({
                    variables: {
                      id: oldData.id,
                    },
                    mutation: gql`
                      mutation deleteBankAccount($id: ID!) {
                        deleteBankAccount(id: $id)
                      }
                    `,
                  });

                  this.showMessage(i18n.get('bankAccounts.message.onRowDelete.success'), 'success');

                  resolve(result);
                } catch (error) {
                  this.showMessage(i18n.get('bankAccounts.message.onRowDelete.error'), 'error', error.message);
                  reject(error);
                }
              }),
          }}
        />

        <Message
          hideAfter={this.state.hideAfter}
          onClose={this.closeMessage}
          variant={this.state.variant}
          message={this.state.message}
          details={this.state.details}
          open={this.state.message !== null}
        />
        <PaginationDialog
          columns={this.state.paginationColumns}
          fetchData={this.state.fetchPaginationData}
          open={this.state.pagination}
          onCancel={this.handlePaginationCancel}
          dialogTitle={this.state.paginationTitle}
          cancelText={i18n.get('general.pagination.cancel')}
        />
      </div>
    );
  }
}

BankAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withIAM(withStyles(styles, { withTheme: true })(withJournal(BankAccounts)));
