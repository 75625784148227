import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { MESSAGE_COLORS } from '../common/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: MESSAGE_COLORS.green,
  },
  error: {
    backgroundColor: MESSAGE_COLORS.red,
  },
  info: {
    backgroundColor: MESSAGE_COLORS.blue,
  },
  warning: {
    backgroundColor: MESSAGE_COLORS.orange,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  button: {
    color: '#ffffff',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MessageWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, details, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <div id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <b>
            {message} {details ? <i>{details}</i> : <i></i>}«
          </b>
        </div>
      }
      action={[
        <IconButton key="close" aria-label="Close" className={classes.button} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MessageWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

class Message extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.props.open}
          autoHideDuration={this.props.hideAfter ? this.props.hideAfter : 6000}
          onClose={this.props.onClose}
        >
          <MessageWrapper
            onClose={this.props.onClose}
            variant={this.props.variant}
            message={this.props.message}
            details={this.props.details}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default Message;
